import { Button, Col, Input, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, InputGroup, DropdownItem } from "reactstrap";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import Table, { TableColumn } from 'react-data-table-component';
import { ChangeEvent, KeyboardEvent, FocusEvent, useEffect, useState, SyntheticEvent } from "react";
import Usuario from "../../interfaces/Usuario.interface";
import { FormGroup, IconButton, Tabs } from "@mui/material";
import UsuarioService from "../../services/Usuario.service";
import { Add, Cached, Check, Close, Delete, Edit, Search, ToggleOff, ToggleOn } from "@mui/icons-material";
import UsuarioModel from "../../models/Usuario.model";
import Tiempo from "../../tools/Tiempo.tool";
import Components from "../../tools/Components.tool";
import Styles from "../../assets/css/Styles.css";
import Image from "../../tools/Imagen.tool";
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import Asesor from "../../interfaces/Asesor.interface";
import AsesorService from "../../services/Asesor.service";
import Response from "../../interfaces/Response.interface";
import EmpresaService from "../../services/Empresa.service";
import Empresa from "../../interfaces/Empresa.interface";

import imgNoAddUser from "../../assets/img/noAddUser.svg";
import PermisoModel from "../../models/Permisos.model";
import Permisos from "../../interfaces/Permisos.interface";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const Usuarios = () => {

    const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [asesoresUsuarioNoAsignado, setAsesoresUsuarioNoAsignado] = useState<Asesor[]>([]);
    const [empresasUsuarioNoAsignado, setEmpresasUsuarioNoAsignado] = useState<Empresa[]>([]);
    const [usuariosLista, setUsuariosLista] = useState<Usuario[]>([]);
    const [isOpenModalUSuario, setIsOpenModalUsuario] = useState<boolean>(false);
    const [isOpenModalPassword, setIsOpenModalPassword] = useState<boolean>(false);
    const [isOpenModalAsesoresNoAsignados, setIsOpenModalAsesoresNoAsignados] = useState<boolean>(false);
    const [isOpenModalEmpresasNoAsignados, setIsOpenModalEmpresasNoAsignados] = useState<boolean>(false);
    const [labelCambioPassword, setLabelCambioPassword] = useState<string>("");
    const [labelGuarUsuario, setLabelGuarUsuario] = useState<string>("");
    const [colorCambioLabel, setColorCambioLabel] = useState<string>("");
    const [textBuscar, setTextBuscar] = useState<string>("");
    const [value, setValue] = useState<string>('1');
    const [permisos, setPermisos] = useState<Permisos[]>(PermisoModel.getEstructura());
    const [data] = useState<Device>(DeviceConfig.data());
    const [hora, setHora] = useState<string>("");

    useEffect(() => {
        listarUsuarios("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validarDatosUsuario = (): boolean => {
        let value: boolean = false;
        let label: string = "";
        let color: string = "";

        if (usuario.IdUsuario === 0 && usuario.password.length === 0) {
            label = "La contraseña debe de tener al menos una letra";
            color = colors.warning;
            setLabelGuarUsuario(label);
            setColorCambioLabel(color);
            value = true;
            Components.focus("password");
        }

        if (usuario.username.length === 0) {
            label = "El nombre de usuario debe de tener al menos una letra";
            color = colors.warning;
            setLabelGuarUsuario(label);
            setColorCambioLabel(color);
            value = true;
            Components.focus("username");
        }

        Tiempo.sleep(5).then(() => {
            label = "";
            setLabelGuarUsuario(label);
        });
        return value;
    }

    const guardarUsuario = async () => {
        let label: string = "";
        let color: string = "";
        if (validarDatosUsuario()) return;

        if (usuario.IdUsuario === 0) {
            const value: Response = await UsuarioService.usuarioRepetido(usuario.username)
            if (value.data.value > 0) {
                label = "Ya hay un usuario con este username";
                color = colors.warning;
                setLabelGuarUsuario(label);
                setColorCambioLabel(color);
                Components.focus("username");
                Tiempo.sleep(5).then(() => {
                    label = "";
                    setLabelGuarUsuario(label);
                });
                return;
            }
        }

        let response: Response = await UsuarioService.guardar(usuario);

        const { error, data } = response;
        if (error === false) {
            if (data.status === 200) {
                const IdUsuario: number = response.data.value;
                setUsuario((prev) => ({ ...prev, IdUsuario }));
                label = "Datos guardados";
                color = colors.greenSuccessful;
                setLabelGuarUsuario(label);
                setColorCambioLabel(color);
                listarUsuarios(textBuscar);
            } else {
                label = "No se pudieron guardar los datos";
                color = colors.warning;
                setLabelGuarUsuario(label);
                setColorCambioLabel(color);
            }
        } else {
            label = "Error al guardar los datos del usuario";
            color = colors.red01;
            setLabelGuarUsuario(label);
            setColorCambioLabel(color);
        }
        Tiempo.sleep(5).then(() => {
            label = "";
            setLabelGuarUsuario(label);
        });
    }

    const eliminarUsuario = (usuario: Usuario) => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Usuario",
            text: `¿Estas seguro de eliminar al usuario ${usuario.nombreCompleto}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.red01,
            timer: 10000,
            timerProgressBar: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            reverseButtons: true,
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await UsuarioService.eliminar(usuario.IdUsuario);
                if (response.error === false) {
                    listarUsuarios(textBuscar);
                }
            }
        });
    }

    const listarUsuarios = async (text: string): Promise<void> => {
        const response: Response = await UsuarioService.listar(text);
        const usuariosLista: Usuario[] = response.data.value;
        setUsuariosLista(usuariosLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const listarAsesoresUsuarios = async (): Promise<void> => {
        const response: Response = await AsesorService.asesoresUsuario(usuario.IdUsuario);
        const asesoresLista: Asesor[] = response.data.value;
        console.log(asesoresLista);
        setUsuario((prev) => ({
            ...prev,
            asesores: asesoresLista
        }))
    };

    const listarEmpresaUsuarios = async (): Promise<void> => {
        const response: Response = await EmpresaService.empresasUsuario(usuario.IdUsuario);
        const empresasLista: Empresa[] = response.data.value;
        setUsuario((prev) => ({
            ...prev,
            empresas: empresasLista
        }));
    };

    const cambiarPassword = async (): Promise<void> => {

        if (usuario.password.length > 0) {
            const response: Response = await UsuarioService.cambiarPassword(usuario);
            if (response.error === false) {
                let label: string = "La contraseña fue cambiada";
                let color: string = colors.greenSuccessful;
                setLabelCambioPassword(label);
                setColorCambioLabel(color);
                Tiempo.sleep(5).then(() => {
                    setLabelCambioPassword("");
                    setColorCambioLabel(color);
                });
            } else {
                let label: string = "No se pudo cambiar la contraseña";
                setLabelCambioPassword(label);
                let color: string = colors.red01;
                Tiempo.sleep(5).then(() => {
                    setLabelCambioPassword(label);
                    setColorCambioLabel(color);
                });
            }
        } else {
            let label: string = "No has especificado la contraseña";
            let color: string = colors.warning;
            setLabelCambioPassword(label);
            setColorCambioLabel(color);
            Tiempo.sleep(5).then(() => {
                setLabelCambioPassword("");
                setColorCambioLabel(color);
            });
        }

    };

    const asesoresSinAsignar = async () => {
        const response: Response = await AsesorService.asesoresNoUsuario(usuario.IdUsuario);
        if (response.error === false) {
            console.log(response.data.value)
            const asesores: Asesor[] = response.data.value;
            setAsesoresUsuarioNoAsignado(asesores);
        }
    };

    const empresasSinAsignar = async () => {
        const response: Response = await EmpresaService.empresasNoUsuario(usuario.IdUsuario);
        if (response.error === false) {
            setEmpresasUsuarioNoAsignado(response.data.value)
        }
    };

    const onChangeUsuario = (name: string, value: string | number) => {
        setUsuario((prev) => ({ ...prev, [name]: value }));
    };

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === "2") {
            listarAsesoresUsuarios();
        }
        if (newValue === "3") {
            listarEmpresaUsuarios()
        }
    };

    const agregarPermiso = async (IdUsuario: number, IdPermiso: number): Promise<void> => {
        const response: Response = await UsuarioService.agregarPermiso(IdUsuario, IdPermiso);
        if (response.error === false) { const permisos: Permisos[] = await UsuarioModel.getPermisoUsuario(usuario.IdUsuario); setPermisos(permisos); }
    }

    const quitarPermiso = async (IdUsuario: number, IdPermiso: number): Promise<void> => {
        const response: Response = await UsuarioService.quitarPermiso(IdUsuario, IdPermiso);
        if (response.error === false) { const permisos: Permisos[] = await UsuarioModel.getPermisoUsuario(usuario.IdUsuario); setPermisos(permisos); }
    }

    const MyTabs = styled(Tabs)(() => ({
        '.MuiTabs-indicator': {
            backgroundColor: colors.blue,
            color: colors.blue
        },
    }));

    const columnsUsuarios: TableColumn<Usuario>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRE </Label>,
            cell: (usuario: Usuario) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{usuario.nombreCompleto}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > USERNAME </Label>,
            cell: (usuario: Usuario) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{usuario.username}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > EMPRESA ASOCIADA </Label>,
            cell: (usuario: Usuario) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{usuario.nombreEmpresa}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > ESTATUS </Label>,
            cell: (usuario: Usuario) => <Label style={{ color: usuario.IdStatus === 1 ? colors.greenSuccessful : colors.grey, fontSize: 16, whiteSpace: 'normal', fontWeight: "bold" }} >{usuario.IdStatus === 1 ? "Activo" : "Bloqueado"}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (usuario: Usuario) =>
                <>
                    <IconButton style={{ color: colors.red01 }} onClick={() => {
                        eliminarUsuario(usuario)
                    }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton style={{ color: colors.blue }} onClick={() => {
                        usuario = {
                            ...usuario,
                            password: ""
                        }
                        setIsOpenModalUsuario(true);
                        setUsuario(usuario);
                        setValue("1")
                    }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ];

    const columnsAsesoresAsignados: TableColumn<Asesor>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > RFC </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.RFC}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRES </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{`${asesor.nombres} ${asesor.paterno} ${asesor.materno}`}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (asesor: Asesor) =>
                <IconButton
                    style={{ color: colors.red01 }}
                    onClick={async () => {
                        await AsesorService.asesoresDesignarUsuario(asesor.IdAsesoresUsuario);
                        await asesoresSinAsignar();
                        await listarAsesoresUsuarios();
                    }}
                    title={"Quitar asesor"}
                >
                    <Close />
                </IconButton>,
        },
    ];

    const columnsAsesoresNoAsignados: TableColumn<Asesor>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > RFC </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.RFC}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRES </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{`${asesor.nombres} ${asesor.paterno} ${asesor.materno}`}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (asesor: Asesor) => <IconButton
                style={{ color: colors.greenSuccessful }}
                onClick={async () => {
                    await AsesorService.asesoresAsignarUsuario(usuario.IdUsuario, asesor.RFC);
                    await asesoresSinAsignar();
                    await listarAsesoresUsuarios();
                }}
                title={"Asignar asesor"} >
                <Check />
            </IconButton>,
        },
    ];

    const columnsEmpresasAsignados: TableColumn<Empresa>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > RFC </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{empresa.RFC}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRES </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{`${empresa.nombreEmpresa}`}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > GIRO EMPRESARIAL </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{`${empresa.giroEmpresarial}`}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (empresa: Empresa) =>
                <IconButton
                    style={{ color: colors.red01 }}
                    onClick={async () => {
                        await EmpresaService.empresaDesignarUsuario(empresa.IdEmpresaUsuario);
                        await empresasSinAsignar();
                        await listarEmpresaUsuarios();
                    }}
                    title={"Quitar empresa"}
                >
                    <Close />
                </IconButton>,
        },
    ];

    const columnsEmpresasNoAsignados: TableColumn<Empresa>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > RFC </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{empresa.RFC}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRES </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{`${empresa.nombreEmpresa}`}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > GIRO EMPRESARIAL </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{`${empresa.giroEmpresarial}`}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (empresa: Empresa) => <IconButton
                style={{ color: colors.greenSuccessful }}
                onClick={async () => {
                    await EmpresaService.empresaAsignarUsuario(usuario.IdUsuario, empresa.RFC);
                    await empresasSinAsignar();
                    await listarEmpresaUsuarios();
                }}
                title={"Asignar empresa"} >
                <Check />
            </IconButton>,
        },
    ];

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >

                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            USUARIOS DADOS DE ALTA EN EL SISTEMA
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                        <FormGroup>
                            <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                Buscar Usuario
                            </Label>
                            <InputGroup>
                                <Input
                                    id="textBuscar"
                                    type="text"
                                    placeholder="Nombre completo o nombre de usuario"
                                    value={textBuscar}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setTextBuscar(value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") listarUsuarios(textBuscar);
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        setTextBuscar("");
                                        Tiempo.sleep(1).then(() => { listarUsuarios("") });
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Close />
                                </InputGroupText>
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        listarUsuarios(textBuscar);
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Search />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <IconButton
                            style={{ marginRight: 20, color: colors.blue }}
                            onClick={() => listarUsuarios(textBuscar)}
                        >
                            <Cached />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIsOpenModalUsuario(true);
                            }}
                            style={{ marginRight: 20, color: colors.blue }}
                        >
                            <Add />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Label style={{ fontWeight: "bold" }} >
                            Ultima Actualización: {hora}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Table
                            columns={columnsUsuarios}
                            data={usuariosLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >Tabla Vacía</Label>}
                        />
                    </Col>
                </Row>
            </section>
            <Modal
                isOpen={isOpenModalUSuario}
                style={{ fontFamily: "Montserrat", maxWidth: data.isDesktop ? "90%" : "", marginTop: data.isDesktop ? 10 : 0, height: data.isDesktop ? "65vh" : "" }}
                size="xl"
                onOpened={async () => {
                    Components.focus("nombreCompleto");
                    const permisos: Permisos[] = await UsuarioModel.getPermisoUsuario(usuario.IdUsuario);
                    setPermisos(permisos)
                }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setIsOpenModalUsuario(false);
                                setUsuario(UsuarioModel.getEstructura());
                                setLabelGuarUsuario("");
                                setLabelCambioPassword("");
                            }}
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Datos del Usuario
                    </Label>
                </ModalHeader>
                <ModalBody  >
                    <Box sx={{ typography: 'body1' }}>

                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <MyTabs value={value} onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Datos Generales" value="1" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Asesores" value="2" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Empresas" value="3" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Permisos" value="4" />
                                </MyTabs>
                            </Box>
                            <div style={{ height: "65vh", overflowY: "auto" }} >
                                <TabPanel value="1">
                                    <Row>
                                        <Col style={{ padding: 5 }} >
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label style={{ color: colors.black, fontSize: 18 }} >
                                                            Nombre Completo
                                                        </Label>
                                                        <Input
                                                            id="nombreCompleto"
                                                            type="text"
                                                            value={usuario.nombreCompleto}
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                let name = "nombreCompleto";
                                                                onChangeUsuario(name, value)
                                                            }}
                                                            onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                                if (key === "Enter") Components.focus("username");
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            style={Styles.input}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: 20 }} >
                                                <Col>
                                                    <FormGroup>
                                                        <Label style={{ color: colors.black, fontSize: 18 }} >
                                                            Nombre de Usuario
                                                        </Label>
                                                        <Input
                                                            id="username"
                                                            type="text"
                                                            value={usuario.username}
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                let name = "username";
                                                                onChangeUsuario(name, value)
                                                            }}
                                                            onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                                if (key === "Enter") Components.focus(usuario.IdUsuario === 0 ? "password" : "nombreEmpresa");
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            style={Styles.input}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {
                                                usuario.IdUsuario > 0 &&
                                                <Label
                                                    style={{ color: colors.blue, fontSize: 14, marginTop: 5, cursor: "pointer" }}
                                                    onClick={() => { setIsOpenModalPassword(true) }}
                                                >
                                                    Cambiar contraseña
                                                </Label>
                                            }
                                            {
                                                usuario.IdUsuario === 0 &&
                                                <Row style={{ marginTop: 20 }} >
                                                    <Col>
                                                        <FormGroup>
                                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                                Contraseña
                                                            </Label>
                                                            <Input
                                                                id="password"
                                                                type="text"
                                                                value={usuario.password}
                                                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                    let name = "password";
                                                                    onChangeUsuario(name, value)
                                                                }}
                                                                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                                    if (key === "Enter") Components.focus("nombreEmpresa");
                                                                }}
                                                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                    event.target.select();
                                                                }}
                                                                style={Styles.input}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row style={{ marginTop: 20 }} >
                                                <Col>
                                                    <FormGroup>
                                                        <Label style={{ color: colors.black, fontSize: 18 }} >
                                                            Nombre de Empresa Asociada
                                                        </Label>
                                                        <Input
                                                            id="nombreEmpresa"
                                                            type="text"
                                                            value={usuario.nombreEmpresa}
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                let name = "nombreEmpresa";
                                                                onChangeUsuario(name, value)
                                                            }}
                                                            onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                                if (key === "Enter") Components.focus("password");
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            style={Styles.input}
                                                        />
                                                        <span>Este nombre se mostrara en los reportes generados por este usuario</span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: 20 }} >
                                                <Col style={{ width: "50%" }} >
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Estatus: <label style={{ marginLeft: 7 }} >{usuario.IdStatus === 0 ? "Bloqueado" : "Activo"}</label>
                                                    </Label>
                                                </Col>
                                                <Col
                                                    style={{ textAlign: "right", width: "50%" }}
                                                    onClick={() => {
                                                        let { IdStatus } = usuario;
                                                        if (IdStatus === 1) {
                                                            IdStatus = 0;
                                                        } else {
                                                            if (IdStatus === 0) {
                                                                IdStatus = 1;
                                                            }
                                                        }
                                                        setUsuario((prev) => ({
                                                            ...prev,
                                                            IdStatus
                                                        }));
                                                    }}
                                                >
                                                    {usuario.IdStatus === 1 ?
                                                        <ToggleOn style={{ height: 40, width: 40, marginTop: -5, color: colors.blue, marginRight: 20 }} />
                                                        :
                                                        <ToggleOff style={{ height: 40, width: 40, marginTop: -5, color: colors.grey, marginRight: 20 }} />}
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col style={{ padding: 5 }} >
                                            <Row style={{ marginTop: 0 }} >
                                                <Col>
                                                    <FormGroup>
                                                        <Label style={{ color: colors.black, fontSize: 18 }} >
                                                            Imagen de la empresa asociada
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                                if (files) {
                                                                    let file = files[0];
                                                                    if (file) {
                                                                        let size = Image.obtenerTamañoImagenMB(file)
                                                                        if (size > 5) {
                                                                            let label: string = "No se puede usar esta imagen ya que pesa mas de 5 MB";
                                                                            let color: string = colors.warning;
                                                                            setLabelGuarUsuario(label);
                                                                            setColorCambioLabel(color);
                                                                        } else {
                                                                            let base64 = await Image.convertirImagenABase64(file);

                                                                            setUsuario((prev) => ({
                                                                                ...prev,
                                                                                imgLogoEmpresa: base64
                                                                            }));
                                                                        }
                                                                    }


                                                                }
                                                            }}
                                                        />
                                                        <span>Esta imagen se mostrara en los reportes generados por este usuario</span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: 20 }} >
                                                <Col>
                                                    {
                                                        usuario.imgLogoEmpresa.length === 0 ?
                                                            <i style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >No hay logo</i>
                                                            :
                                                            <img
                                                                src={usuario.imgLogoEmpresa}
                                                                alt="No se pudo cargar la imagen"
                                                                height={150}
                                                                style={Styles.input}
                                                            />
                                                    }

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label style={{ marginTop: 10, color: colorCambioLabel, fontWeight: "bold" }} >
                                                        {labelGuarUsuario}
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel value="2">
                                    {
                                        usuario.IdUsuario === 0 ?
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Label style={{ color: colors.black, fontSize: 18 }}  >
                                                            Crear o selecciona a un usuario para poder asignarle asesores
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ textAlign: "center" }} >
                                                        <img
                                                            src={imgNoAddUser}
                                                            alt="No se pudo cargar la imagen"
                                                            style={{ height: 370, marginTop: 10 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Label style={{ color: colors.black, fontSize: 18 }}  >
                                                            Asesores asignados a este usuario
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <IconButton
                                                            style={{ marginRight: 20, color: colors.blue }}
                                                            onClick={listarAsesoresUsuarios}
                                                        >
                                                            <Cached />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => { setIsOpenModalAsesoresNoAsignados(true); }}
                                                            style={{ marginRight: 20, color: colors.blue }}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Table
                                                        columns={columnsAsesoresAsignados}
                                                        data={usuario.asesores}
                                                        noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >No hay asesores asignados</Label>}
                                                    />
                                                </Row>
                                            </>
                                    }

                                </TabPanel>

                                <TabPanel value="3">
                                    {
                                        usuario.IdUsuario === 0 ?
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Label style={{ color: colors.black, fontSize: 18 }}  >
                                                            Crear o selecciona a un usuario para poder asignarle empresas
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ textAlign: "center" }} >
                                                        <img
                                                            src={imgNoAddUser}
                                                            alt="No se pudo cargar la imagen"
                                                            style={{ height: 370, marginTop: 10 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Label style={{ color: colors.black, fontSize: 18 }}  >
                                                            Empresas asignadas a este usuario
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <IconButton
                                                            style={{ marginRight: 20, color: colors.blue }}
                                                            onClick={listarEmpresaUsuarios}
                                                        >
                                                            <Cached />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => { setIsOpenModalEmpresasNoAsignados(true); }}
                                                            style={{ marginRight: 20, color: colors.blue }}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Table
                                                        columns={columnsEmpresasAsignados}
                                                        data={usuario.empresas}
                                                        noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >No hay empresas asignadas</Label>}
                                                    />
                                                </Row>
                                            </>
                                    }
                                </TabPanel>

                                <TabPanel value="4">
                                    {
                                        usuario.IdUsuario === 0 ?
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Label style={{ color: colors.black, fontSize: 18 }}  >
                                                            Crear o selecciona a un usuario para asignar o quitar permisos
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ textAlign: "center" }} >
                                                        <img
                                                            src={imgNoAddUser}
                                                            alt="No se pudo cargar la imagen"
                                                            style={{ height: 370, marginTop: 10 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Label style={{ color: colors.black, fontSize: 18 }}  >
                                                            Permisos
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            Menu Transacciones
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[0].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[0].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 1);
                                                                    quitarPermiso(usuario.IdUsuario, 2);
                                                                    quitarPermiso(usuario.IdUsuario, 3);
                                                                    quitarPermiso(usuario.IdUsuario, 4);
                                                                    quitarPermiso(usuario.IdUsuario, 5);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 1);
                                                                    agregarPermiso(usuario.IdUsuario, 2);
                                                                    agregarPermiso(usuario.IdUsuario, 3);
                                                                    agregarPermiso(usuario.IdUsuario, 4);
                                                                    agregarPermiso(usuario.IdUsuario, 5);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Ingresos / Egresos
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[1].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[1].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 2);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 2);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider style={{ marginLeft: 30 }} />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Concentrado Asesores
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[2].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[2].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 3);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 3);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider style={{ marginLeft: 30 }} />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Resumen por Asesor
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[3].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[3].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 4);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 4);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider style={{ marginLeft: 30 }} />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Cheques
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[4].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[4].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 5);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 5);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            Catálogos
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[5].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[5].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 6);
                                                                    quitarPermiso(usuario.IdUsuario, 7);
                                                                    quitarPermiso(usuario.IdUsuario, 8);
                                                                    quitarPermiso(usuario.IdUsuario, 9);
                                                                    quitarPermiso(usuario.IdUsuario, 10);
                                                                    quitarPermiso(usuario.IdUsuario, 11);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 6);
                                                                    agregarPermiso(usuario.IdUsuario, 7);
                                                                    agregarPermiso(usuario.IdUsuario, 8);
                                                                    agregarPermiso(usuario.IdUsuario, 9);
                                                                    agregarPermiso(usuario.IdUsuario, 10);
                                                                    agregarPermiso(usuario.IdUsuario, 11);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Asesores
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[6].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[6].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 7);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 7);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider style={{ marginLeft: 30 }} />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Tipo de Porcentaje
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[7].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[7].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 8);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 8);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider style={{ marginLeft: 30 }} />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Bancos
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[8].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[8].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 9);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 9);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider style={{ marginLeft: 30 }} />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Cuentas Bancarias
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[9].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[9].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 10);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 10);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider style={{ marginLeft: 30 }} />
                                                <Row  >
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }}>
                                                            Empresas
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[10].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[10].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 11);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 11);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row>
                                                    <Col>
                                                        <Label >
                                                            Directorio
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[13].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[13].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 14);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 14);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row>
                                                    <Col>
                                                        <Label style={{ marginLeft: 30 }} >
                                                            Modo escritor
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[14].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[14].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 15);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 15);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            Documentación
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[15].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[15].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 16);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 16);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            Usuarios
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <Input
                                                            type="checkbox"
                                                            checked={permisos[12].valor === 1}
                                                            onClick={() => {
                                                                if (permisos[12].valor === 1) {
                                                                    quitarPermiso(usuario.IdUsuario, 13);
                                                                } else {
                                                                    agregarPermiso(usuario.IdUsuario, 13);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                </TabPanel>

                            </div>
                        </TabContext>

                    </Box>



                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            setUsuario(UsuarioModel.getEstructura());
                            Components.focus("nombreCompleto");
                            setValue("1");
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={Styles.button}
                        onClick={() => guardarUsuario()}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalPassword}
                onOpened={() => { Components.focus("password") }}
                style={{ fontFamily: "Montserrat" }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setIsOpenModalPassword(false);
                            }}
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Cambio de Contraseña
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginTop: 20 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                    Nueva Contraseña para {usuario.username}
                                </Label>
                                <Input
                                    id="password"
                                    type="text"
                                    value={usuario.password}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        let name = "password";
                                        onChangeUsuario(name, value);
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreEmpresa");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                            <Label style={{ marginTop: 10, color: colorCambioLabel, fontWeight: "bold" }} >
                                {labelCambioPassword}
                            </Label>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            cambiarPassword();
                        }}
                    >
                        Cambiar Contraseña
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalAsesoresNoAsignados}
                onOpened={async () => {
                    asesoresSinAsignar()
                }}
                style={{ fontFamily: "Montserrat" }}
                size="xl"
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setIsOpenModalAsesoresNoAsignados(false);
                            }}
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Asesores sin asignar
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginTop: 20 }} >
                        <Table
                            columns={columnsAsesoresNoAsignados}
                            data={asesoresUsuarioNoAsignado}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >No hay asesores para asignar</Label>}
                        />
                    </Row>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={isOpenModalEmpresasNoAsignados}
                onOpened={async () => {
                    empresasSinAsignar()
                }}
                style={{ fontFamily: "Montserrat" }}
                size="xl"
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setIsOpenModalEmpresasNoAsignados(false);
                            }}
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Empresas sin asignar
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginTop: 20 }} >
                        <Table
                            columns={columnsEmpresasNoAsignados}
                            data={empresasUsuarioNoAsignado}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >No hay empresas para asignar</Label>}
                        />
                    </Row>
                </ModalBody>
            </Modal>
        </main >
    )
}

export default Usuarios;