import colors from "../../config/Colors.config";

const input = {
    borderWidth: 1,
    boxShadow: "none",
    borderLeftColor: "transparent",
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: colors.blue,
    backgroundColor: colors.white,
}

const button = {
    backgroundColor: colors.blue,
    borderColor: colors.blue,
    fontWeight: " ",
    boxShadow: "none"
}


const Styles = {
    input,
    button,
}

export default Styles;