import ApiConfig from "../config/Api.config";
import Usuario from "../interfaces/Usuario.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import Permisos from "../interfaces/Permisos.interface";
import UsuarioModel from "../models/Usuario.model";

const url = `${ApiConfig.URLBase}/usuarios`

const login = async (usuario: Usuario): Promise<Response> => {
    usuario = {
        ...usuario,
        password: UsuarioModel.hashearPassword(usuario.password)
    }
    const request: Request = {
        url: `${url}/login`,
        metodo: "POST",
        data: { usuario }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (texto: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { texto }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}


const guardar = async (usuario: Usuario): Promise<Response> => {
    usuario = {
        ...usuario,
        password: UsuarioModel.hashearPassword(usuario.password)
    }
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { usuario }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdUsuario: number | undefined): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { IdUsuario }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const cambiarPassword = async (usuario: Usuario): Promise<Response> => {
    usuario = {
        ...usuario,
        passwordHASH: UsuarioModel.hashearPassword(usuario.password)
    }
    const request: Request = {
        url: `${url}/cambiarPassword`,
        metodo: "PUT",
        data: { usuario }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const usuarioRepetido = async (username: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/repetido`,
        metodo: "POST",
        data: { username }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const permisos = async (IdUsuario: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/permisos`,
        metodo: "POST",
        data: { IdUsuario }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const permisosGuardar = async (permisos: Permisos): Promise<Response> => {
    const request: Request = {
        url: `${url}/permisos/guardar`,
        metodo: "POST",
        data: { permisos }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const getUsuario = async (IdUsuario: number) => {    
    const request: Request = {
        url: `${url}/${IdUsuario}`,
        metodo: "GET",
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};

const agregarPermiso = async (IdUsuario: number, IdPermiso: number) => {
    const request: Request = {
        url: `${url}/permisos/agregar`,
        metodo: "POST",
        data: { IdUsuario, IdPermiso }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};


const quitarPermiso = async (IdUsuario: number, IdPermiso: number) => {
    const request: Request = {
        url: `${url}/permisos/quitar`,
        metodo: "POST",
        data: { IdUsuario, IdPermiso }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};


const UsuarioService = {
    login,
    listar,
    guardar,
    eliminar,
    cambiarPassword,
    usuarioRepetido,
    permisos,
    permisosGuardar,
    getUsuario,
    agregarPermiso,
    quitarPermiso,
}

export default UsuarioService;