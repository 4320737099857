import ApiConfig from "../config/Api.config";
import Documentacion from "../interfaces/Documentacion.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/documentacion`

const listar = async (empresaBuscar: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { empresaBuscar }

    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const obtener = async (IdDocomentacion: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/obtener`,
        metodo: "POST",
        data: { IdDocomentacion }

    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const guardar = async (documentation: Documentacion): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { documentation }

    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdDocumentacion: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { IdDocumentacion }

    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const DocumentacionService = {
    listar,
    guardar,
    eliminar,
    obtener,
}

export default DocumentacionService;