import { FC, useState } from "react";
import colors from "../../config/Colors.config";
import { Col, Label, Row } from "reactstrap";
import Block from "../../components/Block.component";

import logo2 from "../../assets/img/svgChar2.svg";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";
const Home: FC = () => {

    const [data] = useState<Device>(DeviceConfig.data());

    return (
        <section style={{ minHeight: "100vh", height: "100vh", maxHeight: "auto", marginLeft: 0, backgroundColor: colors.white }} >
            <Block height={"10vh"} />
            <Row style={{ marginRight: 0, marginLeft: 0 }} >
                <Col style={{ textAlign: "center" }} >
                    <img src={logo2} height={data.isDesktop ? 300 : 150} style={{ marginLeft: data.isDesktop ? 155 : 0 }} alt="No se pudo cargar la imagen" />
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ textAlign: "center" }} >
                    <Label style={{ fontSize: data.isDesktop ? 52 : 42, fontWeight: "bold", color: colors.blue }} >
                        KHRONOS
                    </Label>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ textAlign: "center" }} >
                    <Label style={{ fontSize: data.isDesktop ? 36 : 28, fontWeight: "bold", color: colors.blue }} >
                        ASESORÍA ESPECIALIZADA EN FINANZAS
                    </Label>
                </Col>
            </Row>
        </section>
    )
}

export default Home