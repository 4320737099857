import colors from "../config/Colors.config";
import Movimiento from "../interfaces/Movimiento.interface";
import Fecha from "../tools/Fecha.tool";
import AsesorModel from "./Asesor.model";
import BancoModel from "./Banco.model";
import CuentaBancariaModel from "./CuentaBancaria.model";
import EmpresaModel from "./Empresa.model";
import PorcentajeModel from "./Porcentaje.model";

const getEstructura = (): Movimiento => {
    const movimiento: Movimiento = {
        IdMovimiento: 0,
        IdTipo: 0,
        fechaMovimiento: Fecha.getDateToDay(),
        empresa: EmpresaModel.getEstructura(),
        asesor: AsesorModel.getEstructura(),
        IdFormaPago: 0,
        banco: BancoModel.getEstructura(),
        cuentaBancaria: CuentaBancariaModel.getEstructura(),
        referencia: "",
        observaciones: "",
        importe: "0",
        retornar: 0,
        utilidad: 0,
        gastosOperacion: 0,
        fechaCreacion: "",
        horaCreacion: "",
        porcentaje: PorcentajeModel.getEstructura(),
        IdChequeStatus: 0,
        IdReferenciaMovimientoIngreso: 0
    };
    return movimiento;
}

const selectFormaPago = (index: number) => {
    const array: string[] = ["Efectivo", "Trasferencia", "Cheque"]
    return array[index - 1];
}

const selectTipoMovimiento = (index: number) => {
    const array: string[] = ["Ingreso", "Retorno", "Gasto"]
    return array[index - 1];
}

const selectStatusCheque = (index: number): string => {
    const array: string[] = ["Inicial", "Cobrado", "Cancelado"];
    return array[index];
}

const selectColor = (index: number): string => {
    const array: string[] = [colors.greenSuccessful, colors.red01, colors.red01];
    return array[index - 1];
}

const selectColorStatus = (index: number): string => {
    const array: string[] = [colors.blue, colors.greenSuccessful, colors.red01];
    return array[index ];
}


const MovimientoModel = {
    getEstructura,
    selectFormaPago,
    selectTipoMovimiento,
    selectStatusCheque,
    selectColor,
    selectColorStatus,
}

export default MovimientoModel; 