import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Styles from "../../../assets/css/Styles.css";
import colors from "../../../config/Colors.config";
import Usuario from "../../../interfaces/Usuario.interface";
import Movimiento from "../../../interfaces/Movimiento.interface";
import Response from "../../../interfaces/Response.interface";
import MovimientoService from "../../../services/Movimiento.service";
import { IconButton } from "@mui/material";
import { Add, Cached, Check, Close, Delete, Edit } from "@mui/icons-material";
import Table, { TableColumn } from 'react-data-table-component';
import BancoModel from "../../../models/Banco.model";
import Banco from "../../../interfaces/Banco.interface";
import Swal from "sweetalert2";
import Moneda from "../../../tools/Moneda.tool";
import CuentaBancariaService from "../../../services/CuentaBancaria.service";
import MovimientoModel from "../../../models/Movimiento.model";
import Fecha from "../../../tools/Fecha.tool";
import CuentaBancaria from "../../../interfaces/CuentaBancaria.interface";
import Asesor from "../../../interfaces/Asesor.interface";
import Empresa from "../../../interfaces/Empresa.interface";
import EmpresaService from "../../../services/Empresa.service";
import AsesorService from "../../../services/Asesor.service";
import BancoService from "../../../services/Banco.service";
import Components from "../../../tools/Components.tool";
import Tiempo from "../../../tools/Tiempo.tool";
import UsuarioModel from "../../../models/Usuario.model";
import CuentaBancariaModel from "../../../models/CuentaBancaria.model";
import Number from "../../../tools/Number.tool";
import Device from "../../../interfaces/Device.interface";
import DeviceConfig from "../../../config/Device.config";
import { toast, ToastContainer } from "react-toastify";

const Retornos: FC<{ vUsuario: Usuario }> = ({ vUsuario }) => {
    const [usuario, setUsuario] = useState<Usuario>(vUsuario);
    const [IdFormaPago, setFormaPago] = useState<number>(0);
    const [movimientosLista, setMovimientosLista] = useState<Movimiento[]>([]);
    const [movimientosReferenciaLista, setMovimientosReferenciaLista] = useState<Movimiento[]>([]);
    const [banco, setBanco] = useState<Banco>(BancoModel.getEstructura());
    const [bancosLista, setBancosLista] = useState<Banco[]>([]);
    const [isOpenModalMovimiento, setIsOpenModalMovimiento] = useState<boolean>(false);
    const [movimiento, setMovimiento] = useState<Movimiento>(MovimientoModel.getEstructura());
    const [cuentasBancariasLista, setCuentasBancariasLista] = useState<CuentaBancaria[]>([])
    const [labelGuardarMovimiento, setLabelGuardarMovimiento] = useState<string>("");
    const [colorMovimiento, setColorMovimiento] = useState<string>("");
    const [folioReferencia, setFolioReferencia] = useState<string>("");
    const [focusImporte, setFocusImporte] = useState<boolean>(false);
    const [isOpenModalMovimientoIngresoRelacionado, setIsOpenModalMovimientoIngresoRelacionado] = useState<boolean>(false);
    const [movimientoReferencia, setMovimientosReferencia] = useState<{ fechaMovimiento: string, RFCAsesor: string }>({ fechaMovimiento: Fecha.getDateToDay(), RFCAsesor: "" });
    const [maximoRetornoAsesor, setMaximoRetornoAsesor] = useState<number>(0);
    const [data] = useState<Device>(DeviceConfig.data());

    useEffect(() => {
        cargarUsuario();
        listarMovimientos(IdFormaPago);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cargarUsuario = async () => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        setUsuario((prev: Usuario) => ({ ...prev, ...usuario }));
    }

    const listarMovimientos = async (IdFormaPago: number): Promise<void> => {
        const response: Response = await MovimientoService.listarRetornos(IdFormaPago);
        const movimientosLista: Movimiento[] = response.data.value;
        setMovimientosLista(movimientosLista);
    }
    const listarCuentasBancarias = async (RFCEmpresa: string, IdBanco: number): Promise<void> => {
        const response: Response = await CuentaBancariaService.listar(RFCEmpresa, IdBanco);
        const cuentasBancariasLista: CuentaBancaria[] = response.data.value;
        setCuentasBancariasLista(cuentasBancariasLista);
    };

    const listarEmpresaUsuarios = async (): Promise<void> => {
        const response: Response = await EmpresaService.empresasUsuario(usuario.IdUsuario);
        const empresasLista: Empresa[] = response.data.value;
        setUsuario((prev) => ({
            ...prev,
            empresas: empresasLista
        }));
    };

    const listarAsesoresUsuarios = async (): Promise<void> => {
        const response: Response = await AsesorService.asesoresUsuario(usuario.IdUsuario);
        const asesoresLista: Asesor[] = response.data.value;
        setUsuario((prev: Usuario) => ({
            ...prev,
            asesores: asesoresLista
        }))
    }

    const listarBancos = async (): Promise<void> => {
        const response: Response = await BancoService.listar("");
        const bancosLista: Banco[] = response.data.value;
        setBancosLista(bancosLista);
    };

    const validarDatosMovimiento = (): boolean => {

        let value: boolean = true;
        let labelGuardarMovimiento: string = "";
        let colorMovimiento: string = "";
        let { empresa, asesor, IdFormaPago, importe, banco, cuentaBancaria, IdReferenciaMovimientoIngreso } = movimiento;
        let RFCAsesor: string = asesor.RFC;
        let RFCEmpresa: string = empresa.RFC;
        let { IdBanco } = banco;
        let { IdBancoCuenta } = cuentaBancaria;

        if (+importe === 0) {
            labelGuardarMovimiento = "No has seleccionado un importe";
            colorMovimiento = colors.warning;
            Components.focus("importe");
            value = false;
        }
        if (IdBanco === -1) {
            labelGuardarMovimiento = "No has seleccionado una banco";
            colorMovimiento = colors.warning;
            Components.focus("IdBanco");
            value = false;
        }
        if (IdBancoCuenta === -1) {
            labelGuardarMovimiento = "No has seleccionado una cuenta bancaria";
            colorMovimiento = colors.warning;
            Components.focus("IdBancoCuenta");
            value = false;
        }
        if (IdFormaPago === 0) {
            labelGuardarMovimiento = "No has seleccionado una forma de pago";
            colorMovimiento = colors.warning;
            Components.focus("IdFormaPago");
            value = false;
        }
        if (IdReferenciaMovimientoIngreso === 0) {
            labelGuardarMovimiento = "No has seleccionado un movimiento de referencia";
            colorMovimiento = colors.warning;
            setIsOpenModalMovimientoIngresoRelacionado(true);
            value = false;
        }
        if (RFCAsesor.length === 0) {
            labelGuardarMovimiento = "No has seleccionado una persona";
            colorMovimiento = colors.warning;
            Components.focus("RFCPersona");
            value = false;
        }
        if (RFCEmpresa.length === 0) {
            labelGuardarMovimiento = "No has seleccionado una empresa";
            colorMovimiento = colors.warning;
            Components.focus("RFCEmpresa");
            value = false;
        }

        setLabelGuardarMovimiento(labelGuardarMovimiento);
        setColorMovimiento(colorMovimiento);

        return value;
    }

    const guardarMovimiento = async (): Promise<void> => {
        const value: boolean = validarDatosMovimiento();
        if (value) {

            const response: Response = await MovimientoService.guardar(movimiento);
            if (response.error === false) {
                listarMovimientos(IdFormaPago);
                setLabelGuardarMovimiento("Movimiento Guardado");
                setColorMovimiento(colors.greenSuccessful);
                Tiempo.sleep(5).then(() => setLabelGuardarMovimiento(""));
                setMovimiento({ ...MovimientoModel.getEstructura(), IdTipo: 2 });
                Components.focus("fechaMovimiento");
                setFolioReferencia("");
                setMaximoRetornoAsesor(0);
            }
        }
    }

    const columnsMovimientosReferencia: TableColumn<Movimiento>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FECHA </Label>,
            cell: (movimientoReferencia: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Fecha.formatDate(movimientoReferencia.fechaMovimiento)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > NOMBRE ASESOR </Label>,
            cell: (movimientoReferencia: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{`${movimientoReferencia.asesor.nombres} ${movimientoReferencia.asesor.paterno} ${movimientoReferencia.asesor.materno} `}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > No. CHEQUE / FOLIO </Label>,
            cell: (movimientoReferencia: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimientoReferencia.referencia}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > IMPORTE </Label>,
            cell: (movimientoReferencia: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Moneda.formatCurrency(movimientoReferencia.importe)}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FALTANTE POR RETORNAR </Label>,
            cell: (movimientoReferencia: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Moneda.formatCurrency(movimientoReferencia.retornar)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} ></Label>,
            cell: (movimientoReferencia: Movimiento) =>
                <IconButton
                    style={{ color: +movimientoReferencia.retornar === 0 ? "grey" : colors.blue }}
                    onClick={() => {
                        if (+movimientoReferencia.retornar === 0) {
                            toast.info("Ya no hay mas por retornar");
                            return
                        }
                        setMovimiento((prev: Movimiento) => ({ ...prev, IdReferenciaMovimientoIngreso: movimientoReferencia.IdMovimiento }))
                        setFolioReferencia(movimientoReferencia.referencia);
                        setIsOpenModalMovimientoIngresoRelacionado(false);
                        console.log(movimientoReferencia)
                        setMaximoRetornoAsesor(+movimientoReferencia.retornar);
                    }}
                    title={+movimientoReferencia.retornar === 0 ? "No se puede aplicar" : "Aplicar"}
                >
                    <Check />
                </IconButton>
            ,
        },
    ]

    const columnsMovimientos: TableColumn<Movimiento>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FECHA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} title={movimiento.fechaMovimiento}  >{Fecha.formatDate(movimiento.fechaMovimiento)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FORMA DE PAGO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} title={MovimientoModel.selectStatusCheque(movimiento.IdChequeStatus)} >{MovimientoModel.selectFormaPago(movimiento.IdFormaPago)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > NOMBRE ASESOR </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{`${movimiento.asesor.nombres} ${movimiento.asesor.paterno} ${movimiento.asesor.materno} `}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > IMPORTE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Moneda.formatCurrency(movimiento.importe)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > No. CHEQUE / FOLIO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.referencia}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > BANCO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.banco.nombreBanco}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > EMPRESA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.empresa.nombreEmpresa}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} ></Label>,
            cell: (movimiento: Movimiento) =>
                <>
                    <IconButton
                        style={{ color: colors.red01 }}
                        onClick={() => {
                            Swal.fire({
                                icon: "question",
                                title: "¿Eliminar movimiento?",
                                text: `¿Estas seguro de eliminar este movimiento de ingreso de ${Moneda.formatCurrency(movimiento.importe)}?`,
                                showCancelButton: true,
                                cancelButtonText: "No, Eliminar",
                                confirmButtonText: "Si, Eliminar",
                                confirmButtonColor: colors.blue,
                                reverseButtons: true
                            }).then(async (response) => {
                                if (response.isConfirmed) {
                                    await MovimientoService.eliminar(movimiento.IdMovimiento);
                                    listarMovimientos(IdFormaPago);
                                }
                            });
                        }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton
                        style={{ color: colors.blue }}
                        onClick={async () => {
                            setMovimiento({ ...movimiento, fechaMovimiento: movimiento.fechaMovimiento.substring(0, 10), IdReferenciaMovimientoIngreso: 0 });
                            setIsOpenModalMovimiento(true);
                            listarCuentasBancarias(movimiento.empresa.RFC, movimiento.banco.IdBanco)
                            setBanco(movimiento.banco);
                            // const response = await AsesorService.getMaximoPorRetornar(movimiento.asesor.RFC);
                            // const maximoRetornoAsesor: number = response.data.value;
                            // setMaximoRetornoAsesor(maximoRetornoAsesor);
                        }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ]

    return (
        <>
            <ToastContainer limit={3} position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                <Col style={{ minWidth: "30%", width: "30%", maxWidth: "30%" }} >
                    <FormGroup>
                        <Label style={{ color: colors.black, fontSize: 18 }} >
                            Forma de pago
                        </Label>
                        <Input
                            style={{ ...Styles.input, width: "100%", marginTop: -10 }}
                            type="select"
                            value={IdFormaPago}
                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                setFormaPago(+value);
                                listarMovimientos(+value)
                            }}
                        >
                            <option value={0}>Todos</option>
                            <option value={1}>Efectivo</option>
                            <option value={2}>Trasferencia</option>
                            <option value={3}>Cheque</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col style={{ textAlign: "right" }} >
                    <IconButton
                        style={{ backgroundColor: colors.blue01, color: colors.white, marginRight: 15 }}
                        onClick={() => listarMovimientos(IdFormaPago)}
                    >
                        <Cached />
                    </IconButton>
                    <IconButton
                        style={{ backgroundColor: colors.blue01, color: colors.white }}
                        onClick={() => {
                            setIsOpenModalMovimiento(true);
                            setMovimiento((prev: Movimiento) => ({
                                ...prev,
                                IdTipo: 2,
                                banco: { ...prev.banco, IdBanco: -1 },
                                cuentaBancaria: { ...prev.cuentaBancaria, IdBancoCuenta: -1 }
                            }));
                        }}
                    >
                        <Add />
                    </IconButton>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ minWidth: "30%", width: "30%", maxWidth: "30%" }} >
                    <Label style={{ color: colors.black, fontSize: 16 }} >
                        TOTAL DE IMPORTE
                    </Label>
                    <br />
                    <Label style={{ color: colors.black, fontSize: 16 }} >
                        {Moneda.formatCurrency(movimientosLista.reduce((sum, movimiento) => sum + +movimiento.importe, 0))}
                    </Label>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Table
                    columns={columnsMovimientos}
                    data={movimientosLista}
                    noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 30 }} >No hay movimientos registrados</Label>}
                />
            </Row>

            <Modal
                isOpen={isOpenModalMovimiento}
                style={{ fontFamily: "Montserrat", maxWidth: data.isMovil ? "" : "80%" }}
                size="lg"
                onOpened={() => {
                    listarEmpresaUsuarios();
                    listarAsesoresUsuarios();
                    listarBancos();

                }}
                onClosed={() => { setMovimiento(MovimientoModel.getEstructura()); setLabelGuardarMovimiento(""); setFolioReferencia(""); setMaximoRetornoAsesor(0); }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalMovimiento(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Agregar Movimiento de Retorno
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: data.isDesktop ? "70vh" : "", height: data.isDesktop ? "70vh" : "", maxHeight: data.isDesktop ? "70vh" : "", overflowY: "auto" }} >
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Fecha
                                </Label>
                                <Input
                                    id="fechaMovimiento"
                                    name="fechaMovimiento"
                                    type="date"
                                    value={movimiento.fechaMovimiento}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, [name]: value }));
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Empresa
                                </Label>
                                <Input
                                    id="RFCEmpresa"
                                    type="select"
                                    value={movimiento.empresa.RFC}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, empresa: { ...prev.empresa, RFC: value } }));
                                        listarCuentasBancarias(value, movimiento.banco.IdBanco);
                                    }}
                                    style={Styles.input}
                                    disabled={movimiento.IdFormaPago === 1}
                                >
                                    <option value="">Seleccione una empresa</option>
                                    {
                                        movimiento.IdFormaPago === 1 &&
                                        <option value={"000"}>NO APLICA</option>
                                    }
                                    {
                                        usuario.empresas.map((empresa: Empresa) => {
                                            return (
                                                <option value={empresa.RFC} title={empresa.giroEmpresarial} >{empresa.nombreEmpresa}</option>
                                            )
                                        })
                                    }
                                </Input>
                                {
                                    movimiento.IdFormaPago === 1 &&
                                    <Label style={{ color: colors.blue }} >
                                        Cuando la forma de pago es en efectivo no se asocia ninguna empresa
                                    </Label>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Persona
                                </Label>
                                <Input
                                    id="RFCPersona"
                                    type="select"
                                    value={movimiento.asesor.RFC}
                                    onChange={async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, asesor: { ...prev.asesor, RFC: value } }));
                                        if (value.length > 0) {
                                            const response = await AsesorService.getMaximoPorRetornar(value);
                                            // const maximoRetornoAsesor: number = response.data.value;
                                            // setMaximoRetornoAsesor(maximoRetornoAsesor);
                                        }
                                        setMovimiento((prev: Movimiento) => ({ ...prev, importe: "0" }))
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Seleccione una persona</option>
                                    {/*<option value="000">NO APLICA</option> */}
                                    {
                                        usuario.asesores.map((asesor: Asesor) => {
                                            return (
                                                <option value={asesor.RFC}  >{asesor.nombres} {asesor.paterno} {asesor.materno} </option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Forma de Pago
                                </Label>
                                <Input
                                    id="IdFormaPago"
                                    type="select"
                                    value={movimiento.IdFormaPago}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({
                                            ...prev,
                                            IdFormaPago: +value,
                                            empresa: { ...prev.empresa, RFC: +value === 1 ? "000" : prev.empresa.RFC },
                                            banco: { ...prev.banco, IdBanco: +value === 1 ? 0 : prev.banco.IdBanco, imgBanco: +value === 1 ? "" : prev.banco.imgBanco },
                                            cuentaBancaria: { ...prev.cuentaBancaria, IdBancoCuenta: +value === 1 ? 0 : prev.cuentaBancaria.IdBancoCuenta }
                                        }));
                                        if (+value === 1) setBanco(BancoModel.getEstructura())

                                    }}
                                    style={Styles.input}
                                >
                                    <option value={0}>Seleccione una forma de pago</option>
                                    <option value={1}>Efectivo</option>
                                    <option value={2}>Trasferencia</option>
                                    <option value={3}>Cheque</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Banco
                                </Label>
                                <Row>
                                    <Col style={{ minWidth: "85%", width: "85%", maxWidth: "85%" }} >
                                        <Input
                                            id="IdBanco"
                                            type="select"
                                            value={movimiento.banco.IdBanco}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setMovimiento((prev: Movimiento) => ({ ...prev, banco: { ...prev.banco, IdBanco: +value }, cuentaBancaria: { ...prev.cuentaBancaria, IdBancoCuenta: 0 } }));
                                                const banco: Banco | undefined = bancosLista.find(banco => banco.IdBanco === +value);
                                                if (banco !== undefined) setBanco(banco); else setBanco(BancoModel.getEstructura());
                                                listarCuentasBancarias(movimiento.empresa.RFC, +value)
                                            }}
                                            style={{ ...Styles.input, width: "100%" }}
                                            disabled={movimiento.IdFormaPago === 1}
                                        >
                                            <option value={-1}>Seleccione un banco</option>
                                            {
                                                movimiento.IdFormaPago === 1 &&
                                                <option value={0}>NO APLICA</option>
                                            }
                                            {
                                                bancosLista.map((banco: Banco) => {
                                                    return (
                                                        <option value={banco.IdBanco}  >{banco.nombreBanco} </option>
                                                    )
                                                })
                                            }
                                        </Input>
                                        {
                                            movimiento.IdFormaPago === 1 &&
                                            <Label style={{ color: colors.blue }} >
                                                Cuando la forma de pago es en efectivo no se asocia ningún banco
                                            </Label>
                                        }
                                    </Col>
                                    <Col style={{ minWidth: "15%", width: "15%", maxWidth: "15%", textAlign: "center" }} >
                                        {
                                            banco.IdBanco > 0 ?
                                                <img style={{ height: 35, borderRadius: 12 }} src={banco.imgBanco} alt="No se pudo cargar la imagen" />
                                                :
                                                <i>Logo</i>
                                        }
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ /*minWidth: "85%", width: "85%", maxWidth: "85%"*/ }} >
                            <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                Cuentas
                            </Label>
                            <Input
                                id="IdBancoCuenta"
                                type="select"
                                value={movimiento.cuentaBancaria.IdBancoCuenta}
                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                    setMovimiento((prev: Movimiento) => ({ ...prev, cuentaBancaria: { ...prev.cuentaBancaria, IdBancoCuenta: +value } }))
                                }}
                                style={{ ...Styles.input, width: "100%" }}
                                disabled={movimiento.IdFormaPago === 1}
                            >
                                <option value={-1}>Seleccione una cuenta</option>
                                {
                                    movimiento.IdFormaPago === 1 &&
                                    <option value={0}>NO APLICA</option>
                                }
                                {
                                    cuentasBancariasLista.map((cuenta: CuentaBancaria) => {
                                        return (
                                            <option value={cuenta.IdBancoCuenta} title={cuenta.descripcion} >{CuentaBancariaModel.cast(cuenta.numeroCuenta)} </option>
                                        )
                                    })
                                }
                            </Input>
                            {
                                movimiento.IdFormaPago === 1 ?
                                    null
                                    :
                                    (movimiento.empresa.RFC.length === 0 || movimiento.empresa.RFC === "000") && movimiento.banco.IdBanco === 0 ?
                                        <Label style={{ color: colors.blue }} >Seleccione un empresa y un banco para ver sus cuentas </Label>
                                        :
                                        (movimiento.empresa.RFC.length === 0 || movimiento.empresa.RFC === "000") ?
                                            <Label style={{ color: colors.blue }} >Seleccione un empresa para ver sus cuentas </Label>
                                            :
                                            movimiento.banco.IdBanco === 0 ?
                                                <Label style={{ color: colors.blue }} >Seleccione un banco para ver sus cuentas </Label>
                                                :
                                                cuentasBancariasLista.length === 0 ?
                                                    <Label style={{ color: colors.blue }} >No hay cuentas asignadas </Label>
                                                    :
                                                    <Label style={{ color: colors.blue }} >Se encontraron {cuentasBancariasLista.length} cuentas registradas</Label>

                            }
                            {
                                movimiento.IdFormaPago === 1 &&
                                <Label style={{ color: colors.blue }} >
                                    Cuando la forma de pago es en efectivo no se asocia ninguna cuenta
                                </Label>
                            }

                        </Col>

                        <Col>
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                        Folio
                                    </Label>
                                    <Input
                                        id="referencia"
                                        type="text"
                                        value={movimiento.referencia}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setMovimiento((prev: Movimiento) => ({ ...prev, referencia: value }));
                                        }}
                                        style={Styles.input}
                                    />
                                </FormGroup>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Movimiento de Ingreso Relacionado
                                </Label>
                                <Input
                                    id="movimientoRelacionado"
                                    type="text"
                                    value={`FOLIO: ${folioReferencia.length === 0 ? "Sin asignar" : folioReferencia}`}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                    }}
                                    style={Styles.input}
                                    onClick={() => setIsOpenModalMovimientoIngresoRelacionado(true)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Importe
                                </Label>
                                <Input
                                    id="importe"
                                    type="text"
                                    value={focusImporte ? movimiento.importe : Moneda.formatCurrency(movimiento.importe)}
                                    onFocus={() => {
                                        setFocusImporte(true);
                                    }}
                                    onBlur={() => {
                                        setFocusImporte(false);
                                    }}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        const importe: string = Number.singleNumbers(value);
                                        if (+importe <= maximoRetornoAsesor) setMovimiento((prev: Movimiento) => ({ ...prev, importe: importe }));
                                    }}
                                    style={Styles.input}
                                />
                                <Label style={{ color: colors.blue }} >
                                    {movimiento.asesor.RFC.length > 0 ? `Máximo a retornar: ${Moneda.formatCurrency(maximoRetornoAsesor)}` : "Seleccione una persona"}
                                </Label>

                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Observaciones
                                </Label>
                                <Input
                                    id="observaciones"
                                    type="textarea"
                                    value={movimiento.observaciones}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 100) return
                                        setMovimiento((prev: Movimiento) => ({ ...prev, observaciones: value }));
                                    }}
                                    style={{
                                        height: 100,
                                        minHeight: 100,
                                        maxHeight: 100,
                                        borderColor: colors.blue,
                                        boxShadow: "none"
                                    }}
                                />
                                <Label>
                                    {movimiento.observaciones.length} / 100
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Row style={{ width: "100%" }} >
                        <Col style={{ textAlign: "left" }} >
                            {
                                labelGuardarMovimiento.length > 0 &&
                                <Label style={{ color: colorMovimiento, fontSize: 20, fontWeight: "bold", fontFamily: "Montserrat" }} >
                                    {labelGuardarMovimiento}
                                </Label>
                            }
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Button
                                style={Styles.button}
                                onClick={() => guardarMovimiento()}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal >

            <Modal
                isOpen={isOpenModalMovimientoIngresoRelacionado}
                size="xl"
                fullscreen={data.isMovil}
                onOpened={() => { setMovimientosReferenciaLista([]); }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalMovimientoIngresoRelacionado(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Selecciona un movimiento de ingreso a relacionar
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Row>
                            <Col style={{ minWidth: "45%", width: "45%", maxWidth: "45%" }} >
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                        Fecha del Movimiento
                                    </Label>
                                    <Input
                                        id="movimientoRelacionado"
                                        type="date"
                                        value={movimientoReferencia.fechaMovimiento}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setMovimientosReferencia((prev) => ({ ...prev, fechaMovimiento: value }));
                                        }}
                                        style={Styles.input}

                                    />
                                </FormGroup>
                            </Col>
                            <Col style={{ minWidth: "45%", width: "45%", maxWidth: "45%" }} >
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                        Persona
                                    </Label>
                                    <Input
                                        type="select"
                                        value={movimientoReferencia.RFCAsesor}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setMovimientosReferencia((prev) => ({ ...prev, RFCAsesor: value }));
                                        }}
                                        style={Styles.input}
                                    >
                                        <option value="">Seleccione una persona</option>
                                        {
                                            usuario.asesores.map((asesor: Asesor) => {
                                                return (
                                                    <option value={asesor.RFC}  >{asesor.nombres} {asesor.paterno} {asesor.materno} </option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col style={{ minWidth: "10%", width: "10%", maxWidth: "10%" }} >
                                <Button
                                    style={{ ...Styles.button, marginTop: 30 }}
                                    onClick={async () => {
                                        const response: Response = await MovimientoService.listarIngresosRelacionados(movimientoReferencia.fechaMovimiento, movimientoReferencia.RFCAsesor);
                                        const movimientos: Movimiento[] = response.data.value;
                                        setMovimientosReferenciaLista(movimientos);
                                    }}
                                >
                                    Buscar
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Table
                                columns={columnsMovimientosReferencia}
                                data={movimientosReferenciaLista}
                                noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 30 }} >No hay movimientos registrados</Label>}
                            />
                        </Row>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Retornos;