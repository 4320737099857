import Empresa from "../interfaces/Empresa.interface";

const getEstructura = (): Empresa => {
    const empresa: Empresa = {
        IdEmpresa: 0,
        RFC: "",
        nombreEmpresa: "",
        giroEmpresarial: "",
        descripcion: ""
    };
    return empresa;
}

const EmpresaModel = {
    getEstructura,
}

export default EmpresaModel; 