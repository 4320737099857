import { FC, useState, ChangeEvent, KeyboardEvent, FocusEvent, useEffect, useRef } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Styles from "../../assets/css/Styles.css";
import Tiempo from "../../tools/Tiempo.tool";
import Components from "../../tools/Components.tool";
import { Add, Cached, Close, Delete, Edit, Search } from "@mui/icons-material";
import PorcentajeService from "../../services/Porcentaje.service";
import Response from "../../interfaces/Response.interface";
import { IconButton } from "@mui/material";
import Table, { TableColumn } from 'react-data-table-component';
import Swal from "sweetalert2";
import Porcentaje from "../../interfaces/Porcentaje.interface";
import PorcentajeModel from "../../models/Porcentaje.model";
import Number from "../../tools/Number.tool";
import CalculoPorcentaje from "../../interfaces/CalculoPorcentaje,interface.";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const Porcentajes: FC = () => {

    const [porcentaje, setPorcentaje] = useState<Porcentaje>(PorcentajeModel.getEstructura());
    const [porcentajesLista, setPorcentajesLista] = useState<Porcentaje[]>([]);
    const [calculoPorcentajeLista, setCalculoPorcentajeLista] = useState<CalculoPorcentaje[]>([]);
    const [textBuscar, setTextBuscar] = useState<string>("");
    const [hora, setHora] = useState<string>("");
    const [isOpenModalPorcentaje, setIsOpenModalPorcentaje] = useState<boolean>(false);
    const [labelGuarPorcentaje, setLabelGuarPorcentaje] = useState<string>("");
    const [colorCambioLabel, setColorCambioLabel] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());
    const labelRef = useRef<HTMLLabelElement>(null);


    useEffect(() => {
        listarPorcentajes("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const focusAndScroll = () => {
        if (labelRef.current) {
            labelRef.current.focus();
        }
        if (labelRef.current && labelRef.current.offsetTop < window.pageYOffset) {
            window.scrollTo(0, labelRef.current.offsetTop);
        }
    };

    const onChangePorcentaje = (name: string, value: string | number) => {
        setPorcentaje((prev) => ({ ...prev, [name]: value }));
    };

    const listarPorcentajes = async (text: string): Promise<void> => {
        const response: Response = await PorcentajeService.listar(text);
        const porcentajeLista: Porcentaje[] = response.data.value;
        setPorcentajesLista(porcentajeLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const listarTiposCalculo = async (): Promise<void> => {
        const response: Response = await PorcentajeService.listarTipoCalculos();
        const porcentajeLista: CalculoPorcentaje[] = response.data.value;
        setCalculoPorcentajeLista(porcentajeLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const guardarPorcentaje = async () => {
        if (validarDatosPorcentaje()) {
            const response: Response = await PorcentajeService.guardar(porcentaje);
            if (response.error === false) {
                listarPorcentajes(textBuscar);
                const IdPorcentaje: number = response.data.value;
                setPorcentaje((prev) => ({ ...prev, IdPorcentaje, }));
                setPorcentaje(PorcentajeModel.getEstructura());
                Components.focus("nombrePorcentaje");
                setLabelGuarPorcentaje("");
            } else {
                let label: string = "No se pudo guardar el tipo de porcentaje";
                let color: string = colors.red01;
                setLabelGuarPorcentaje(label);
                setColorCambioLabel(color);
            }
        }
    }

    const validarDatosPorcentaje = (): boolean => {
        let value: boolean = true;
        let label: string = "";
        let color: string = "";

        if (porcentaje.tipoCalculo.IdCalculoTipo === 0) {
            label = "Especifique el tipo de calculo";
            color = colors.warning;
            value = false;
        }
        if (porcentaje.nombrePorcentaje.length === 0) {
            label = "Especifique el nombre del porcentaje";
            color = colors.warning;
            value = false;
        }
        Components.focus("nombrePorcentaje");
        Tiempo.sleep(0.3).then(() => focusAndScroll())
        focusAndScroll()
        setLabelGuarPorcentaje(label);
        setColorCambioLabel(color);

        return value;
    }

    const eliminarUsuario = (porcentaje: Porcentaje) => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Porcentaje",
            text: `¿Estas seguro de eliminar al usuario ${porcentaje.nombrePorcentaje}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.red01,
            timer: 10000,
            timerProgressBar: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            reverseButtons: true,
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await PorcentajeService.eliminar(porcentaje.IdPorcentaje);
                if (response.error === false) {
                    listarPorcentajes(textBuscar);
                }
            }
        });
    }

    const columnsPorcentajes: TableColumn<Porcentaje>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > TIPO PORCENTAJE </Label>,
            cell: (porcentaje: Porcentaje) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{porcentaje.nombrePorcentaje}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > UTILIDAD </Label>,
            cell: (porcentaje: Porcentaje) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{porcentaje.porcentajeUtilidad} %</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > GASTOS DE OPERACIÓN </Label>,
            cell: (porcentaje: Porcentaje) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{porcentaje.porcentajeGastosOperacion} %</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > PORCENTAJE TOTAL </Label>,
            cell: (porcentaje: Porcentaje) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{porcentaje.porcentajeTotal} %</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > TIPO DE CALCULO </Label>,
            cell: (porcentaje: Porcentaje) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{porcentaje.tipoCalculo.calculoTipo}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (porcentaje: Porcentaje) =>
                <>
                    <IconButton style={{ color: colors.red01 }} onClick={() => {
                        eliminarUsuario(porcentaje)
                    }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton style={{ color: colors.blue }} onClick={() => {
                        setIsOpenModalPorcentaje(true);
                        setPorcentaje(porcentaje);
                    }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ];

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            TIPOS DE PORCENTAJE DADOS DE ALTA EN EL SISTEMA
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                        <FormGroup>
                            <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                Buscar Tipo de Porcentaje
                            </Label>
                            <InputGroup>
                                <Input
                                    id="textBuscar"
                                    type="text"
                                    placeholder="Nombre del tipo de porcentaje"
                                    value={textBuscar}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setTextBuscar(value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") listarPorcentajes(textBuscar);
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        setTextBuscar("");
                                        Tiempo.sleep(1).then(() => { listarPorcentajes("") });
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Close />
                                </InputGroupText>
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        listarPorcentajes(textBuscar);
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Search />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <IconButton
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => listarPorcentajes(textBuscar)}
                        >
                            <Cached />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIsOpenModalPorcentaje(true);
                            }}
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Add />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Label style={{ fontWeight: "bold" }} >
                            Ultima Actualización: {hora}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Table
                            columns={columnsPorcentajes}
                            data={porcentajesLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >Tabla Vacía</Label>}
                        />
                    </Col>
                </Row>
            </section>
            <Modal
                isOpen={isOpenModalPorcentaje}
                style={{ fontFamily: "Montserrat" }}
                onOpened={() => {
                    Components.focus("nombrePorcentaje");
                    listarTiposCalculo();
                }}
                onClosed={() => {
                    setPorcentaje(PorcentajeModel.getEstructura());
                    setLabelGuarPorcentaje("");
                }}
                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                    if (key === "Escape") setIsOpenModalPorcentaje(false);
                }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalPorcentaje(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Datos del Tipo de Porcentaje
                    </Label>
                </ModalHeader>
                <ModalBody style={{ height: "70vh", overflowY: "auto" }}  >
                    {
                        labelGuarPorcentaje.length > 0 &&
                        <Row style={{ marginTop: 0 }} >
                            <Col>
                                <label ref={labelRef} id="label" style={{ marginTop: 0, color: colorCambioLabel, fontWeight: "bold" }} >
                                    {labelGuarPorcentaje}
                                </label>
                            </Col>
                        </Row>
                    }

                    <Row style={{ marginTop: 10 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Nombre del Tipo de Porcentaje
                                </Label>
                                <Input
                                    id="nombrePorcentaje"
                                    type="text"
                                    name="nombrePorcentaje"
                                    value={porcentaje.nombrePorcentaje}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangePorcentaje(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("porcentajeUtilidad");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Porcentaje de Utilidad
                                </Label>
                                <Input
                                    id="porcentajeUtilidad"
                                    type="text"
                                    name="porcentajeUtilidad"
                                    value={porcentaje.porcentajeUtilidad}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        value = Number.singleNumbers(value);
                                        onChangePorcentaje(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("porcentajeGastosOperacion");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Porcentaje Gastos de Operación
                                </Label>
                                <Input
                                    id="porcentajeGastosOperacion"
                                    type="text"
                                    name="porcentajeGastosOperacion"
                                    value={porcentaje.porcentajeGastosOperacion}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        value = Number.singleNumbers(value);
                                        onChangePorcentaje(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("IdCalculoTipo");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Porcentaje Total
                                </Label>
                                <Input
                                    id="porcentajeTotal"
                                    name="porcentajeTotal"
                                    type="text"
                                    value={(+porcentaje.porcentajeUtilidad + +porcentaje.porcentajeGastosOperacion).toFixed(2)}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Tipo de Calculo
                                </Label>
                                <Input
                                    id="IdCalculoTipo"
                                    name="IdCalculoTipo"
                                    type="select"
                                    value={porcentaje.tipoCalculo.IdCalculoTipo}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setPorcentaje((prev) => ({
                                            ...prev,
                                            tipoCalculo: {
                                                ...prev.tipoCalculo,
                                                IdCalculoTipo: +value
                                            }
                                        }));
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("observacionesPorcentaje");
                                    }}
                                    style={Styles.input}
                                >
                                    <option value={0}>Seleccione una opción</option>
                                    {
                                        calculoPorcentajeLista.map((item) => {
                                            return (
                                                <option value={item.IdCalculoTipo}>{item.calculoTipo}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Observaciones
                                </Label>
                                <Input
                                    id="observacionesPorcentaje"
                                    name="observacionesPorcentaje"
                                    type="textarea"
                                    value={porcentaje.observacionesPorcentaje}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 255) return;
                                        onChangePorcentaje(name, value)
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") guardarPorcentaje();
                                    }}
                                    style={{ ...Styles.input, minHeight: 100, maxHeight: 100, height: 100, borderColor: colors.blue }}
                                />
                                <Label style={{ fontSize: 12 }} >
                                    {porcentaje.observacionesPorcentaje.length} / 255
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            setPorcentaje(PorcentajeModel.getEstructura());
                            Components.focus("nombrePorcentaje");
                            setLabelGuarPorcentaje("");
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={Styles.button}
                        onClick={() => guardarPorcentaje()}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </main>
    );
}

export default Porcentajes;