import { FC, useState, ChangeEvent, KeyboardEvent, FocusEvent, useEffect, useRef } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Styles from "../../assets/css/Styles.css";
import Tiempo from "../../tools/Tiempo.tool";
import Components from "../../tools/Components.tool";
import { Add, Cached, Close, Delete, Edit, Search } from "@mui/icons-material";
import EmpresaService from "../../services/Empresa.service";
import Response from "../../interfaces/Response.interface";
import { IconButton } from "@mui/material";
import Table, { TableColumn } from 'react-data-table-component';
import Swal from "sweetalert2";
import EmpresaModel from "../../models/Empresa.model";
import Empresa from "../../interfaces/Empresa.interface";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const Empresas: FC = () => {

    const [empresa, setEmpresa] = useState<Empresa>(EmpresaModel.getEstructura());
    const [empresasLista, setEmpresasLista] = useState<Empresa[]>([]);
    const [textBuscar, setTextBuscar] = useState<string>("");
    const [hora, setHora] = useState<string>("");
    const [isOpenModalEmpresa, setIsOpenModalEmpresa] = useState<boolean>(false);
    const [labelGuarEmpresa, setLabelGuarEmpresa] = useState<string>("");
    const [colorCambioLabel, setColorCambioLabel] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());
    const labelRef = useRef<HTMLLabelElement>(null);

    useEffect(() => {
        listarEmpresas("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const focusAndScroll = () => {                
        if (labelRef.current) {
            labelRef.current.focus();
        }
        if (labelRef.current && labelRef.current.offsetTop < window.pageYOffset) {
            window.scrollTo(0, labelRef.current.offsetTop);
        }
    };

    const onChangeEmpresa = (name: string, value: string | number) => {
        setEmpresa((prev) => ({ ...prev, [name]: value }));
    };

    const listarEmpresas = async (text: string): Promise<void> => {
        const response: Response = await EmpresaService.listar(text);
        const empresasLista: Empresa[] = response.data.value;        
        setEmpresasLista(empresasLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const guardarEmpresa = async () => {
        if (validarDatosEmpresa()) {
            const response: Response = await EmpresaService.guardar(empresa);
            if (response.error === false) {
                listarEmpresas(textBuscar);
                const IdBanco: number = response.data.value;
                setEmpresa((prev) => ({ ...prev, IdBanco, }));
            } else {
                let label: string = "No se pudo guardar la empresa";
                let color: string = colors.red01;
                setLabelGuarEmpresa(label);
                setColorCambioLabel(color);
            }
        }
    }

    const validarDatosEmpresa = (): boolean => {
        let value: boolean = true;
        let label: string = "";
        let color: string = "";
        if (empresa.nombreEmpresa.length === 0) {
            label = "Especifique el nombre de la empresa";
            Components.focus("nombreEmpresa");
            color = colors.warning;
            value = false;
        }
        if (empresa.RFC.length === 0) {
            label = "Especifique el RFC de la empresa";
            color = colors.warning;
            value = false;
            Components.focus("RFC");
        }

        Tiempo.sleep(0.3).then(() => focusAndScroll())
        focusAndScroll()
        setLabelGuarEmpresa(label);
        setColorCambioLabel(color);

        return value;
    }

    const eliminarEmpresa = (empresa: Empresa) => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Banco",
            text: `¿Estas seguro de eliminar el banco ${empresa.nombreEmpresa}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.red01,
            timer: 10000,
            timerProgressBar: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            reverseButtons: true,
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await EmpresaService.eliminar(empresa.RFC);
                if (response.error === false) {
                    listarEmpresas(textBuscar);
                }
            }
        });
    }

    const columnsBancos: TableColumn<Empresa>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > RFC EMPRESA </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{empresa.RFC}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRE EMPRESA </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{empresa.nombreEmpresa}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > GIRO EMPRESARIAL </Label>,
            cell: (empresa: Empresa) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{empresa.giroEmpresarial}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (empresa: Empresa) =>
                <>
                    <IconButton style={{ color: colors.red01 }} onClick={() => {
                        eliminarEmpresa(empresa)
                    }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton style={{ color: colors.blue }} onClick={() => {
                        setIsOpenModalEmpresa(true);
                        setEmpresa(empresa);
                    }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ];

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            EMPRESAS DADAS DE ALTA EN EL SISTEMA
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                        <FormGroup>
                            <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                Buscar Empresa
                            </Label>
                            <InputGroup>
                                <Input
                                    id="textBuscar"
                                    type="text"
                                    placeholder="Nombre de la empresa"
                                    value={textBuscar}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setTextBuscar(value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") listarEmpresas(textBuscar);
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        setTextBuscar("");
                                        Tiempo.sleep(1).then(() => { listarEmpresas("") });
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Close />
                                </InputGroupText>
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        listarEmpresas(textBuscar);
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Search />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <IconButton
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => listarEmpresas(textBuscar)}
                        >
                            <Cached />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIsOpenModalEmpresa(true);                                
                            }}
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Add />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Label style={{ fontWeight: "bold" }} >
                            Ultima Actualización: {hora}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Table
                            columns={columnsBancos}
                            data={empresasLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >Tabla Vacía</Label>}
                        />
                    </Col>
                </Row>
            </section>
            <Modal
                isOpen={isOpenModalEmpresa}
                style={{ fontFamily: "Montserrat" }}
                onOpened={() => {
                    Components.focus("RFC");
                }}
                onClosed={() => {
                    setEmpresa(EmpresaModel.getEstructura());
                    setLabelGuarEmpresa("");
                }}
                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                    if (key === "Escape") setIsOpenModalEmpresa(false);
                }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalEmpresa(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Datos de la empresa
                    </Label>
                </ModalHeader>
                <ModalBody style={{ height: "70vh", overflowY: "auto" }}  >


                    <Row style={{ marginTop: 10 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    RFC Empresa
                                </Label>
                                <Input
                                    id="RFC"
                                    type="text"
                                    name="RFC"
                                    value={empresa.RFC}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeEmpresa(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreEmpresa");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Nombre de la empresa
                                </Label>
                                <Input
                                    id="nombreEmpresa"
                                    type="text"
                                    name="nombreEmpresa"
                                    value={empresa.nombreEmpresa}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeEmpresa(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("giroEmpresarial");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Giro Empresarial
                                </Label>
                                <Input
                                    id="giroEmpresarial"
                                    type="text"
                                    name="giroEmpresarial"
                                    value={empresa.giroEmpresarial}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeEmpresa(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("descripcion");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Observaciones
                                </Label>
                                <Input
                                    id="descripcion"
                                    name="descripcion"
                                    type="textarea"
                                    value={empresa.descripcion}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 255) return;
                                        onChangeEmpresa(name, value)
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={{ ...Styles.input, minHeight: 100, maxHeight: 100, height: 100, borderColor: colors.blue }}
                                />
                                <Label style={{ fontSize: 12 }} >
                                    {empresa.descripcion.length} / 255
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        labelGuarEmpresa.length > 0 &&
                        <Row style={{ marginTop: 0 }} >
                            <Col>
                                <label ref={labelRef} id="label" style={{ marginTop: 0, color: colorCambioLabel, fontWeight: "bold" }} >
                                    {labelGuarEmpresa}
                                </label>
                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            setEmpresa(EmpresaModel.getEstructura());
                            Components.focus("nombreBanco");
                            setLabelGuarEmpresa("");
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={Styles.button}
                        onClick={() => guardarEmpresa()}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </main>
    );
}

export default Empresas;