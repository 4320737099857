import Permisos from "../interfaces/Permisos.interface";

const getEstructura = (): Permisos[] => {
    const permisos: Permisos[] = [
        {            
            IdPermiso: 1,
            Permiso: "Menu transacciones",
            valor: 0
        },
        {
            IdPermiso: 2,
            Permiso: "Ingresos / Egresos",
            valor: 0
        },
        {
            IdPermiso: 3,
            Permiso: "Concentrado Asesores",
            valor: 0
        },
        {
            IdPermiso: 4,
            Permiso: "Resumen por Asesor",
            valor: 0
        },
        {
            IdPermiso: 5,
            Permiso: "Cheques",
            valor: 0
        },
        {
            IdPermiso: 6,
            Permiso: "Menu Catalogos",
            valor: 0
        },
        {
            IdPermiso: 7,
            Permiso: "Asesores",
            valor: 0
        },
        {
            IdPermiso: 8,
            Permiso: "Tipo de Porcentaje",
            valor: 0
        },
        {
            IdPermiso: 9,
            Permiso: "Bancos",
            valor: 0
        },
        {
            IdPermiso: 10,
            Permiso: "Cuentas Bancarias",
            valor: 0
        },
        {
            IdPermiso: 11,
            Permiso: "Empresas",
            valor: 0
        },
        {
            IdPermiso: 12,
            Permiso: "Menu Sistemas",
            valor: 0
        },
        {
            IdPermiso: 13,
            Permiso: "Usuarios",
            valor: 0
        },        
        {
            IdPermiso: 14,
            Permiso: "Directorio",
            valor: 0
        },        
        {
            IdPermiso: 15,
            Permiso: "Directorio Modo Escritor",
            valor: 0
        },
        {
            IdPermiso: 16,
            Permiso: "Documentación",
            valor: 0
        }
    ]
    return permisos;
}


const PermisoModel = {
    getEstructura
}

export default PermisoModel;