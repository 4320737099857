import Asesor from "../interfaces/Asesor.interface";

const getEstructura = (): Asesor => {
    const usuario: Asesor = { RFC: "", nombres: "", paterno: "", materno: "", IdAsesoresUsuario: 0 };
    return usuario;
}

const AsesorModel = {
    getEstructura,
}

export default AsesorModel; 