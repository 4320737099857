import { ChangeEvent, FC, FocusEvent, KeyboardEvent, useEffect, useState } from "react";
import colors from "../config/Colors.config";
import Block from "../components/Block.component";
import { Col, Row, Label, FormGroup, InputGroup, Input, InputGroupText, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";
import Usuario from "../interfaces/Usuario.interface";
import UsuarioModel from "../models/Usuario.model";
import Cookies from 'universal-cookie';
import UsuarioService from "../services/Usuario.service";
import Components from "../tools/Components.tool";
import { LockOpen, Lock } from "@mui/icons-material";
import Tiempo from "../tools/Tiempo.tool";

import logo from "../assets/img/KHRONOS.png";
import logo2 from "../assets/img/svgWorking.svg";
import Response from "../interfaces/Response.interface";

const cookies = new Cookies();

const Login: FC = () => {

    const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [isHovered, setIsHovered] = useState({ 0: false, 1: false });
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [labelTextResponse, setLabelTextResponse] = useState<string>("");
    const [statusResponse, setStatusResponse] = useState<number>(0);
    const device: Device = DeviceConfig.data();

    const onChangeUsuario = (name: string, value: string | number) => {
        setUsuario((prev) => ({ ...prev, [name]: value }));
    }

    const handleMouseEnter = (index: number) => {
        setIsHovered((prev) => ({ ...prev, [index]: true }));
    };

    const handleMouseLeave = (index: number) => {
        setIsHovered((prev) => ({ ...prev, [index]: false }));
    };

    const login = async (): Promise<void> => {

        if (!validarDatosUsuario()) return
        const IdToast = toast.loading("Logueando usuario");
        let response: Response = await UsuarioService.login(usuario);        
        if (response.error) {
            toast.update(IdToast, { render: "Error en la petición", type: "error", autoClose: 5000, isLoading: false, closeButton: true });
        } else {
            if (response.data.status === 404) {
                toast.update(IdToast, { render: "Acceso Denegado", type: "warning", autoClose: 5000, isLoading: false, closeButton: true });
                setLabelTextResponse("Acceso Denegado, nombre de usuario y/o contraseña incorrectos");
                setStatusResponse(404);
                Components.focus("username");
            }
            if (response.data.status === 200) {
                if (response.data.value.estaActivo === 0) {
                    toast.update(IdToast, { render: "Acceso Denegado", type: "warning", autoClose: 5000, isLoading: false, closeButton: true });
                    setLabelTextResponse("Acceso Denegado");
                    setStatusResponse(500);
                    Components.focus("username");
                } else {                    
                    cookies.set("usuario", response.data.value, { path: '/' });                        
                    response = await UsuarioService.permisos(+response.data.value.IdUsuario)                
                    cookies.set("permisos", response.data.value, { path: '/' });                    
                    setLabelTextResponse("");
                    setStatusResponse(0);
                    toast.update(IdToast, { render: "Cargando Datos", type: "success", autoClose: 1500, isLoading: false, closeButton: true });
                    Tiempo.sleep(1.5).then(() => window.location.href = "/dashboard/?page=0");
                }
            }
        }
    }

    const validarDatosUsuario = (): boolean => {
        const { username, password } = usuario;
        let response: boolean = true;
        if (username.length === 0) {
            response = false;
            setLabelTextResponse("No has escrito tu nombre de usuario");
            Components.focus("username");
            setStatusResponse(404);
        } else if (password.length === 0) {
            response = false;
            setLabelTextResponse("No has escrito tu contraseña");
            Components.focus("password");
            setStatusResponse(404);
        }
        return response;
    }

    useEffect(() => {
        Components.focus("username");
    }, []);

    return (
        <section style={{ width: "100%", height: "100vh", backgroundColor: device.isDesktop ? colors.blue : colors.white }} >
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            {
                device.isDesktop &&
                <Block height={"10vh"} />}

            <center>
                <div style={{ height: device.isDesktop ? "80vh" : "100vh", width: device.isDesktop ? 1200 : "100%", backgroundColor: device.isDesktop ? colors.blue : colors.white, borderRadius: 12 }} >
                    <Row style={{ backgroundColor: device.isDesktop ? colors.white01 : colors.white, borderRadius: 12 }} >
                        <Col style={{ borderRadius: "12px 0px 0px 12px" }} >
                            <div style={{ backgroundColor: colors.white, marginTop: "5vh", marginRight: device.isDesktop ? 30 : 0, marginLeft: device.isDesktop ? 30 : 0, height: "70vh", borderRadius: 12, color: colors.blue }} >
                                <Row>
                                    <Col style={{ textAlign: "center" }} >
                                        <img
                                            src={logo}
                                            alt="No se pudo cargar imagen"
                                            style={{
                                                height: "17vh",
                                                marginTop: 10
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ width: "95%" }} >
                                    <Col style={{ textAlign: "left" }} >
                                        <FormGroup>
                                            <Label style={{ fontWeight: "bold", color: colors.blue, fontSize: device.isDesktop ? 18 : 20 }} >
                                                Nombre de Usuario
                                            </Label>
                                            <Input
                                                id="username"
                                                name="username"
                                                type="text"
                                                value={usuario.username}
                                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                    let name = "username";
                                                    onChangeUsuario(name, value)
                                                }}
                                                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                    if (key === "Enter") Components.focus("password");
                                                }}
                                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                    event.target.select();
                                                    handleMouseEnter(0);
                                                }}
                                                style={{
                                                    borderWidth: 2,
                                                    boxShadow: "none",
                                                    borderLeftColor: "transparent",
                                                    borderTopColor: "transparent",
                                                    borderRightColor: "transparent",
                                                    borderBottomColor: isHovered[0] ? colors.blue : colors.blue,
                                                }}
                                                onBlur={() => handleMouseLeave(0)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ width: "95%", marginTop: 30 }} >
                                    <Col style={{ textAlign: "left" }} >
                                        <FormGroup>
                                            <Label style={{ fontWeight: "bold", color: colors.blue, fontSize: device.isDesktop ? 18 : 20 }} >
                                                Contraseña
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    id="password"
                                                    name="password"
                                                    type={showPassword ? "text" : "password"}
                                                    value={usuario.password}
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        let name = "password";
                                                        onChangeUsuario(name, value)
                                                    }}
                                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                        if (key === "Enter") login();
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                        handleMouseEnter(1);
                                                    }}
                                                    style={{
                                                        borderWidth: 2,
                                                        boxShadow: "none",
                                                        borderLeftColor: "transparent",
                                                        borderTopColor: "transparent",
                                                        borderRightColor: "transparent",
                                                        borderBottomColor: isHovered[1] ? colors.blue : colors.blue,
                                                    }}
                                                    onBlur={() => handleMouseLeave(1)}
                                                />
                                                <InputGroupText
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        borderLeftWidth: 0,
                                                        borderBottomWidth: 2,
                                                        borderLeftColor: "transparent",
                                                        borderTopColor: "transparent",
                                                        borderRightColor: "transparent",
                                                        borderBottomColor: isHovered[1] ? colors.blue : colors.blue,
                                                        color: isHovered[1] ? colors.blue : colors.blue,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <LockOpen /> : <Lock />}
                                                </InputGroupText>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {
                                    labelTextResponse.length > 0 &&
                                    <Row style={{ marginTop: 10, marginRight: 5, marginLeft: 5, marginBottom: 20 }} >
                                        <Col style={{ textAlign: "left" }} >
                                            <Label style={{ fontSize: 18, fontWeight: "bold", color: statusResponse === 404 ? colors.warning : (statusResponse === 500 ? colors.red01 : colors.blue) }} >
                                                {labelTextResponse}
                                            </Label>
                                        </Col>
                                    </Row>
                                }
                                {
                                    device.isDesktop ?
                                        <Row style={{ width: "95%", marginTop: 30 }} >
                                            <Col>
                                                <Button
                                                    style={{ color: colors.blue, backgroundColor: colors.white01, fontWeight: "bold", borderColor: colors.blue, boxShadow: "none", width: "100%" }}
                                                    onClick={() => setUsuario(UsuarioModel.getEstructura())}
                                                >
                                                    Cancelar
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    style={{ backgroundColor: colors.blue, fontWeight: "bold", borderColor: colors.blue, boxShadow: "none", width: "100%" }}
                                                    onClick={() => login()}
                                                >
                                                    Iniciar Sesión
                                                </Button>
                                            </Col>
                                        </Row>
                                        :
                                        <Row style={{ width: "95%", marginTop: 30 }} >
                                            <Col>
                                                <Button
                                                    style={{ backgroundColor: colors.blue, fontWeight: "bold", borderColor: colors.blue, boxShadow: "none", width: "100%", fontSize: 20 }}
                                                    onClick={() => login()}
                                                >
                                                    Iniciar Sesión
                                                </Button>
                                                <Button
                                                    style={{ color: colors.blue, backgroundColor: colors.white01, fontWeight: "bold", borderColor: colors.blue, boxShadow: "none", width: "100%", marginTop: 20, fontSize: 20 }}
                                                    onClick={() => setUsuario(UsuarioModel.getEstructura())}
                                                >
                                                    Cancelar
                                                </Button>
                                            </Col>
                                        </Row>
                                }

                            </div>
                        </Col>
                        {
                            device.isDesktop &&
                            <Col>
                                <div>
                                    <Row style={{ marginTop: 30, width: "100%" }} >
                                        <Col style={{ textAlign: "center" }} >
                                            <img
                                                src={logo2}
                                                alt="No se puedo cargar la imagen"
                                                style={{
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 30, width: "100%" }} >
                                        <Col style={{ textAlign: "center" }} >
                                            <Label style={{ fontWeight: "bold", fontSize: 28, color: colors.blue }} >
                                                ASESORÍA ESPECIALIZADA EN FINANZAS
                                            </Label>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        }
                        {
                            device.isDesktop &&
                            <Block height={"5vh"} />
                        }
                    </Row>
                </div>
            </center>
            <Block height={"10vh"} backgroundColor={device.isDesktop ? colors.blue : colors.white} />
        </section>
    );
}

export default Login;