import CuentaBancaria from "../interfaces/CuentaBancaria.interface";

const getEstructura = (): CuentaBancaria => {
    const cuentaBancaria: CuentaBancaria = {
        IdBancoCuenta: 0,
        IdBanco: 0,
        descripcion: "",
        numeroCuenta: "",
        RFCEmpresa: ""
    };
    return cuentaBancaria;
}

const cast = (value: string) : string => {
    const textoSinGuiones = value.replace(/-/g, '');
    const grupos = textoSinGuiones.match(/.{1,4}/g);
    const textoFormateado = grupos ? grupos.join('-') : '';
    return textoFormateado;
}

const CuentaBancariaModel = {
    getEstructura,
    cast,
}

export default CuentaBancariaModel; 