import { ChangeEvent, FC, useEffect, useState, KeyboardEvent, } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Col, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Empresa from "../../interfaces/Empresa.interface";
import Banco from "../../interfaces/Banco.interface";
import Components from "../../tools/Components.tool";
import Styles from "../../assets/css/Styles.css";
import EmpresaService from "../../services/Empresa.service";
import Response from "../../interfaces/Response.interface";
import Tiempo from "../../tools/Tiempo.tool";
import BancoService from "../../services/Banco.service";
import Table, { TableColumn } from 'react-data-table-component';
import CuentaBancaria from "../../interfaces/CuentaBancaria.interface";
import { IconButton } from "@mui/material";
import { Add, Cached, Close, Delete, Edit, } from "@mui/icons-material";
import CuentaBancariaModel from "../../models/CuentaBancaria.model";
import Number from "../../tools/Number.tool";
import CuentaBancariaService from "../../services/CuentaBancaria.service";
import Swal from "sweetalert2";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const CuentasBancarias: FC = () => {

    const [cuentasBancariasLista, setCuentasBancariasLista] = useState<CuentaBancaria[]>([])
    const [cuentaBancaria, setCuentaBancaria] = useState<CuentaBancaria>(CuentaBancariaModel.getEstructura());

    const [referencia, setReferencia] = useState<{ RFCEmpresa: string, IdBanco: number }>({ RFCEmpresa: "", IdBanco: 0 });
    const [hora, setHora] = useState<string>("");

    const [empresasLista, setEmpresasLista] = useState<Empresa[]>([]);
    const [bancosLista, setBancosLista] = useState<Banco[]>([]);

    const [isOpenModalCuentaBancaria, setIsOpenModalCuentaBancaria] = useState<boolean>(false);

    const [labelGuarCuentaBancaria, setLabelGuarCuentaBancaria] = useState<string>("");
    const [colorCambioLabel, setColorCambioLabel] = useState<string>("");

    const [data] = useState<Device>(DeviceConfig.data());

    useEffect(() => {
        listarEmpresas("");
        listarBancos("");
        listarCuentasBancarias(referencia.RFCEmpresa, referencia.IdBanco);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listarEmpresas = async (text: string): Promise<void> => {
        const response: Response = await EmpresaService.listar(text);
        const empresasLista: Empresa[] = response.data.value;
        setEmpresasLista(empresasLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const listarBancos = async (text: string): Promise<void> => {
        const response: Response = await BancoService.listar(text);
        const bancosLista: Banco[] = response.data.value;
        setBancosLista(bancosLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const listarCuentasBancarias = async (RFCEmpresa: string, IdBanco: number): Promise<void> => {
        const response: Response = await CuentaBancariaService.listar(RFCEmpresa, IdBanco);
        const cuentasBancariasLista: CuentaBancaria[] = response.data.value;
        setCuentasBancariasLista(cuentasBancariasLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour());
    };

    const validarDatos = (): boolean => {
        const { RFCEmpresa, IdBanco, numeroCuenta } = cuentaBancaria;
        let value: boolean = true;
        let label: string = "";
        let color: string = "";

        if (numeroCuenta.length === 0) {
            label = "Especifique el numero de cuenta";
            color = colors.warning;
            value = false;
            Components.focus("numeroCuenta");
        }
        if (IdBanco === 0) {
            label = "Especifique el banco";
            color = colors.warning;
            value = false;
            Components.focus("IdBanco");
        }
        if (RFCEmpresa.length === 0) {
            label = "Especifique la empresa";
            color = colors.warning;
            value = false;
            Components.focus("RFCEmpresa");
        }
        setLabelGuarCuentaBancaria(label);
        setColorCambioLabel(color);
        return value;
    }

    const guardarCuentaBancaria = async () => {
        if (validarDatos()) {
            const response: Response = await CuentaBancariaService.guardar(cuentaBancaria);
            if (response.error === false) {                
                listarCuentasBancarias(referencia.RFCEmpresa, referencia.IdBanco);
                setCuentaBancaria((prev) => ({ ...prev, numeroCuenta: "" }));
                setLabelGuarCuentaBancaria("");
                Components.focus("numeroCuenta")
            }
        }

    }

    const eliminarCuentaBanco = async (cuentaBancaria: CuentaBancaria) => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Banco",
            text: `¿Estas seguro de eliminar la cuenta bancaria con terminación **** ${cuentaBancaria.numeroCuenta.slice(-4)}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.red01,
            timer: 10000,
            timerProgressBar: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            reverseButtons: true,
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await CuentaBancariaService.eliminar(cuentaBancaria.IdBancoCuenta);
                if (response.error === false) {
                    listarCuentasBancarias(referencia.RFCEmpresa, referencia.IdBanco);
                }
            }
        });
    }

    const onChangeCuentaBancaria = (name: string, value: string | number): void => {
        setCuentaBancaria((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const columnsCuentasBancarias: TableColumn<CuentaBancaria>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRE DEL BANCO </Label>,
            cell: (cuentaBancaria: CuentaBancaria) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{cuentaBancaria.nombreBanco}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NUMERO CUENTA </Label>,
            cell: (cuentaBancaria: CuentaBancaria) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} title={CuentaBancariaModel.cast(cuentaBancaria.numeroCuenta)} >{`**** ${cuentaBancaria.numeroCuenta.slice(-4)}`}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > OBSERVACIONES </Label>,
            cell: (cuentaBancaria: CuentaBancaria) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{cuentaBancaria.descripcion}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (cuentaBancaria: CuentaBancaria) =>
                <>
                    <IconButton
                        style={{ color: colors.red01 }}
                        onClick={() => {
                            eliminarCuentaBanco(cuentaBancaria)
                        }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton
                        style={{ color: colors.blue }}
                        onClick={() => {
                            setIsOpenModalCuentaBancaria(true);
                            setCuentaBancaria(cuentaBancaria);
                        }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ];

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >

                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            CUENTAS BANCARIAS DADAS DE ALTA EN EL SISTEMA
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col style={{ minWidth: "25%", width: "25%", maxWidth: "25%" }} >
                        <FormGroup>
                            <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                EMPRESA
                            </Label>
                            <Input
                                id="RFC"
                                name="RFC"
                                type="select"
                                value={referencia.RFCEmpresa}
                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                    setReferencia((prev) => ({
                                        ...prev,
                                        RFCEmpresa: value
                                    }));
                                    setCuentaBancaria((prev) => ({
                                        ...prev,
                                        RFCEmpresa: value
                                    }));
                                    Tiempo.sleep(0.3).then(() => listarCuentasBancarias(value, referencia.IdBanco));
                                }}
                                style={Styles.input}
                            >
                                <option value={""}>Seleccione una empresa</option>
                                {
                                    empresasLista.map((empresa: Empresa) => {
                                        return (
                                            <option value={empresa.RFC}>{empresa.nombreEmpresa}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col style={{ minWidth: "25%", width: "25%", maxWidth: "25%" }} >
                        <FormGroup>
                            <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                BANCO
                            </Label>
                            <Input
                                id="IdBanco"
                                name="IdBanco"
                                type="select"
                                value={referencia.IdBanco}
                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                    setReferencia((prev) => ({
                                        ...prev,
                                        IdBanco: +value
                                    }));
                                    setCuentaBancaria((prev) => ({
                                        ...prev,
                                        IdBanco: +value
                                    }));
                                    Tiempo.sleep(0.3).then(() => listarCuentasBancarias(referencia.RFCEmpresa, +value));
                                }}
                                style={Styles.input}
                            >
                                <option value={0}>Todos los bancos</option>
                                {
                                    bancosLista.map((banco: Banco) => {
                                        return (
                                            <option value={banco.IdBanco} title={banco.nombreCorto} >{banco.nombreBanco}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col style={{ textAlign: "right", minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                        <IconButton
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { listarCuentasBancarias(referencia.RFCEmpresa, referencia.IdBanco); }}
                        >
                            <Cached />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIsOpenModalCuentaBancaria(true);
                                setCuentaBancaria((prev) => ({ ...prev, ...referencia }));
                            }}
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Add />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Label style={{ fontWeight: "bold" }} >
                            Ultima Actualización: {hora}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    {
                        referencia.RFCEmpresa.length === 0 ?
                            <center>
                                <Label style={{ fontWeight: "bold", color: colors.black, fontSize: 18, marginTop: 50 }} >
                                    Seleccione una empresa para ver sus cuentas bancarias
                                </Label>
                            </center>
                            :
                            <Table
                                columns={columnsCuentasBancarias}
                                data={cuentasBancariasLista}
                                noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 50 }} >No hay cuentas bancarias registradas para esta empresa</Label>}
                            />
                    }
                </Row>

            </section>
            <Modal
                isOpen={isOpenModalCuentaBancaria}
                style={{ fontFamily: "Montserrat" }}
                onClosed={() => {
                    setCuentaBancaria(CuentaBancariaModel.getEstructura());
                    setLabelGuarCuentaBancaria("");
                }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalCuentaBancaria(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Datos de la cuenta de banco
                    </Label>
                </ModalHeader>
                <ModalBody>
                    {
                        labelGuarCuentaBancaria.length > 0 &&
                        <Row style={{ marginTop: 0 }} >
                            <Col>
                                <label id="label" style={{ marginTop: 0, color: colorCambioLabel, fontWeight: "bold" }} >
                                    {labelGuarCuentaBancaria}
                                </label>
                            </Col>
                        </Row>
                    }
                    <Row style={{ marginTop: 10 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Empresa
                                </Label>
                                <Input
                                    id="RFCEmpresa"
                                    type="select"
                                    name="RFCEmpresa"
                                    value={cuentaBancaria.RFCEmpresa}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeCuentaBancaria(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreCorto");
                                    }}
                                >
                                    <option value={0}>Seleccione una empresa</option>
                                    {
                                        empresasLista.map((empresa: Empresa) => {
                                            return (
                                                <option value={empresa.RFC}>{empresa.nombreEmpresa}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Banco
                                </Label>
                                <Input
                                    id="IdBanco"
                                    type="select"
                                    name="IdBanco"
                                    value={cuentaBancaria.IdBanco}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeCuentaBancaria(name, +value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreCorto");
                                    }}
                                >
                                    <option value={0}>Seleccione un banco</option>
                                    {
                                        bancosLista.map((banco: Banco) => {
                                            return (
                                                <option value={banco.IdBanco}>{banco.nombreBanco}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Numero de Cuenta
                                </Label>
                                <Input
                                    id="numeroCuenta"
                                    type="text"
                                    name="numeroCuenta"
                                    value={CuentaBancariaModel.cast(cuentaBancaria.numeroCuenta)}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 21) return;
                                        value = Number.singleNumbers(value);
                                        onChangeCuentaBancaria(name, value);
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreCorto");
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Observaciones
                                </Label>
                                <Input
                                    id="descripcion"
                                    type="textarea"
                                    name="descripcion"
                                    value={cuentaBancaria.descripcion}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 100) return;
                                        onChangeCuentaBancaria(name, value);
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreCorto");
                                    }}                                    
                                    style={{
                                        height: 100,
                                        minHeight: 100,
                                        maxHeight: 100,
                                        borderColor: colors.blue,
                                        boxShadow: "none"
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            setCuentaBancaria(CuentaBancariaModel.getEstructura())
                            setLabelGuarCuentaBancaria("");
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={Styles.button}
                        onClick={() => guardarCuentaBancaria()}
                    >
                        {cuentaBancaria.IdBancoCuenta === 0 ? "Guardar" : "Actualizar"}
                    </Button>
                </ModalFooter>
            </Modal>
        </main >
    )
}

export default CuentasBancarias;