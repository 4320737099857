import ApiConfig from "../config/Api.config";
import Porcentaje from "../interfaces/Porcentaje.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/porcentajes`

const guardar = async (porcentaje: Porcentaje): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { porcentaje }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (text: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { text }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listarTipoCalculos = async (): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar/tipos-calculos`,
        metodo: "GET",
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdPorcentaje: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { IdPorcentaje }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const PorcentajeService = {
    guardar,
    listar,
    listarTipoCalculos,
    eliminar,
}

export default PorcentajeService;