import { FC, ChangeEvent, useState, useEffect } from "react";
import { Button, Card, Col, DropdownItem, Input, Label, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { ArrowBack, AttachFile, Close, Delete, Download } from "@mui/icons-material";
import { FormGroup, IconButton } from "@mui/material";
import colors from "../../../config/Colors.config";
import Documentacion from "../../../interfaces/Documentacion.interface";
import iconFileAdd from "../../../assets/icons/iconFileAdd.png";
import iconFile from "../../../assets/icons/iconFile.png";
import ArchivoTool from "../../../tools/Archivo.tool";
import Styles from "../../../assets/css/Styles.css";
import Empresa from "../../../interfaces/Empresa.interface";
import Response from "../../../interfaces/Response.interface";
import Usuario from "../../../interfaces/Usuario.interface";
import UsuarioModel from "../../../models/Usuario.model";
import EmpresaService from "../../../services/Empresa.service";
import EmpresaModel from "../../../models/Empresa.model";
import DocumentacionService from "../../../services/Documentacion.service";
import Tiempo from "../../../tools/Tiempo.tool";
import DocumentacionModel from "../../../models/Documentacion.model";
import Block from "../../../components/Block.component";
import Swal from "sweetalert2";
import Components from "../../../tools/Components.tool";
import Device from "../../../interfaces/Device.interface";
import DeviceConfig from "../../../config/Device.config";

interface Item { label: string, index: string, modal?: boolean }

const Documento: FC<{ vDocumentacion: Documentacion, onChangeIndexPage: any, onChangeNombreEmpresa: any }> = ({ vDocumentacion, onChangeIndexPage, onChangeNombreEmpresa }) => {

    const [documentacion, setDocumentacion] = useState<Documentacion>(vDocumentacion);
    const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [mostrarLabelGuardado, setMostrarLabelGuardado] = useState<boolean>(false);
    const [index, setIndex] = useState<string>("/*-");
    const [labelGuardar, setLabelGuardar] = useState<string>("");
    const [colorLabelGuardar, setColorLabelGuardar] = useState<string>("");
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [data] = useState<Device>(DeviceConfig.data());
    const array: Item[] = DocumentacionModel.getArray()
    const subArray: Item[] = DocumentacionModel.getSubArray()

    useEffect(() => {
        listarEmpresaUsuarios();
        cargarDocumentacion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cargarDocumentacion = async (): Promise<void> => {
        const response: Response = await DocumentacionService.obtener(vDocumentacion.IdDocumentacion);
        if (response.error === false) {
            const documentacion: Documentacion = response.data.value;
            setDocumentacion(documentacion);
        }
    }

    const listarEmpresaUsuarios = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        const response: Response = await EmpresaService.empresasUsuario(usuario.IdUsuario);
        const empresasLista: Empresa[] = response.data.value;
        setUsuario((prev) => ({
            ...prev,
            empresas: empresasLista
        }));
    };

    const guardarDocumentacion = async () => {
        setMostrarLabelGuardado(true);
        if (documentacion.empresa.RFC.length === 0) {
            setLabelGuardar("Debes seleccionar una empresa");
            setColorLabelGuardar(colors.red01);
            Components.focus("RFCEmpresa");
        } else {
            const response: Response = await DocumentacionService.guardar(documentacion);
            if (response.error === false) {
                const IdDocumentacion: number = +response.data.value;
                setDocumentacion((prev: Documentacion) => ({ ...prev, IdDocumentacion }));
                onChangeNombreEmpresa(`DE ${documentacion.empresa.nombreEmpresa}`);
                setLabelGuardar("Documentacion guardada");
                setColorLabelGuardar(colors.greenSuccessful);

            }
        }
        Tiempo.sleep(7).then(() => setMostrarLabelGuardado(false));
    }

    return (
        <>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                <Col style={{ textAlign: "right" }} >
                    <IconButton
                        style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                        onClick={() => {
                            onChangeIndexPage("lista");
                            onChangeNombreEmpresa("");
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col>
                    <Label style={{ color: colors.black, fontSize: 20, }} >
                        DATOS DE LA EMPRESA
                    </Label>
                </Col>
            </Row>

            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col>
                    <FormGroup>
                        <Label style={{ color: colors.black, fontSize: 16, }} >
                            EMPRESA
                        </Label>
                        <Input
                            id="RFCEmpresa"
                            type="select"
                            value={documentacion.empresa.RFC}
                            style={{ ...Styles.input, width: "80%" }}
                            onChange={async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                const empresa: Empresa | undefined = usuario.empresas.find(empresa => empresa.RFC === value);
                                if (empresa) {
                                    setDocumentacion((prev: Documentacion) => ({ ...prev, empresa }));
                                } else {
                                    setDocumentacion((prev: Documentacion) => ({ ...prev, empresa: EmpresaModel.getEstructura() }));
                                }

                            }}
                        >
                            <option value="">Seleccione una empresa</option>
                            {
                                usuario.empresas.map((empresa: Empresa) => {
                                    return (
                                        <option value={empresa.RFC}>{empresa.nombreEmpresa}</option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <Label style={{ color: colors.black, fontSize: 16, }} >
                        EMPRESA RFC:
                    </Label>
                    <br />
                    <Label>
                        {documentacion.empresa.RFC}
                    </Label>
                </Col>
                <Col>
                    <Label style={{ color: colors.black, fontSize: 16, }} >
                        GIRO EMPRESARIAL:
                    </Label>
                    <br />
                    <Label>
                        {documentacion.empresa.giroEmpresarial}
                    </Label>
                </Col>
            </Row >
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col>
                    <Label style={{ color: colors.black, fontSize: 16, }} >
                        DESCRIPCIÓN:
                    </Label>
                    <br />
                    <Label>
                        {documentacion.empresa.descripcion}
                    </Label>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                <DropdownItem divider />
            </Row>

            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col>
                    <Label style={{ color: colors.black, fontSize: 20, }} >
                        DOCUMENTOS
                    </Label>
                    {documentacion.empresa.RFC.length === 0 && <><br /><span style={{ color: colors.red01, fontSize: 18 }} >NO SE A ESPECIFICADO LA EMPRESA</span></>}
                </Col>
            </Row>

            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10, }} >
                {
                    array.map((item: Item) => {
                        return (
                            <Col key={item.index} sm="12" md="6" lg="3" className="mb-4"  >
                                <Card className="custom-card" style={{ backgroundColor: colors.white01, padding: 10 }}>
                                    <FormGroup>
                                        <Label style={{ color: colors.black, fontSize: 16 }} >
                                            {item.label}
                                        </Label>
                                        <DropdownItem divider />
                                        <Row style={{ marginBottom: 0 }} >
                                            <Col>
                                                <img
                                                    src={documentacion[item.index as keyof Documentacion] === "" ? iconFileAdd : iconFile}
                                                    alt="/*-"
                                                    style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                    onClick={() => document.getElementById(item.index)?.click()}
                                                />
                                            </Col>
                                            {
                                                documentacion[item.index as keyof Documentacion] !== "" &&
                                                <>
                                                    <Col style={{ textAlign: "right" }} >
                                                        <IconButton
                                                            style={{ color: colors.red01, }}
                                                            onClick={() => setDocumentacion((prev: Documentacion) => ({ ...prev, [item.index]: "" }))}
                                                        >
                                                            <Delete />
                                                        </IconButton>

                                                        <IconButton
                                                            style={{ color: colors.blue, marginLeft: 10 }}
                                                            onClick={() => ArchivoTool.descargarArchivoDesdeBase64(documentacion[item.index as keyof Documentacion])}
                                                        >
                                                            <Download />
                                                        </IconButton>
                                                        {
                                                            item.modal &&
                                                            <IconButton
                                                                style={{ color: colors.blue }}
                                                                onClick={() => { setIsOpenModal(true); setIndex(item.index); }}
                                                            >
                                                                <AttachFile />
                                                            </IconButton>
                                                        }

                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                        <Input
                                            id={item.index}
                                            type="file"
                                            accept="*/*"
                                            onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                if (files !== null) {
                                                    const file: File = files[0];
                                                    try {
                                                        const base64: string = await ArchivoTool.convertirBase64(file);
                                                        setDocumentacion((prev: Documentacion) => ({ ...prev, [item.index]: base64 }));
                                                    } catch (error) {
                                                        console.error(error);
                                                    }
                                                }
                                            }}
                                            hidden
                                        />
                                    </FormGroup>

                                </Card>

                            </Col>
                        )
                    })
                }
            </Row >

            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 20 }} >
                <Col>
                    {
                        mostrarLabelGuardado &&
                        <Label style={{ fontSize: 20, color: colorLabelGuardar, fontWeight: "bold" }} >
                            {labelGuardar}
                        </Label>
                    }
                </Col>
                <Col style={{ textAlign: "right" }} >
                    <Button
                        style={{ ...Styles.button, marginRight: 15, backgroundColor: colors.red01, borderColor: colors.red01 }}
                        onClick={() => {
                            if (documentacion.IdDocumentacion === 0) { Components.focus("RFCEmpresa"); return }
                            Swal.fire({
                                icon: "question",
                                title: "Eliminar Documentacion",
                                text: `¿Estas seguro de eliminar la documentacion de la empresa ${documentacion.empresa.nombreEmpresa}?`,
                                showConfirmButton: true,
                                confirmButtonColor: colors.red01,
                                timer: 10000,
                                timerProgressBar: true,
                                confirmButtonText: "Eliminar",
                                cancelButtonText: "Cancelar",
                                showCancelButton: true,
                                reverseButtons: true,
                            }).then(async (response) => {
                                if (response.isConfirmed) {
                                    const response: Response = await DocumentacionService.eliminar(documentacion.IdDocumentacion);
                                    if (response.error === false) {
                                        onChangeNombreEmpresa("");
                                        setDocumentacion(DocumentacionModel.getEstructura());
                                        setMostrarLabelGuardado(true);
                                        setLabelGuardar("Se elimino la documentacion");
                                        setColorLabelGuardar(colors.greenSuccessful);
                                        Tiempo.sleep(5).then(() => { setMostrarLabelGuardado(false); Components.focus("RFCEmpresa"); });

                                    }
                                }
                            });
                        }}
                    >
                        Eliminar
                    </Button>
                    <Button
                        style={{ ...Styles.button }}
                        onClick={() => {
                            guardarDocumentacion();
                        }}
                    >
                        Guardar
                    </Button>
                </Col>
            </Row>
            <Block height={"5vh"} />
            <Modal
                isOpen={isOpenModal}
                style={{ fontFamily: "Montserrat", maxWidth: data.isDesktop ? "50%" : "" }}
                size={"lg"}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModal(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        DOCUMENTO ADJUNTO A {array.find(documento => documento.index === index)?.label}
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10, }} >
                        {
                            subArray.map((item: Item) => {
                                return (
                                    <Col key={item.index} sm="6" md="6" lg="6" className="mb-4"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white01, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    {item.label}
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={documentacion[`${index}_${item.index}` as keyof Documentacion] === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => document.getElementById(`${index}_${item.index}`)?.click()}
                                                        />
                                                    </Col>
                                                    {
                                                        documentacion[`${index}_${item.index}` as keyof Documentacion] !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red01, }}
                                                                    onClick={() => setDocumentacion((prev: Documentacion) => ({ ...prev, [`${index}_${item.index}`]: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(documentacion[`${index}_${item.index}` as keyof Documentacion])}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Input
                                                    id={`${index}_${item.index}`}
                                                    type="file"
                                                    accept="*/*"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setDocumentacion((prev: Documentacion) => ({ ...prev, [`${index}_${item.index}`]: base64 }));
                                                            } catch (error) {

                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {
                        mostrarLabelGuardado &&
                        <Row>
                            <Col>
                                <Label style={{ fontSize: 20, color: colorLabelGuardar, fontWeight: "bold" }} >
                                    {labelGuardar}
                                </Label>

                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ ...Styles.button }}
                        onClick={() => {
                            guardarDocumentacion();
                        }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Documento;