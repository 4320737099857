import ApiConfig from "../config/Api.config";
import Movimiento from "../interfaces/Movimiento.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import UsuarioModel from "../models/Usuario.model";

const url = `${ApiConfig.URLBase}/movimientos`
const urlCheques = `${ApiConfig.URLBase}/cheques`

const guardar = async (movimiento: Movimiento): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { movimiento }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (fechaInicial: string, fechaFinal: string, RFCAsesor: string, RFCEmpresa: string): Promise<Response> => {
    const IdUsuario: number = UsuarioModel.getIdUsuario()
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { fechaInicial, fechaFinal, RFCAsesor, RFCEmpresa, IdUsuario }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listarIngresos = async (RFCAsesor: string): Promise<Response> => {
    const IdUsuario: number = UsuarioModel.getIdUsuario()
    console.log({ url: `${url}/ingresos/listar/${IdUsuario}/${RFCAsesor}` })
    const request: Request = {
        url: `${url}/ingresos/listar/${IdUsuario}/${RFCAsesor}`,
        metodo: "GET",
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listarIngresosRelacionados = async (fechaMovimiento: string, RFCAsesor: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/ingresos/relacionados/listar`,
        metodo: "POST",
        data: { fechaMovimiento, RFCAsesor }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listarGastos = async (): Promise<Response> => {
    const IdUsuario: number = UsuarioModel.getIdUsuario();
    const request: Request = {
        url: `${url}/gastos/listar/${IdUsuario}`,
        metodo: "GET",
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listarRetornos = async (IdFormaPago: number): Promise<Response> => {
    const IdUsuario: number = UsuarioModel.getIdUsuario();
    const request: Request = {
        url: `${url}/retornos/listar/${IdFormaPago}/${IdUsuario}`,
        metodo: "GET",
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdMovimiento: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { IdMovimiento }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const saldoPendiente = async (IdReferenciaMovimientoIngreso: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/saldo/pendiente`,
        metodo: "POST",
        data: { IdReferenciaMovimientoIngreso }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const resumenAsesores = async (RFCAsesor: string, RFCEmpresa: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/asesores/resumen`,
        metodo: "POST",
        data: { RFCAsesor, RFCEmpresa }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listarCheques = async (fecha: string, RFCEmpresa: string, RFCAsesor: string, IdBanco: number, IdChequeStatus: number): Promise<Response> => {
    const IdUsuario: number = UsuarioModel.getIdUsuario();
    const request: Request = {
        url: `${urlCheques}/listar`,
        metodo: "POST",
        data: { fecha, RFCEmpresa, RFCAsesor, IdBanco, IdChequeStatus, IdUsuario }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const cambiarStatusCheque = async (IdMovimiento: number, IdChequeStatus: number): Promise<Response> => {
    const request: Request = {
        url: `${urlCheques}/status/cambiar`,
        metodo: "POST",
        data: { IdMovimiento, IdChequeStatus }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const MovimientoService = {
    guardar,
    listarIngresos,
    listarIngresosRelacionados,
    listarRetornos,
    listarGastos,
    eliminar,
    listar,
    saldoPendiente,
    listarCheques,
    cambiarStatusCheque,
    resumenAsesores,
}

export default MovimientoService;