import { FC, useState } from "react";
import colors from "../../config/Colors.config";
import { Col, Label, Row } from "reactstrap";
import Block from "../../components/Block.component";
import DocumentacionLista from "./documentacion/Lista.documentacion";
import Documento from "./documentacion/Documento.documento";
import DocumentacionModel from "../../models/Documentacion.model";
import Documentacion from "../../interfaces/Documentacion.interface";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const Documentation: FC = () => {

    const [documentacion, setDocumentacion] = useState<Documentacion>(DocumentacionModel.getEstructura());
    const [indexPage, setIndexPage] = useState<string>("lista");
    const [nombreEmpresa, setNombreEmpresa] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());

    const onChangeIndexPage = (index: string) => {
        setIndexPage(index);
    }

    const onChangeNombreEmpresa = (nombreEmpresa: string) => {
        setNombreEmpresa(nombreEmpresa);
    }

    const onChangeDocumentacion = (documentacion: Documentacion) => {
        setDocumentacion(documentacion);
    }

    return (
        <>
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                    <Block height={"5vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                DOCUMENTACIÓN {nombreEmpresa.length > 0 && `${nombreEmpresa.toUpperCase()}`}
                            </Label>
                        </Col>
                    </Row>
                    {
                        indexPage === "lista" &&
                        <DocumentacionLista onChangeIndexPage={onChangeIndexPage} onChangeNombreEmpresa={onChangeNombreEmpresa} onChangeDocumentacion={onChangeDocumentacion} />
                    }
                    {
                        indexPage === "ver-un-documento" &&
                        <Documento onChangeIndexPage={onChangeIndexPage} vDocumentacion={documentacion} onChangeNombreEmpresa={onChangeNombreEmpresa} />
                    }
                </section>
            </main>
        </>
    );
};

export default Documentation;