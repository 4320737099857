import { FC, useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import colors from "../config/Colors.config";
import { Button, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { IconButton } from "@mui/material";
import { AccountBalance, AccountCircle, Apartment, ArrowBackIos, ArrowForwardIos, AttachMoney, Close, Computer, CreditCard, DocumentScanner, FolderShared, FormatListBulleted, Group, Groups, Logout, Percent, Person, ReceiptLong, RequestPage } from "@mui/icons-material";
import Block from "../components/Block.component";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Home as IconHome, Menu as MenuIcon } from "@mui/icons-material";
import UsuarioModel from "../models/Usuario.model";
import Swal from "sweetalert2";
import Tiempo from "../tools/Tiempo.tool";
import Home from "./index/Home.index";
import Usuarios from "./index/Usuarios.index";
import MiPerfil from "./index/MiPerfil.index";
import Asesores from "./index/Asesores.index";
import Porcentaje from "./index/Porcentaje.index";
import Bancos from "./index/Bancos.index";
import CuentasBancarias from "./index/CuentasBancarias.index";
import Empresas from "./index/Empresas.index";
import IngresosEgresos from "./index/IngresosEgresos.index";
import ConcentradoAsesores from "./index/ConcentradoAsesores.index";
import Usuario from "../interfaces/Usuario.interface";
import Cheques from "./index/Cheques.index";
import ResumenAsesor from "./index/ResumenAsesor.index";
import DirectorioIndex from "./index/Directorio.index";
import Permisos from "../interfaces/Permisos.interface";
import PermisoModel from "../models/Permisos.model";
import Documentation from "./index/Documentacion.documento";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";
import imgLogo from "../assets/img/KHRONOS.png"

const Dashboard: FC = () => {

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [isOpenCanvas, setIsOpenCanvas] = useState<boolean>(false);
    const [indexPage, setIndexPage] = useState<number>(0);
    const [isHovered, setIsHovered] = useState({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false, 12: false, 13: false, 14: false, 15: false, 16: false, 17: false });
    const usuario: Usuario = UsuarioModel.getEstructura();
    const [permisos, setPermisos] = useState<Permisos[]>(PermisoModel.getEstructura());
    const location = useLocation();
    const navigate = useNavigate();

    const [data] = useState<Device>(DeviceConfig.data());

    const changeIndexPage = (index: number): void => {
        setIndexPage(index);
        navigate(`/dashboard/?page=${index}`);
    }

    const handleMouseEnter = (index: number) => {
        setIsHovered((prev) => ({ ...prev, [index]: true }));
    };

    const handleMouseLeave = (index: number) => {
        setIsHovered((prev) => ({ ...prev, [index]: false }));
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let pageParam: any = queryParams.get("page") ?? "0";

        if (pageParam === undefined || pageParam === null || pageParam === "") pageParam = 0;
        pageParam = parseInt(pageParam);
        changeIndexPage(pageParam);
        cargarUsuario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cargarUsuario = async () => {
        const response = UsuarioModel.validarUsuarioLocal();
        if (response) {
            document.body.innerHTML = `<div style="background-color: ${colors.white} ;width: 100%; height: 100vh;"></div>`;
            Swal.fire({
                icon: "error",
                title: "Acceso Denegado",
                text: "Debes iniciar sesión para usar el sistema",
                showConfirmButton: true,
                confirmButtonColor: colors.blue,
                timer: 10000,
                timerProgressBar: true,
            }).then((response) => {
                if (response.isConfirmed) {
                    window.location.href = "/";
                }
            })
            Tiempo.sleep(10).then(() => { window.location.href = "/"; });
        } else {
            const permisos: Permisos[] = await UsuarioModel.getPermiso();
            setPermisos(permisos);
        }
    }

    return (
        <section style={{ width: "100%", minHeight: "100vh", height: "auto", maxHeight: "auto", backgroundColor: colors.white01 }} >
            {
                data.isDesktop ?
                    <Sidebar
                        style={{
                            backgroundColor: colors.blue,
                            color: colors.white,
                            height: "100vh",
                            fontWeight: "bold",
                            position: "fixed",
                            width: collapsed ? "" : 320,
                            zIndex: 3,
                        }}
                        collapsed={collapsed}
                    >
                        <Menu
                            style={{ backgroundColor: colors.blue, marginTop: 0, height: "100%" }}
                        >
                            <Block height={10} />
                            <Row style={{ marginTop: 0, marginBottom: 10, marginLeft: 0, marginRight: 0 }} >
                                <Col style={{ textAlign: collapsed ? "center" : "right" }} >
                                    <IconButton
                                        onClick={() => {
                                            setCollapsed(!collapsed)
                                        }}
                                        style={{
                                            color: colors.white
                                        }}
                                    >
                                        {
                                            collapsed ?
                                                <ArrowForwardIos />
                                                :
                                                <ArrowBackIos />
                                        }
                                    </IconButton>
                                </Col>
                            </Row>
                            <MenuItem style={{ color: isHovered[0] ? colors.blue : colors.white }} onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(0)} icon={<IconHome />} onClick={() => changeIndexPage(0)} > Inicio </MenuItem>
                            {
                                permisos[0].valor === 1 &&
                                <SubMenu style={{ color: isHovered[1] ? colors.blue : colors.white, }} onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)} icon={<ReceiptLong />} label="Transacciones" >
                                    {
                                        permisos[1].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[2] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[2] ? colors.blue : colors.white, backgroundColor: isHovered[2] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(2)} icon={<AttachMoney />} onClick={() => changeIndexPage(2)}>Ingresos / Egresos</MenuItem>
                                    }
                                    {
                                        permisos[2].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[3] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[3] ? colors.blue : colors.white, backgroundColor: isHovered[3] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={() => handleMouseLeave(3)} icon={<Groups />} onClick={() => changeIndexPage(3)} >Concentrado de Asesores</MenuItem>
                                    }
                                    {
                                        permisos[3].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[4] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[4] ? colors.blue : colors.white, backgroundColor: isHovered[4] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={() => handleMouseLeave(4)} icon={<Person />} onClick={() => changeIndexPage(4)}>Resumen por Asesor</MenuItem>
                                    }
                                    {
                                        permisos[4].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[5] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[5] ? colors.blue : colors.white, backgroundColor: isHovered[5] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(5)} onMouseLeave={() => handleMouseLeave(5)} icon={<RequestPage />} onClick={() => changeIndexPage(5)}>Cheques</MenuItem>
                                    }
                                </SubMenu>
                            }
                            {
                                permisos[5].valor === 1 &&
                                <SubMenu style={{ color: isHovered[6] ? colors.blue : colors.white, }} onMouseEnter={() => handleMouseEnter(6)} onMouseLeave={() => handleMouseLeave(6)} icon={<FormatListBulleted />} label="Catálogos" >
                                    {
                                        permisos[6].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[7] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[7] ? colors.blue : colors.white, backgroundColor: isHovered[7] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(7)} onMouseLeave={() => handleMouseLeave(7)} icon={<Groups />} onClick={() => changeIndexPage(7)} >Asesores</MenuItem>
                                    }
                                    {
                                        permisos[7].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[8] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[8] ? colors.blue : colors.white, backgroundColor: isHovered[8] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(8)} onMouseLeave={() => handleMouseLeave(8)} icon={<Percent />} onClick={() => changeIndexPage(8)}>Tipos de Porcentajes</MenuItem>
                                    }
                                    {
                                        permisos[8].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[9] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[9] ? colors.blue : colors.white, backgroundColor: isHovered[9] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(9)} onMouseLeave={() => handleMouseLeave(9)} icon={<AccountBalance />} onClick={() => { changeIndexPage(9) }}>Bancos</MenuItem>
                                    }
                                    {
                                        permisos[9].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[10] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[10] ? colors.blue : colors.white, backgroundColor: isHovered[10] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(10)} onMouseLeave={() => handleMouseLeave(10)} icon={<CreditCard />} onClick={() => { changeIndexPage(10) }}>Cuentas Bancarias</MenuItem>
                                    }
                                    {
                                        permisos[10].valor === 1 &&
                                        <MenuItem style={{ border: isHovered[11] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[11] ? colors.blue : colors.white, backgroundColor: isHovered[11] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(11)} onMouseLeave={() => handleMouseLeave(11)} icon={<Apartment />} onClick={() => { changeIndexPage(11) }}>Empresas</MenuItem>
                                    }
                                </SubMenu>
                            }
                            {
                                permisos[13].valor === 1 &&
                                <MenuItem style={{ border: isHovered[16] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[16] ? colors.blue : colors.white, backgroundColor: isHovered[16] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(16)} onMouseLeave={() => handleMouseLeave(16)} icon={<FolderShared />} onClick={() => { changeIndexPage(16) }}>Directorio</MenuItem>
                            }
                            {
                                permisos[14].valor === 1 &&
                                <MenuItem style={{ border: isHovered[17] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[17] ? colors.blue : colors.white, backgroundColor: isHovered[17] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(17)} onMouseLeave={() => handleMouseLeave(17)} icon={<DocumentScanner />} onClick={() => { changeIndexPage(17) }}>Documentación</MenuItem>
                            }
                            <SubMenu style={{ color: isHovered[12] ? colors.blue : colors.white, }} onMouseEnter={() => handleMouseEnter(12)} onMouseLeave={() => handleMouseLeave(12)} icon={<Computer />} label="Sistema" >
                                {
                                    permisos[12].valor === 1 &&
                                    <MenuItem style={{ border: isHovered[13] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[13] ? colors.blue : colors.white, backgroundColor: isHovered[13] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(13)} onMouseLeave={() => handleMouseLeave(13)} icon={<Group />} onClick={() => changeIndexPage(13)}>Usuarios</MenuItem>
                                }
                                <MenuItem style={{ border: isHovered[14] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[14] ? colors.blue : colors.white, backgroundColor: isHovered[14] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(14)} onMouseLeave={() => handleMouseLeave(14)} icon={<AccountCircle />} onClick={() => changeIndexPage(14)} >Mi Perfil</MenuItem>
                                <MenuItem style={{ border: isHovered[15] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[15] ? colors.blue : colors.white, backgroundColor: isHovered[15] ? colors.white : colors.blue }} onMouseEnter={() => handleMouseEnter(15)} onMouseLeave={() => handleMouseLeave(15)} icon={<Logout />} onClick={() => { UsuarioModel.cerrarSesion() }}>Salir</MenuItem>


                            </SubMenu>
                        </Menu>

                    </Sidebar>
                    :
                    <>
                        <Row style={{ marginRight: 0, marginLeft: 0, backgroundColor: colors.blue }} >
                            <Col style={{ textAlign: "right" }} >
                                <Button
                                    style={{ backgroundColor: colors.white01, color: colors.blue, marginTop: 10, marginBottom: 10, borderColor: colors.blue, boxShadow: "none" }}
                                    onClick={() => setIsOpenCanvas(true)}
                                >
                                    <MenuIcon />
                                </Button>
                            </Col>
                        </Row>
                        <Offcanvas direction="end" isOpen={isOpenCanvas} >
                            <OffcanvasHeader
                                close={

                                    <IconButton
                                        style={{ backgroundColor: colors.blue01, color: colors.white, marginTop: 0, marginBottom: 0, borderColor: colors.blue, boxShadow: "none" }}
                                        onClick={() => setIsOpenCanvas(false)}
                                    >
                                        <Close />
                                    </IconButton>
                                }
                            >
                                <img src={imgLogo} alt="/*-" style={{ height: 50 }} />
                            </OffcanvasHeader>
                            <OffcanvasBody style={{ padding: 0 }} >
                                <Sidebar style={{ width: "100%", backgroundColor: colors.white }} >
                                    <Menu>
                                        <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(0)} icon={<IconHome />} onClick={() => { changeIndexPage(0); setIsOpenCanvas(false) }} > Inicio </MenuItem>
                                        {
                                            permisos[0].valor === 1 &&
                                            <SubMenu style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)} icon={<ReceiptLong />} label="Transacciones" >
                                                {
                                                    permisos[1].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(2)} icon={<AttachMoney />} onClick={() => { changeIndexPage(2); setIsOpenCanvas(false) }}>Ingresos / Egresos</MenuItem>
                                                }
                                                {
                                                    permisos[2].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={() => handleMouseLeave(3)} icon={<Groups />} onClick={() => { changeIndexPage(3); setIsOpenCanvas(false) }} >Concentrado de Asesores</MenuItem>
                                                }
                                                {
                                                    permisos[3].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={() => handleMouseLeave(4)} icon={<Person />} onClick={() => { changeIndexPage(4); setIsOpenCanvas(false) }}>Resumen por Asesor</MenuItem>
                                                }
                                                {
                                                    permisos[4].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(5)} onMouseLeave={() => handleMouseLeave(5)} icon={<RequestPage />} onClick={() => { changeIndexPage(5); setIsOpenCanvas(false) }}>Cheques</MenuItem>
                                                }
                                            </SubMenu>
                                        }
                                        {
                                            permisos[5].valor === 1 &&
                                            <SubMenu style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(6)} onMouseLeave={() => handleMouseLeave(6)} icon={<FormatListBulleted />} label="Catálogos" >
                                                {
                                                    permisos[6].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(7)} onMouseLeave={() => handleMouseLeave(7)} icon={<Groups />} onClick={() => { changeIndexPage(7); setIsOpenCanvas(false) }} >Asesores</MenuItem>
                                                }
                                                {
                                                    permisos[7].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(8)} onMouseLeave={() => handleMouseLeave(8)} icon={<Percent />} onClick={() => { changeIndexPage(8); setIsOpenCanvas(false) }}>Tipos de Porcentajes</MenuItem>
                                                }
                                                {
                                                    permisos[8].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(9)} onMouseLeave={() => handleMouseLeave(9)} icon={<AccountBalance />} onClick={() => { changeIndexPage(9); setIsOpenCanvas(false) }}>Bancos</MenuItem>
                                                }
                                                {
                                                    permisos[9].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(10)} onMouseLeave={() => handleMouseLeave(10)} icon={<CreditCard />} onClick={() => { changeIndexPage(10); setIsOpenCanvas(false) }}>Cuentas Bancarias</MenuItem>
                                                }
                                                {
                                                    permisos[10].valor === 1 &&
                                                    <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(11)} onMouseLeave={() => handleMouseLeave(11)} icon={<Apartment />} onClick={() => { changeIndexPage(11); setIsOpenCanvas(false) }}>Empresas</MenuItem>
                                                }
                                            </SubMenu>
                                        }
                                        {
                                            permisos[13].valor === 1 &&
                                            <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(16)} onMouseLeave={() => handleMouseLeave(16)} icon={<FolderShared />} onClick={() => { changeIndexPage(16); setIsOpenCanvas(false) }}>Directorio</MenuItem>
                                        }
                                        {
                                            permisos[14].valor === 1 &&
                                            <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(17)} onMouseLeave={() => handleMouseLeave(17)} icon={<DocumentScanner />} onClick={() => { changeIndexPage(17); setIsOpenCanvas(false) }}>Documentación</MenuItem>
                                        }
                                        <SubMenu style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(12)} onMouseLeave={() => handleMouseLeave(12)} icon={<Computer />} label="Sistema" >
                                            {
                                                permisos[12].valor === 1 &&
                                                <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(13)} onMouseLeave={() => handleMouseLeave(13)} icon={<Group />} onClick={() => { changeIndexPage(13); setIsOpenCanvas(false) }}>Usuarios</MenuItem>
                                            }
                                            <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(14)} onMouseLeave={() => handleMouseLeave(14)} icon={<AccountCircle />} onClick={() => { changeIndexPage(14); setIsOpenCanvas(false) }} >Mi Perfil</MenuItem>
                                            <MenuItem style={{ color: colors.blue, backgroundColor: colors.white }} onMouseEnter={() => handleMouseEnter(15)} onMouseLeave={() => handleMouseLeave(15)} icon={<Logout />} onClick={() => { UsuarioModel.cerrarSesion() }}>Salir</MenuItem>


                                        </SubMenu>
                                    </Menu>
                                </Sidebar>
                            </OffcanvasBody>
                        </Offcanvas>
                    </>

            }

            <div onClick={() => { setCollapsed(true) }} >
                {indexPage === 0 && <Home />}
                {indexPage === 2 && <IngresosEgresos />}
                {indexPage === 3 && <ConcentradoAsesores vUsuario={usuario} />}
                {indexPage === 4 && <ResumenAsesor vUsuario={usuario} />}
                {indexPage === 5 && <Cheques vUsuario={usuario} />}
                {indexPage === 7 && <Asesores />}
                {indexPage === 8 && <Porcentaje />}
                {indexPage === 9 && <Bancos />}
                {indexPage === 10 && <CuentasBancarias />}
                {indexPage === 11 && <Empresas />}
                {indexPage === 16 && <DirectorioIndex />}
                {indexPage === 17 && <Documentation />}
                {indexPage === 13 && <Usuarios />}
                {indexPage === 14 && <MiPerfil />}
            </div>
        </section >
    );
}

export default Dashboard