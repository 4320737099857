import { FC, useState, ChangeEvent, KeyboardEvent, FocusEvent, useEffect, useRef } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Styles from "../../assets/css/Styles.css";
import Tiempo from "../../tools/Tiempo.tool";
import Components from "../../tools/Components.tool";
import { Add, Cached, Close, Delete, Edit, Search } from "@mui/icons-material";
import BancoService from "../../services/Banco.service";
import Response from "../../interfaces/Response.interface";
import { IconButton } from "@mui/material";
import Table, { TableColumn } from 'react-data-table-component';
import Swal from "sweetalert2";
import Banco from "../../interfaces/Banco.interface";
import BancoModel from "../../models/Banco.model";
import Image from "../../tools/Imagen.tool";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const Bancos: FC = () => {

    const [banco, setBanco] = useState<Banco>(BancoModel.getEstructura());
    const [bancosLista, setBancosLista] = useState<Banco[]>([]);
    const [textBuscar, setTextBuscar] = useState<string>("");
    const [hora, setHora] = useState<string>("");
    const [isOpenModalBanco, setIsOpenModalBanco] = useState<boolean>(false);
    const [labelGuarBanco, setLabelGuarBanco] = useState<string>("");
    const [colorCambioLabel, setColorCambioLabel] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());

    const labelRef = useRef<HTMLLabelElement>(null);

    useEffect(() => {
        listarBancos("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const focusAndScroll = () => {
        if (labelRef.current) {
            labelRef.current.focus();
        }
        if (labelRef.current && labelRef.current.offsetTop < window.pageYOffset) {
            window.scrollTo(0, labelRef.current.offsetTop);
        }
    };

    const onChangeBanco = (name: string, value: string | number) => {
        setBanco((prev) => ({ ...prev, [name]: value }));
    };

    const listarBancos = async (text: string): Promise<void> => {
        const response: Response = await BancoService.listar(text);
        const bancosLista: Banco[] = response.data.value;
        setBancosLista(bancosLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const guardarBanco = async () => {
        if (validarDatosBanco()) {
            const response: Response = await BancoService.guardar(banco);
            if (response.error === false) {
                listarBancos(textBuscar);
                const IdBanco: number = response.data.value;
                setBanco((prev) => ({ ...prev, IdBanco, }));
                setBanco(BancoModel.getEstructura());
                Components.focus("nombreBanco");
                setLabelGuarBanco("");
            } else {
                let label: string = "No se pudo guardar el banco";
                let color: string = colors.red01;
                setLabelGuarBanco(label);
                setColorCambioLabel(color);
            }
        }
    }

    const validarDatosBanco = (): boolean => {
        let value: boolean = true;
        let label: string = "";
        let color: string = "";

        if (banco.nombreBanco.length === 0) {
            label = "Especifique el nombre del banco";
            color = colors.warning;
            value = false;
            Components.focus("nombreBanco");
        }

        Tiempo.sleep(0.3).then(() => focusAndScroll())
        focusAndScroll()
        setLabelGuarBanco(label);
        setColorCambioLabel(color);

        return value;
    }

    const eliminarBanco = (banco: Banco) => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Banco",
            text: `¿Estas seguro de eliminar el banco ${banco.nombreBanco}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.red01,
            timer: 10000,
            timerProgressBar: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            reverseButtons: true,
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await BancoService.eliminar(banco.IdBanco);
                if (response.error === false) {
                    listarBancos(textBuscar);
                }
            }
        });
    }

    const columnsBancos: TableColumn<Banco>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRE DEL BANCO </Label>,
            cell: (banco: Banco) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{banco.nombreBanco}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRE CORTO </Label>,
            cell: (banco: Banco) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{banco.nombreCorto}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > LOGO </Label>,
            cell: (banco: Banco) => banco.imgBanco.length === 0 ? <i>No hay logo</i> : <img src={banco.imgBanco} alt="No se pudo cargar la imagen" style={{ height: 35, width: 50 }} />,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (banco: Banco) =>
                <>
                    <IconButton style={{ color: colors.red01 }} onClick={() => {
                        eliminarBanco(banco)
                    }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton style={{ color: colors.blue }} onClick={() => {
                        setIsOpenModalBanco(true);
                        setBanco(banco);
                    }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ];

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            BANCOS DADOS DE ALTA EN EL SISTEMA
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                        <FormGroup>
                            <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                Buscar Banco
                            </Label>
                            <InputGroup>
                                <Input
                                    id="textBuscar"
                                    type="text"
                                    placeholder="Nombre del banco o nombre corto"
                                    value={textBuscar}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setTextBuscar(value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") listarBancos(textBuscar);
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        setTextBuscar("");
                                        Tiempo.sleep(1).then(() => { listarBancos("") });
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Close />
                                </InputGroupText>
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        listarBancos(textBuscar);
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Search />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <IconButton
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => listarBancos(textBuscar)}
                        >
                            <Cached />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIsOpenModalBanco(true);
                            }}
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Add />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Label style={{ fontWeight: "bold" }} >
                            Ultima Actualización: {hora}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Table
                            columns={columnsBancos}
                            data={bancosLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >Tabla Vacía</Label>}
                        />
                    </Col>
                </Row>
            </section>
            <Modal
                isOpen={isOpenModalBanco}
                style={{ fontFamily: "Montserrat" }}
                onOpened={() => {
                    Components.focus("nombreBanco");
                }}
                onClosed={() => {
                    setBanco(BancoModel.getEstructura());
                    setLabelGuarBanco("");
                }}
                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                    if (key === "Escape") setIsOpenModalBanco(false);
                }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalBanco(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Datos del Banco
                    </Label>
                </ModalHeader>
                <ModalBody style={{ height: "70vh", overflowY: "auto" }}  >
                    {
                        labelGuarBanco.length > 0 &&
                        <Row style={{ marginTop: 0 }} >
                            <Col>
                                <label ref={labelRef} id="label" style={{ marginTop: 0, color: colorCambioLabel, fontWeight: "bold" }} >
                                    {labelGuarBanco}
                                </label>
                            </Col>
                        </Row>
                    }

                    <Row style={{ marginTop: 10 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Nombre del Banco
                                </Label>
                                <Input
                                    id="nombreBanco"
                                    type="text"
                                    name="nombreBanco"
                                    value={banco.nombreBanco}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeBanco(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreCorto");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Nombre Corto
                                </Label>
                                <Input
                                    id="nombreCorto"
                                    type="text"
                                    name="nombreCorto"
                                    value={banco.nombreCorto}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeBanco(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("imgBanco");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Logo del Banco
                                </Label>
                                <Input
                                    id="imgBanco"
                                    type="file"
                                    name="imgBanco"
                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                        if (files) {
                                            let file = files[0];
                                            if (file) {
                                                let size = Image.obtenerTamañoImagenMB(file)
                                                if (size > 5) {
                                                    let label: string = "No se puede usar esta imagen ya que pesa mas de 5 MB";
                                                    let color: string = colors.warning;
                                                    setLabelGuarBanco(label);
                                                    setColorCambioLabel(color);
                                                } else {
                                                    let base64 = await Image.convertirImagenABase64(file);
                                                    setBanco((prev) => ({
                                                        ...prev,
                                                        imgBanco: base64
                                                    }));
                                                }
                                            }


                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                                banco.imgBanco.length === 0 ?
                                    <i style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >No hay logo</i>
                                    :
                                    <img
                                        src={banco.imgBanco}
                                        alt="No se pudo cargar la imagen"
                                        style={{
                                            height: 120,
                                        }}
                                    />
                            }

                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            setBanco(BancoModel.getEstructura());
                            Components.focus("nombreBanco");
                            setLabelGuarBanco("");
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={Styles.button}
                        onClick={() => guardarBanco()}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </main>
    );
}

export default Bancos;