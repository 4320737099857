import ApiConfig from "../config/Api.config";
import Banco from "../interfaces/Banco.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/bancos`

const guardar = async (banco: Banco): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { banco }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (text: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { text }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdBanco: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { IdBanco }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const BancoService = {
    guardar,
    listar,
    eliminar,
}

export default BancoService;