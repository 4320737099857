import { FC } from "react";
import Movimiento from "../../interfaces/Movimiento.interface";
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Usuario from "../../interfaces/Usuario.interface";
import Fecha from "../../tools/Fecha.tool";
import logo from "../../assets/img/KHRONOS.png";
import colors from "../../config/Colors.config";
import { Col, Row } from "reactstrap";
import MovimientoModel from "../../models/Movimiento.model";
import Moneda from "../../tools/Moneda.tool";

const PDFConcentradoAsesores: FC<{ fechaInicial: string, fechaFinal: string, movimientosLista: Movimiento[], usuario: Usuario }> = ({ fechaInicial, fechaFinal, movimientosLista, usuario }) => {
    const stylesPage = StyleSheet.create({
        page: { paddingBottom: 60, paddingLeft: 0, paddingRight: 0, paddingTop: 40, },
    });

    const styles = StyleSheet.create({
        page: {
            padding: 40,
        },
        table: {
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#bfbfbf",
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: {
            flexDirection: "row",
        },
        tableColHeader: {
            flex: 1,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#bfbfbf",
            borderTopWidth: 0,
            borderLeftWidth: 0,
            backgroundColor: "#f3f3f3",
        },
        tableCol: {
            flex: 1,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#bfbfbf",
            borderTopWidth: 0,
            borderLeftWidth: 0,
        },
        tableCellHeader: {
            margin: 5,
            fontSize: 10,
            fontWeight: "bold",
        },
        tableCell: {
            margin: 5,
            fontSize: 10,
        },

        footer_right: {
            position: 'absolute',
            bottom: 35,
            left: 40,
            right: 40,
            textAlign: 'right',
            fontSize: 10,
        },
        footer_left: {
            position: 'absolute',
            bottom: 35,
            left: 40,
            right: 40,
            textAlign: 'left',
            fontSize: 10,
        },
        font: {
            fontFamily: "Montserrat"
        }
    });

    return (
        <Document>
            <Page
                size="LEGAL"
                orientation='landscape'
                // orientation='portrait'
                wrap
                fixed
                style={stylesPage.page}
            >

                <View  >
                    <Row
                        style={{
                            color: colors.white,
                            marginRight: "15px",
                            marginLeft: "15px",
                        }}
                    >
                        <Col>

                            <Image
                                src={usuario.imgLogoEmpresa.length === 0 ? logo : usuario.imgLogoEmpresa}
                                // alt="No se pudo cargar la imagen"
                                style={{
                                    height: 80,
                                    width: 80,
                                    marginTop: -30,
                                    marginLeft: 50
                                }}
                            />

                        </Col>
                        <Col
                            style={{
                                textAlign: "center",
                                marginTop: "-60px"
                            }}
                        >
                            <Text style={{ color: colors.black, fontWeight: "bold", fontSize: 20 }} >{usuario.nombreEmpresa.length === 0 ? "KHRONOS" : usuario.nombreEmpresa}</Text>
                            <Text style={{ color: colors.black, fontWeight: "bold", marginTop: "5px", fontSize: 18 }} >REPORTE DE INGRESOS Y EGRESOS</Text>
                        </Col>
                    </Row>
                </View>
                <Row style={{ marginTop: 30, marginRight: 30, marginLeft: 30 }} >
                    <Text style={{ marginBottom: 10, fontSize: 14 }}>Reporte de Movimientos del {Fecha.formatDate(fechaInicial)} al {Fecha.formatDate(fechaFinal)}</Text>
                    <View style={styles.table}>
                        {/* Header */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>FECHA</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>PAGO</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>TIPO</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>ASESOR</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>FOLIO</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>IMPORTE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>PORCENTAJE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>UTILIDAD</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>GASTOS DE OPERACIÓN</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>POR RETORNAR</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>SALDO PENDIENTE</Text>
                            </View>
                        </View>
                        {/* Data */}
                        {movimientosLista.map((movimiento: Movimiento, index) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{Fecha.formatDate(movimiento.fechaMovimiento)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{MovimientoModel.selectFormaPago(movimiento.IdFormaPago)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{MovimientoModel.selectTipoMovimiento(movimiento.IdTipo)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{movimiento.asesor.nombres} {movimiento.asesor.paterno} {movimiento.asesor.materno}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{movimiento.referencia}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={{ ...styles.tableCell, color: MovimientoModel.selectColor(movimiento.IdTipo) }}>{Moneda.formatCurrency(movimiento.importe)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{movimiento.IdTipo === 1 ? `${movimiento.porcentaje.porcentajeTotal}%` : ""}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={{ ...styles.tableCell, color: colors.greenSuccessful }}>{movimiento.IdTipo === 1 ? `${Moneda.formatCurrency(movimiento.utilidad)}` : ""}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={{ ...styles.tableCell, color: colors.red01 }}>{movimiento.IdTipo === 1 ? `${Moneda.formatCurrency(movimiento.gastosOperacion)}` : ""}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={{ ...styles.tableCell, color: colors.blue }}>{movimiento.IdTipo === 1 ? `${Moneda.formatCurrency(movimiento.retornar)}` : ""}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={{ ...styles.tableCell, color: colors.blue }}>{movimiento.IdTipo === 1 ? Moneda.formatCurrency(movimiento.saldoPendiente) : ""}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </Row>
                {/* Pie de todas las páginas */}
                <Text style={styles.footer_left} render={() => (
                    `Documento creado el ${Fecha.getDateToDayWithFormat()}`
                )} fixed />
                <Text style={styles.footer_right} render={({ pageNumber, totalPages }) => (
                    `Página ${pageNumber} de ${totalPages} `
                )} fixed />
            </Page>
        </Document>
    )
}

export default PDFConcentradoAsesores;