import { FC, useEffect, useState, ChangeEvent, MouseEvent } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Col, FormGroup, Input, Label, Row, Button, DropdownItem, ModalHeader, ModalBody, Modal } from "reactstrap";
import Moneda from "../../tools/Moneda.tool";
import Encabezado from "../../interfaces/Encabezado.interface";
import Movimiento from "../../interfaces/Movimiento.interface";
import Response from "../../interfaces/Response.interface";
import MovimientoService from "../../services/Movimiento.service";
import Fecha from "../../tools/Fecha.tool";
import Table, { TableColumn } from 'react-data-table-component';
import MovimientoModel from "../../models/Movimiento.model";
import Styles from "../../assets/css/Styles.css";
import Empresa from "../../interfaces/Empresa.interface";
import Usuario from "../../interfaces/Usuario.interface";
import UsuarioModel from "../../models/Usuario.model";
import EmpresaService from "../../services/Empresa.service";
import Asesor from "../../interfaces/Asesor.interface";
import AsesorService from "../../services/Asesor.service";
import { IconButton } from "@mui/material";
import { Cached, Close, Download } from "@mui/icons-material";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import iconPDF from "../../assets/icons/iconPDF.png";
import iconExcel from "../../assets/icons/iconExcel.jpg";
import { /*PDFDownloadLink,*/ PDFViewer } from '@react-pdf/renderer';
import PDFConcentradoAsesores from "../../components/pdf/PDFConcentradoAsesores.pdf";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";
import Tiempo from "../../tools/Tiempo.tool";
import { toast, ToastContainer } from "react-toastify";

interface ReferenciaMovimiento { fechaInicial: string, fechaFinal: string, RFCAsesor: string, RFCEmpresa: string };

const ConcentradoAsesores: FC<{ vUsuario: Usuario }> = ({ vUsuario }) => {
    const [movimientosLista, setMovimientosLista] = useState<Movimiento[]>([]);
    const [usuario, setUsuario] = useState<Usuario>(vUsuario)
    const [encabezados, setEncabezados] = useState<Encabezado>({ importeTotal: 0, gastosOperacionTotal: 0, utilidadTotal: 0, utilidadNetoTotal: 0, saldoTotal: 0, retornadoTotal: 0, porRetornar: 0, retornoEnTransito: 0, gastosTotal: 0 });
    const [referenciaMovimientos, setReferenciaMovimientos] = useState<ReferenciaMovimiento>({ fechaInicial: Fecha.getDateToDay(), fechaFinal: Fecha.getDateToDay(), RFCAsesor: "", RFCEmpresa: "" })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isOpenModalPdf, setIsOpenModalPdf] = useState<boolean>(false);
    const [labelCargandoTabla, setLabelCargando] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        cargarUsuario();
        listarMovimientos(referenciaMovimientos.fechaInicial, referenciaMovimientos.fechaFinal, referenciaMovimientos.RFCAsesor, referenciaMovimientos.RFCEmpresa);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listarMovimientos = async (fechaInicial: string, fechaFinal: string, RFCAsesor: string, RFCEmpresa: string): Promise<void> => {
        setLabelCargando("Descargando información...")
        const response: Response = await MovimientoService.listar(fechaInicial, fechaFinal, RFCAsesor, RFCEmpresa);
        const movimientosLista: Movimiento[] = response.data.value;
        const encabezados: Encabezado = response.data.other.encabezados;
        for (let i = 0; i < movimientosLista.length; i++) {
            if (movimientosLista[i].IdTipo === 1) {
                const response: Response = await MovimientoService.saldoPendiente(movimientosLista[i].IdMovimiento);
                if (response.error === false) {
                    movimientosLista[i].saldoPendiente = movimientosLista[i].retornar - +response.data.value
                } else {
                    movimientosLista[i].saldoPendiente = -1
                }
            }
        }
        await setMovimientosLista(movimientosLista);
        await setEncabezados(encabezados);
        await setLabelCargando("Información descargada");
        await Tiempo.sleep(5).then(() => setLabelCargando("Información descargada"));
    }

    const listarEmpresaUsuarios = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        const response: Response = await EmpresaService.empresasUsuario(usuario.IdUsuario);
        const empresasLista: Empresa[] = response.data.value;
        setUsuario((prev) => ({
            ...prev,
            empresas: empresasLista
        }));
    };

    const listarAsesoresUsuarios = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        const response: Response = await AsesorService.asesoresUsuario(usuario.IdUsuario);
        const asesoresLista: Asesor[] = response.data.value;
        setUsuario((prev: Usuario) => ({
            ...prev,
            asesores: asesoresLista
        }))
    }

    const cargarUsuario = async () => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        setUsuario((prev: Usuario) => ({ ...prev, ...usuario }));
        listarEmpresaUsuarios();
        listarAsesoresUsuarios();
    }

    const columnsMovimientos: TableColumn<Movimiento>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FECHA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Fecha.formatDate(movimiento.fechaMovimiento)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > TIPO DE MOVIMIENTO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }}  >{MovimientoModel.selectTipoMovimiento(movimiento.IdTipo)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FORMA DE PAGO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} title={MovimientoModel.selectStatusCheque(movimiento.IdChequeStatus)} >{MovimientoModel.selectFormaPago(movimiento.IdFormaPago)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > NOMBRE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{`${movimiento.asesor.nombres} ${movimiento.asesor.paterno} ${movimiento.asesor.materno} `}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FOLIO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.referencia}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > EMPRESA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} title={`${movimiento.empresa.giroEmpresarial}`} >{movimiento.empresa.nombreEmpresa}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > IMPORTE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: MovimientoModel.selectColor(movimiento.IdTipo), fontSize: 15, whiteSpace: 'normal' }}>{Moneda.formatCurrency(movimiento.importe)}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > PORCENTAJE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} title={`Nombre: ${movimiento.porcentaje.nombrePorcentaje}, Utilidad: ${movimiento.porcentaje.porcentajeUtilidad}%, Gastos de Operacion: ${movimiento.porcentaje.porcentajeGastosOperacion}%`} >{movimiento.IdTipo === 1 ? `${movimiento.porcentaje.porcentajeTotal}%` : ""}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > UTILIDAD </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.greenSuccessful, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.IdTipo === 1 ? Moneda.formatCurrency(movimiento.utilidad) : ""}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > GASTOS DE OPERACIÓN </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.red01, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.IdTipo === 1 ? Moneda.formatCurrency(movimiento.gastosOperacion) : ""}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > POR RETORNAR </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.blue, fontSize: 15, whiteSpace: 'normal' }} > {movimiento.IdTipo === 1 ? Moneda.formatCurrency(movimiento.retornar) : ""} </Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > SALDO PENDIENTE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.blue, fontSize: 15, whiteSpace: 'normal' }} > {movimiento.IdTipo === 1 ? Moneda.formatCurrency(movimiento.saldoPendiente) : ""} </Label>,
        },
    ]

    return (
        <>
            <ToastContainer limit={3} position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                    <Block height={"5vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                MOVIMIENTOS DE INGRESO Y EGRESOS POR CONCENTRADO DE ASESORES
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >TOTAL DE IMPORTE</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.importeTotal)}</Label>
                        </Col>
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >TOTAL DE UTILIDAD</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.utilidadTotal)}</Label>
                        </Col>
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >TOTAL DE GASTOS DE OPERACIÓN</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.gastosOperacionTotal)}</Label>
                        </Col>
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >TOTAL DE GASTOS</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.gastosTotal)}</Label>
                        </Col>

                        {/* <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >TOTAL DE UTILIDAD NETO</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.utilidadNetoTotal)}</Label>
                        </Col> */}

                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >TOTAL DE SALDO</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.saldoTotal)}</Label>
                        </Col>
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >RETORNADO</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.retornadoTotal)}</Label>
                        </Col>
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >RETORNO EN TRANSITO</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.retornoEnTransito)}</Label>
                        </Col>
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                            <Label style={{ fontSize: 14 }} >POR RETORNAR</Label>
                            <br />
                            <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.porRetornar)}</Label>
                        </Col>
                    </Row>
                    <DropdownItem divider />
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                        <Col>
                            <Label style={{ fontSize: 18, }} >
                                MOVIMIENTOS POR RANGO DE FECHAS
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.blue01, color: colors.white, marginRight: 15, height: 38, width: 38, borderRadius: 50 }}
                                title="Descargar Reporte"
                                onClick={handleClick}
                            >
                                <Download />
                            </IconButton>
                            <Menu
                                id="long-menu-2"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        width: '22ch',
                                    },
                                }}
                            >
                                <Row>
                                    <center>
                                        <Label style={{ fontSize: 16, fontWeight: "bold", color: colors.blue }}>
                                            Forma de descarga
                                        </Label>
                                    </center>
                                </Row>
                                <DropdownItem divider />
                                <Button
                                    style={{ backgroundColor: "transparent", boxShadow: "none", borderColor: "transparent", padding: 0, width: "100%" }}
                                    onClick={() => {
                                        handleClose();
                                        setIsOpenModalPdf(true);
                                        // if (movimientosLista.length === 0) {
                                        //     toast.warning("No hay movimientos registrados");
                                        // } else {
                                        //     setIsOpenModalPdf(true);
                                        // }
                                    }}
                                >
                                    <MenuItem
                                        style={{ boxShadow: "none", marginTop: 0 }}
                                        onClick={handleClose}
                                    >
                                        <img src={iconPDF} height={30} width={30} alt="No se pudo cargar la imagen" />
                                        <Label style={{ marginLeft: 7, marginTop: 0, color: colors.blue, cursor: "pointer" }}>
                                            Generar PDF
                                        </Label>
                                    </MenuItem>
                                </Button>
                                <Button
                                    style={{ backgroundColor: "transparent", boxShadow: "none", borderColor: "transparent", padding: 0, width: "100%" }}
                                    onClick={() => {
                                        // ExcelModel.MovimientosIngresosConcentradoAsesores(movimientosLista);
                                        // ExcelModel.MovimientosIngresosConcentradoAsesoresFaltantesPorRetornar(listaMovimientosAsesoresPorRetornar)
                                        // listarMovimientos(filtro, rangoFechas);
                                        toast.info("");
                                    }}
                                >
                                    <MenuItem
                                        style={{ boxShadow: "none", marginTop: 0 }}
                                        onClick={handleClose}
                                    >
                                        <img src={iconExcel} height={30} width={30} alt="No se pudo cargar la imagen" />
                                        <Label style={{ marginLeft: 7, marginTop: 0, color: colors.blue, cursor: "pointer" }}>
                                            Generar Excel
                                        </Label>
                                    </MenuItem>
                                </Button>
                            </Menu>
                            <IconButton
                                style={{ backgroundColor: colors.blue01, color: colors.white, marginRight: 15, height: 38, width: 38, borderRadius: 50 }}
                                onClick={() => listarMovimientos(referenciaMovimientos.fechaInicial, referenciaMovimientos.fechaFinal, referenciaMovimientos.RFCAsesor, referenciaMovimientos.RFCEmpresa)}
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Fecha Inicial
                                </Label>
                                <Input
                                    id="fechaInicial"
                                    type="date"
                                    value={referenciaMovimientos.fechaInicial}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaMovimientos((prev: ReferenciaMovimiento) => ({ ...prev, fechaInicial: value }));
                                        listarMovimientos(value, referenciaMovimientos.fechaFinal, referenciaMovimientos.RFCAsesor, referenciaMovimientos.RFCEmpresa)
                                    }}
                                    max={referenciaMovimientos.fechaFinal}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Fecha Final
                                </Label>
                                <Input
                                    id="fechaFinal"
                                    type="date"
                                    value={referenciaMovimientos.fechaFinal}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaMovimientos((prev: ReferenciaMovimiento) => ({ ...prev, fechaFinal: value }));
                                        listarMovimientos(referenciaMovimientos.fechaInicial, value, referenciaMovimientos.RFCAsesor, referenciaMovimientos.RFCEmpresa)
                                    }}
                                    style={Styles.input}
                                    min={referenciaMovimientos.fechaInicial}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Empresa
                                </Label>
                                <Input
                                    id="RFCEmpresa"
                                    type="select"
                                    value={referenciaMovimientos.RFCEmpresa}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaMovimientos((prev: ReferenciaMovimiento) => ({ ...prev, RFCEmpresa: value }));
                                        listarMovimientos(referenciaMovimientos.fechaInicial, referenciaMovimientos.fechaFinal, referenciaMovimientos.RFCAsesor, value)
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Todas las empresas</option>
                                    <option value="000">NO APLICA</option>
                                    {
                                        usuario?.empresas.map((empresa: Empresa) => {
                                            return (
                                                <option value={empresa.RFC} title={empresa.giroEmpresarial} >{empresa.nombreEmpresa}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Asesor
                                </Label>
                                <Input
                                    id="RFCPersona"
                                    type="select"
                                    value={referenciaMovimientos.RFCAsesor}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaMovimientos((prev: ReferenciaMovimiento) => ({ ...prev, RFCAsesor: value }));
                                        listarMovimientos(referenciaMovimientos.fechaInicial, referenciaMovimientos.fechaFinal, value, referenciaMovimientos.RFCEmpresa)
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Todos los asesores</option>
                                    <option value="000">NO APLICA</option>
                                    {
                                        usuario.asesores.map((asesor: Asesor) => {
                                            return (
                                                <option value={asesor.RFC}  >{asesor.nombres} {asesor.paterno} {asesor.materno} </option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col>
                            <Label style={{ fontWeight: "bold" }} >
                                {labelCargandoTabla}
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col>
                            {
                                data.isDesktop ?
                                    <i>Pulse la tecla SHIFT y haga scroll con la rueda del ratón sobre la tabla para visualizar todas sus columnas.</i>
                                    :
                                    <i>Deslice el dedo a la derecha y a la izquierda de la tabla para ver mas información</i>
                            }

                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                        <Table
                            columns={columnsMovimientos}
                            data={movimientosLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 30 }} >No hay movimientos registrados</Label>}
                        />
                    </Row>
                    <Block height={"5vh"} />
                </section>
            </main >
            <Modal
                isOpen={isOpenModalPdf}
                fullscreen
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.pdf.grey02, color: colors.pdf.white01, height: 38, width: 38, borderRadius: 50 }}
                            onClick={() => { setIsOpenModalPdf(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.pdf.grey01 }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >

                    </Label>
                </ModalHeader>
                <ModalBody style={{ padding: 0, height: "100vh" }} >
                    <PDFViewer
                        style={{
                            width: "100%",
                            height: "90vh"
                        }}

                    >
                        <PDFConcentradoAsesores fechaInicial={referenciaMovimientos.fechaInicial} fechaFinal={referenciaMovimientos.fechaFinal} movimientosLista={movimientosLista} usuario={usuario} />
                    </PDFViewer>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ConcentradoAsesores;