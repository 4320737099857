import ApiConfig from "../config/Api.config";
import Directorio from "../interfaces/Directorio.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/directorio`

const listar = async (IdArea: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { IdArea }

    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const guardar = async (directorio: Directorio): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { directorio }

    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdDirectorio: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { IdDirectorio }

    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const DirectorioService = {
    listar,
    guardar,
    eliminar,
}

export default DirectorioService;