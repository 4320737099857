import ApiConfig from "../config/Api.config";
import Empresa from "../interfaces/Empresa.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";


const url = `${ApiConfig.URLBase}/empresas`

const guardar = async (empresa: Empresa): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { empresa }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (text: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { text }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (RFC: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { RFC }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const empresasUsuario = async (IdUsuario: number): Promise<Response> => {    
    const request: Request = {
        url: `${url}/empresas/listar`,
        metodo: "POST",
        data: {
            IdUsuario
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const empresasNoUsuario = async (IdUsuario: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/empresas/no/listar`,
        metodo: "POST",
        data: {
            IdUsuario
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const empresaAsignarUsuario = async (IdUsuario: number, RFC: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/empresas/asignar`,
        metodo: "POST",
        data: {
            IdUsuario,
            RFC
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const empresaDesignarUsuario = async (IdEmpresaUsuario: number | undefined): Promise<Response> => {
    const request: Request = {
        url: `${url}/empresas/designar`,
        metodo: "POST",
        data: {
            IdEmpresaUsuario
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}


const EmpresaService = {
    guardar,
    listar,
    eliminar,
    empresasUsuario,
    empresasNoUsuario,
    empresaAsignarUsuario,
    empresaDesignarUsuario,
}

export default EmpresaService;