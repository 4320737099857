import Banco from "../interfaces/Banco.interface";

const getEstructura = (): Banco => {
    const banco: Banco = {
        IdBanco: 0,
        nombreBanco: "",
        nombreCorto: "",
        imgBanco: ""
    };
    return banco;
}

const BancoModel = {
    getEstructura,
}

export default BancoModel; 