import ApiConfig from "../config/Api.config";
import Asesor from "../interfaces/Asesor.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/asesores`

const guardar = async (asesor: Asesor): Promise<Response> => {    
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { asesor }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (RFC: string): Promise<Response> => {    
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { RFC }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (text: string): Promise<Response> => {    
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { text }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const asesoresUsuario = async (IdUsuario: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/asesores/listar`,
        metodo: "POST",
        data: {
            IdUsuario
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const asesoresNoUsuario = async (IdUsuario: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/asesores/no/listar`,
        metodo: "POST",
        data: {
            IdUsuario
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}


const asesoresAsignarUsuario = async (IdUsuario: number, RFC: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/asesores/asignar`,
        metodo: "POST",
        data: {
            IdUsuario,
            RFC
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const asesoresDesignarUsuario = async (IdAsesoresUsuario: number | undefined): Promise<Response> => {
    const request: Request = {
        url: `${url}/asesores/designar`,
        metodo: "POST",
        data: {
            IdAsesoresUsuario
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const getMaximoPorRetornar = async (RFCAsesor : string): Promise<Response> => {
    const request: Request = {
        url: `${url}/maximo-por-retornar`,
        metodo: "POST",
        data: {
            RFCAsesor
        }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const AsesorService = {
    listar,
    asesoresUsuario,
    asesoresNoUsuario,
    asesoresAsignarUsuario,
    asesoresDesignarUsuario,
    guardar,
    eliminar,
    getMaximoPorRetornar,
}

export default AsesorService;