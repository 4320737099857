import { FC, useEffect, useState, ChangeEvent } from "react";
import colors from "../../config/Colors.config";
import { Col, Input, Label, Row, Card, CardBody, CardTitle, CardText, Button, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Block from "../../components/Block.component";
import { FormGroup, IconButton } from "@mui/material";
import { Add, Cached, Close, Edit, EditOff, Mail, Phone, RadioButtonUnchecked } from "@mui/icons-material";
import Response from "../../interfaces/Response.interface";
import DirectorioService from "../../services/Directorio.service";
import Directorio from "../../interfaces/Directorio.interface";
import Styles from "../../assets/css/Styles.css";
import Number from "../../tools/Number.tool";
import Components from "../../tools/Components.tool";
import Permisos from "../../interfaces/Permisos.interface";
import PermisoModel from "../../models/Permisos.model";
import UsuarioModel from "../../models/Usuario.model";
import Tiempo from "../../tools/Tiempo.tool";
import Swal from "sweetalert2";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const DirectorioIndex: FC = () => {

    const [directorio, setDirectorio] = useState<Directorio[]>([]);
    const [elemento, setElemento] = useState<Directorio>({ IdDirectorio: 0, area: { IdArea: 1, area: "" }, persona: "", telefono: "", correo: "", descripcion: [] })
    const [IdArea, setIdArea] = useState<number>(0);
    const [maxHeight, setMaxHeight] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const [estaModoLector, setEstaModoLector] = useState<boolean>(true)
    const [permisos, setPermisos] = useState<Permisos[]>(PermisoModel.getEstructura());
    const [data] = useState<Device>(DeviceConfig.data());

    useEffect(() => {
        listarDirectorio(IdArea);
        const cardElements = document.querySelectorAll('.custom-card');
        const heights = Array.from(cardElements).map(card => (card as HTMLElement).offsetHeight);
        setMaxHeight(Math.max(...heights));
        cargarUsuario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listarDirectorio = async (IdArea: number): Promise<void> => {
        const response: Response = await DirectorioService.listar(IdArea);
        if (response.error === false) {
            const directorio: Directorio[] = response.data.value;
            setDirectorio(directorio);
        }
    }

    const cargarUsuario = async (): Promise<void> => {
        const permisos: Permisos[] = await UsuarioModel.getPermiso();
        setPermisos(permisos);
    }


    return (
        <>
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                    <Block height={"5vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                DIRECTORIO DE ATENCIÓN
                            </Label>
                        </Col>
                    </Row>

                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col style={{ minWidth: "30%", width: "30%", maxWidth: "30%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 16, fontWeight: "bold" }} >
                                    Seleccione el area
                                </Label>
                                <Input
                                    id="area"
                                    type="select"
                                    value={IdArea}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setIdArea(+value)
                                        listarDirectorio(+value);
                                    }}
                                    style={Styles.input}
                                >
                                    <option value={0}>Todas las areas</option>
                                    <option value={1}>Gerencia General</option>
                                    <option value={2}>Documentación</option>
                                    <option value={3}>Transferencia</option>
                                    <option value={4}>Facturación</option>
                                    <option value={5}>Auditoria</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: "right", minWidth: "70%", width: "70%", maxWidth: "70%" }} >
                            {
                                permisos[14].valor === 1 &&
                                <IconButton
                                    style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                                    onClick={() => { setEstaModoLector(false); setIsOpenModal(true); setElemento({ IdDirectorio: 0, area: { IdArea: 1, area: "" }, persona: "", telefono: "", correo: "", descripcion: [] }); }}
                                >
                                    <Add />
                                </IconButton>
                            }
                            <IconButton
                                style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                                onClick={() => { listarDirectorio(IdArea); }}
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>
                    {/* <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                        <Table
                            columns={columnsDirectorio}
                            data={directorio}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 30 }} >Registro vació</Label>}
                        />
                    </Row> */}
                    {/* <Container> */}
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                        {directorio.map((directorio: Directorio) => (
                            <Col key={directorio.IdDirectorio} sm="12" md="6" lg="3" className="mb-4">
                                <Card className="custom-card" style={{ height: maxHeight, cursor: "pointer" }} onClick={() => { setElemento(directorio); setIsOpenModal(true) }} >
                                    <CardBody>
                                        <CardTitle tag="h5">{directorio.persona}</CardTitle>
                                        <CardTitle tag="h6">{directorio.area.area}</CardTitle>
                                        <DropdownItem divider />
                                        <CardText style={{ cursor: "pointer" }} onClick={() => { window.location.href = `tel:${directorio.telefono}` }} > <Phone style={{ marginLeft: 5, color: colors.blue }} /> {Number.formatPhoneNumber(directorio.telefono)}</CardText>
                                        <CardText style={{ color: colors.blue, cursor: "pointer" }} onClick={() => { window.location.href = `mailto:${directorio.correo}` }} > <Mail style={{ marginLeft: 5 }} /> {directorio.correo}</CardText>
                                        <Row>
                                            <Col>
                                                {
                                                    directorio.descripcion.map((item: string) => {
                                                        return (
                                                            <>
                                                                <Label style={{}} >
                                                                    <RadioButtonUnchecked style={{ width: 10, height: 10, borderRadius: 50, backgroundColor: colors.black, color: colors.black }} /> {item}
                                                                </Label>
                                                                <br />
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {/* </Container> */}
                </section>
            </main >
            <Modal
                isOpen={isOpenModal}
                size="lg"
                style={{ fontFamily: "Montserrat" }}
                onClosed={() => { setElemento({ IdDirectorio: 0, area: { IdArea: 1, area: "" }, persona: "", telefono: "", correo: "", descripcion: [] }); setEstaModoLector(true); }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <Col style={{ textAlign: "right" }} >
                            {
                                permisos[14].valor === 1 &&
                                <IconButton
                                    style={{ backgroundColor: colors.blue01, color: colors.white, marginRight: 15 }}
                                    onClick={() => { setEstaModoLector(!estaModoLector); Tiempo.sleep(0.3).then(() => { Components.focus("persona"); }); }}
                                >
                                    {estaModoLector ? <Edit /> : <EditOff />}
                                </IconButton>
                            }
                            <IconButton
                                style={{ backgroundColor: colors.blue01, color: colors.white }}
                                onClick={() => { setIsOpenModal(false); setEstaModoLector(false) }}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >

                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Card style={{}} >
                        <CardBody>
                            {
                                estaModoLector ?
                                    <CardTitle tag="h5">{elemento.persona}</CardTitle>
                                    :
                                    <FormGroup style={{ marginTop: 0 }} >
                                        <Label>
                                            Persona
                                        </Label>
                                        <Input
                                            id="persona"
                                            value={elemento.persona}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setElemento((prev: Directorio) => ({ ...prev, persona: value }))
                                            }}
                                            style={Styles.input}
                                        />
                                    </FormGroup>
                            }
                            {
                                estaModoLector ?
                                    <CardTitle tag="h6">{elemento.area.area}</CardTitle>
                                    :
                                    <FormGroup style={{ marginTop: 5 }} >
                                        <Label>
                                            Area
                                        </Label>
                                        <Input
                                            value={elemento.area.IdArea}
                                            type="select"
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setElemento((prev: Directorio) => ({ ...prev, area: { ...prev.area, IdArea: +value } }))
                                            }}
                                            style={Styles.input}
                                        >
                                            <option value={1}>Gerencia General</option>
                                            <option value={2}>Documentación</option>
                                            <option value={3}>Transferencia</option>
                                            <option value={4}>Facturación</option>
                                            <option value={5}>Auditoria</option>
                                        </Input>
                                    </FormGroup>
                            }

                            <DropdownItem divider />
                            {
                                estaModoLector ?
                                    <CardText style={{ cursor: "pointer" }} onClick={() => { window.location.href = `tel:${elemento.telefono}` }} > <Phone style={{ marginLeft: 5, color: colors.blue }} /> {Number.formatPhoneNumber(elemento.telefono)}</CardText>
                                    :
                                    <FormGroup style={{ marginTop: 5 }} >
                                        <Label>
                                            Teléfono
                                        </Label>
                                        <Input
                                            value={elemento.telefono}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 10) return
                                                setElemento((prev: Directorio) => ({ ...prev, telefono: value }))
                                            }}
                                            style={Styles.input}
                                        />
                                    </FormGroup>
                            }
                            {
                                estaModoLector ?
                                    <CardText style={{ color: colors.blue, cursor: "pointer" }} onClick={() => { window.location.href = `mailto:${elemento.correo}` }} > <Mail style={{ marginLeft: 5 }} /> {elemento.correo}</CardText>
                                    :
                                    <FormGroup style={{ marginTop: 5 }} >
                                        <Label>
                                            Correo
                                        </Label>
                                        <Input
                                            value={elemento.correo}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setElemento((prev: Directorio) => ({ ...prev, correo: value }))
                                            }}
                                            style={Styles.input}
                                        />
                                    </FormGroup>
                            }
                            {
                                estaModoLector ?
                                    <Row>
                                        <Col>
                                            {
                                                elemento.descripcion.map((item: string) => {
                                                    return (
                                                        <>
                                                            <Label style={{}} >
                                                                <RadioButtonUnchecked style={{ width: 10, height: 10, borderRadius: 50, backgroundColor: colors.black, color: colors.black, marginLeft: 10 }} /> {item}
                                                            </Label>
                                                            <br />
                                                        </>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    :
                                    <FormGroup style={{ marginTop: 10 }} >
                                        <Label>
                                            Descripción
                                        </Label>
                                        <Input
                                            value={elemento.descripcion}
                                            type="textarea"
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                const array: string[] = value.split(",")
                                                setElemento((prev: Directorio) => ({ ...prev, descripcion: array }))
                                            }}
                                            // style={Styles.input}
                                            style={{ borderColor: colors.blue, boxShadow: "none" }}
                                        />
                                    </FormGroup>
                            }

                        </CardBody>
                    </Card>
                </ModalBody>
                {
                    !estaModoLector &&
                    <ModalFooter>
                        <Button
                            style={{ ...Styles.button, backgroundColor: colors.red01, borderColor: colors.red01 }}
                            onClick={() => {
                                Swal.fire({
                                    icon: "question",
                                    title: "Eliminar Directorio",
                                    text: `¿Estas seguro de eliminar este directorio?`,
                                    showConfirmButton: true,
                                    confirmButtonColor: colors.red01,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    confirmButtonText: "Eliminar",
                                    cancelButtonText: "Cancelar",
                                    showCancelButton: true,
                                    reverseButtons: true,
                                }).then(async (response) => {
                                    if (response.isConfirmed) {
                                        const response: Response = await DirectorioService.eliminar(elemento.IdDirectorio);
                                        if (response.error === false) {
                                            listarDirectorio(IdArea);
                                            setElemento({ IdDirectorio: 0, area: { IdArea: 1, area: "" }, persona: "", telefono: "", correo: "", descripcion: [] });
                                            Tiempo.sleep(0.3).then(() => Components.focus("persona"));
                                        }
                                    }
                                });
                            }}
                        >
                            Eliminar
                        </Button>
                        <Button
                            style={Styles.button}
                            onClick={async () => {
                                const response: Response = await DirectorioService.guardar(elemento);
                                if (response.error === false) {
                                    const IdDirectorio: number = +response.data.value
                                    setElemento((prev: Directorio) => ({ ...prev, IdDirectorio }));
                                    listarDirectorio(IdArea);
                                }
                            }}
                        >
                            {elemento.IdDirectorio === 0 ? "Guardar" : "Actualizar"}
                        </Button>
                    </ModalFooter>
                }
            </Modal>
        </>
    );
}


export default DirectorioIndex 