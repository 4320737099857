import { ChangeEvent, FC, useState, KeyboardEvent, FocusEvent, useEffect } from "react";
import colors from "../../config/Colors.config";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Block from "../../components/Block.component";
import Usuario from "../../interfaces/Usuario.interface";
import UsuarioModel from "../../models/Usuario.model";
import Styles from "../../assets/css/Styles.css";
import Components from "../../tools/Components.tool";
import UsuarioService from "../../services/Usuario.service";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Tiempo from "../../tools/Tiempo.tool";
import Response from "../../interfaces/Response.interface";
import Image from "../../tools/Imagen.tool";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const MiPerfil: FC = () => {

    const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [isOpenModalPassword, setIsOpenModalPassword] = useState<boolean>(false);
    const [labelCambioPassword, setLabelCambioPassword] = useState<string>("");
    const [colorCambioLabel, setColorCambioLabel] = useState<string>("");
    const [labelGuarUsuario, setLabelGuarUsuario] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());
    
    const onChangeUsuario = (name: string, value: string | number) => {
        setUsuario((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        getUsuario();
    }, []);

    const getUsuario = async () => {
        const IdUsuario: number = UsuarioModel.getIdUsuario();
        const response: Response = await UsuarioService.getUsuario(IdUsuario);
        if (response.error === false) {
            const usuario: Usuario = response.data.value;
            setUsuario((prev) => ({ ...prev, ...usuario }));
        }
    };

    const validarDatosUsuario = (): boolean => {
        let value: boolean = false;
        let label: string = "";
        let color: string = "";

        if (usuario.IdUsuario === 0 && usuario.password.length === 0) {
            label = "La contraseña debe de tener al menos una letra";
            color = colors.warning;
            setLabelGuarUsuario(label);
            setColorCambioLabel(color);
            value = true;
            Components.focus("password");
        }

        if (usuario.username.length === 0) {
            label = "El nombre de usuario debe de tener al menos una letra";
            color = colors.warning;
            setLabelGuarUsuario(label);
            setColorCambioLabel(color);
            value = true;
            Components.focus("username");
        }

        Tiempo.sleep(5).then(() => {
            label = "";
            setLabelGuarUsuario(label);
        });
        return value;
    }

    const guardarUsuario = async () => {
        let label: string = "";
        let color: string = "";
        if (validarDatosUsuario()) return;
        if (usuario.IdUsuario === 0) {
            const value: Response = await UsuarioService.usuarioRepetido(usuario.username)
            if (value.data.value > 0) {
                label = "Ya hay un usuario con este username";
                color = colors.warning;
                setLabelGuarUsuario(label);
                setColorCambioLabel(color);
                Components.focus("username");
                Tiempo.sleep(5).then(() => {
                    label = "";
                    setLabelGuarUsuario(label);
                });
                return;
            }
        }

        let response: Response = await UsuarioService.guardar(usuario);

        const { error, data } = response;
        if (error === false) {
            if (data.status === 200) {
                const IdUsuario: number = response.data.value;
                setUsuario((prev) => ({ ...prev, IdUsuario }));
                label = "Datos guardados";
                color = colors.greenSuccessful;
                setLabelGuarUsuario(label);
                setColorCambioLabel(color);
            } else {
                label = "No se pudieron guardar los datos";
                color = colors.warning;
                setLabelGuarUsuario(label);
                setColorCambioLabel(color);
            }
        } else {
            label = "Error al guardar los datos del usuario";
            color = colors.red01;
            setLabelGuarUsuario(label);
            setColorCambioLabel(color);
        }
        Tiempo.sleep(5).then(() => {
            label = "";
            setLabelGuarUsuario(label);
        });
    }

    const cambiarPassword = async (): Promise<void> => {

        if (usuario.password.length > 0) {
            const response: Response = await UsuarioService.cambiarPassword(usuario);
            if (response.error === false) {
                let label: string = "La contraseña fue cambiada";
                let color: string = colors.greenSuccessful;
                setLabelCambioPassword(label);
                setColorCambioLabel(color);
                Tiempo.sleep(5).then(() => {
                    label = "";
                    setLabelCambioPassword("");
                    setColorCambioLabel(color);
                });
            } else {
                let label: string = "No se pudo cambiar la contraseña";
                setLabelCambioPassword(label);
                let color: string = colors.red01;
                Tiempo.sleep(5).then(() => {
                    label = "";
                    setLabelCambioPassword(label);
                    setColorCambioLabel(color);
                });
            }
        } else {
            let label: string = "No has especificado la contraseña";
            let color: string = colors.warning;
            setLabelCambioPassword(label);
            setColorCambioLabel(color);
            Tiempo.sleep(5).then(() => {
                label = "";
                setLabelCambioPassword(label);
                setColorCambioLabel(color);
            });
        }

    };

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            MI PERFIL
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: data.isDesktop ? "30%" : 10, marginLeft: data.isDesktop ? "30%" : 10 }}  >
                    <Col>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                        Nombre Completo
                                    </Label>
                                    <Input
                                        id="nombreCompleto"
                                        type="text"
                                        value={usuario.nombreCompleto}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            let name = "nombreCompleto";
                                            onChangeUsuario(name, value)
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") Components.focus("username");
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                        style={Styles.input}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                        Nombre de Usuario
                                    </Label>
                                    <Input
                                        id="username"
                                        type="text"
                                        value={usuario.username}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            let name = "username";
                                            onChangeUsuario(name, value)
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") Components.focus(usuario.IdUsuario === 0 ? "password" : "nombreEmpresa");
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                        style={Styles.input}
                                    />
                                </FormGroup>
                                <Label
                                    style={{ color: colors.blue, fontSize: 14, marginTop: 5, cursor: "pointer" }}
                                    onClick={() => { setIsOpenModalPassword(true) }}
                                >
                                    Cambiar contraseña
                                </Label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                        Nombre de Empresa Asociada
                                    </Label>
                                    <Input
                                        id="nombreEmpresa"
                                        type="text"
                                        value={usuario.nombreEmpresa}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            let name = "nombreEmpresa";
                                            onChangeUsuario(name, value)
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") Components.focus("password");
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                        style={Styles.input}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 0 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                        Imagen de la empresa asociada
                                    </Label>
                                    <Input
                                        type="file"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files) {
                                                let file = files[0];
                                                if (file) {
                                                    let size = Image.obtenerTamañoImagenMB(file)
                                                    if (size > 5) {
                                                        let label: string = "No se puede usar esta imagen ya que pesa mas de 5 MB";
                                                        let color: string = colors.warning;
                                                        setLabelGuarUsuario(label);
                                                        setColorCambioLabel(color);
                                                    } else {
                                                        let base64 = await Image.convertirImagenABase64(file);

                                                        setUsuario((prev) => ({
                                                            ...prev,
                                                            imgLogoEmpresa: base64
                                                        }));
                                                    }
                                                }


                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }} >
                            <Col>
                                {
                                    usuario.imgLogoEmpresa.length === 0 ?
                                        <i style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >No hay logo</i>
                                        :
                                        <img
                                            src={usuario.imgLogoEmpresa}
                                            alt="No se pudo cargar la imagen"
                                            height={130}
                                            style={Styles.input}
                                        />
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label style={{ marginTop: 10, color: colorCambioLabel, fontWeight: "bold" }} >
                                    {labelGuarUsuario}
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "right" }} >
                                <Button
                                    style={Styles.button}
                                    onClick={() => guardarUsuario()}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
            <Modal
                isOpen={isOpenModalPassword}
                onOpened={() => { Components.focus("password") }}
                style={{ fontFamily: "Montserrat" }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setIsOpenModalPassword(false);
                            }}
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Cambio de Contraseña
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginTop: 20 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                    Nueva Contraseña para {usuario.username}
                                </Label>
                                <Input
                                    id="password"
                                    type="text"
                                    value={usuario.password}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        let name = "password";
                                        onChangeUsuario(name, value);
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombreEmpresa");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                            <Label style={{ marginTop: 10, color: colorCambioLabel, fontWeight: "bold" }} >
                                {labelCambioPassword}
                            </Label>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            cambiarPassword();
                        }}
                    >
                        Cambiar Contraseña
                    </Button>
                </ModalFooter>
            </Modal>
        </main>
    );
}

export default MiPerfil;