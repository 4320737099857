import Documentation from "../interfaces/Documentacion.interface"
import EmpresaModel from "./Empresa.model"

const getEstructura = (): Documentation => {
    const documentacion: Documentation = {
        IdDocumentacion: 0,
        empresa: EmpresaModel.getEstructura(),
        actaConstitutivaOActaDeNacimiento: "",
        modificacionDeActa: "",
        modificacionDeActa_socio_01: "",
        modificacionDeActa_socio_02: "",
        modificacionDeActa_modificacionDeActa_01: "",
        modificacionDeActa_modificacionDeActa_02: "",
        rcp: "",
        rcp_socio_01: "",
        rcp_socio_02: "",
        rcp_modificacionDeActa_01: "",
        rcp_modificacionDeActa_02: "",
        comprobanteDeDomicilio: "",
        ineDelRepre: "",
        ineDeLosSocios: "",
        ineDeLosSocios_socio_01: "",
        ineDeLosSocios_socio_02: "",
        ineDeLosSocios_modificacionDeActa_01: "",
        ineDeLosSocios_modificacionDeActa_02: "",
        logotipo: "",
        curriculum: "",
        croquisFotos: "",
        tarjetaPatronal: "",
        tarjetaPatronal_socio_01: "",
        tarjetaPatronal_socio_02: "",
        tarjetaPatronal_modificacionDeActa_01: "",
        tarjetaPatronal_modificacionDeActa_02: "",
        cif: "",
        TresDosD: "",
        caratulaBancaria: "",
        padronDeProveedores: "",
        sinfra: "",
        repse: "",
        poder: "",
        poder_socio_01: "",
        poder_socio_02: "",
        poder_modificacionDeActa_01: "",
        poder_modificacionDeActa_02: "",
        hojaMembretrada: "",
        padronDeIne: "",
        compranet: ""
    }
    return documentacion;
}

const getSubArray = () : { label: string, index: string }[] =>{
    const arraySub : { label: string, index: string }[] = [        
        {
            label: "SOCIO 1",
            index: "socio_01"
        },
        {
            label: "SOCIO 2",
            index: "socio_02"
        },
        {
            label: "Modificación de Acta 1",
            index: "modificacionDeActa_01"
        },
        {
            label: "Modificación de Acta 2",
            index: "modificacionDeActa_02"
        },
        
    ];
    return arraySub;
}

const getArray = (): { label: string, index: string, modal: boolean }[] => {
    const array = [
        {
            label: "ACTA CONSTITUTIVA O ACTA DE NACIMIENTO",
            index: "actaConstitutivaOActaDeNacimiento",
            modal: false,
        },
        {
            label: "MODIFICACIÓN DE ACTA",
            index: "modificacionDeActa",
            modal: true,
        },
        {
            label: "RCP",
            index: "rcp",
            modal: true,
        },
        {
            label: "COMPROBANTE DE DOMICILIO",
            index: "comprobanteDeDomicilio",
            modal: false,
        },
        {
            label: "INE DEL REPRE",
            index: "ineDelRepre",
            modal: false,
        },
        {
            label: "INE DE LOS SOCIOS",
            index: "ineDeLosSocios",
            modal: true,
        },
        {
            label: "LOGOTIPO",
            index: "logotipo",
            modal: false,
        },
        {
            label: "CURRICULUM",
            index: "curriculum",
            modal: false,
        },
        {
            label: "CROQUIS FOTOS",
            index: "croquisFotos",
            modal: false,
        },
        {
            label: "TARJETA PATRONAL",
            index: "tarjetaPatronal",
            modal: true,
        },
        {
            label: "CIF",
            index: "cif",
            modal: false,
        },
        {
            label: "32D",
            index: "TresDosD",
            modal: false,
        },
        {
            label: "CARATULA BANCARIA",
            index: "caratulaBancaria",
            modal: false,
        },
        {
            label: "PADRÓN DE PROVEEDORES",
            index: "padronDeProveedores",
            modal: false,
        },
        {
            label: "SINFRA",
            index: "sinfra",
            modal: false,
        },
        {
            label: "REPSE",
            index: "repse",
            modal: false,
        },
        {
            label: "PODER",
            index: "poder",
            modal: true,
        },
        {
            label: "HOJA MEMBRETADA",
            index: "hojaMembretrada",
            modal: false,
        },
        
        {
            label: "PADRÓN DE INE ",
            index: "padronDeIne",
            modal: false,
        },
        {   
            label: "COMPRANET",
            index: "compranet",
            modal: false,
        },
    ]
    return array;
}

const DocumentacionModel = {
    getEstructura,
    getArray,
    getSubArray,
}



export default DocumentacionModel;