

const colors = {
    blue: "#063C6B",
    blue01: "#6485a0",
    white: "#FFFFFF",
    white01: "#F3F3F3",
    white02:"#F9F9F9",
    black: "#000000",
    grey: "#323232",
    warning: "#FFBA1F",
    red01: "#DD0612",
    greenSuccessful: "#22B451",
    pdf: {
        grey01: "#1D1D1D",
        grey02: "#323232",
        white01: "#F1F1F1",
    }
}

export default colors;  