import { FC, useEffect, useState, ChangeEvent } from "react";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Styles from "../../../assets/css/Styles.css";
import colors from "../../../config/Colors.config";
import { IconButton } from "@mui/material";
import { Add, Cached, Check, Close, Delete, Edit } from "@mui/icons-material";
import AsesorService from "../../../services/Asesor.service";
import UsuarioModel from "../../../models/Usuario.model";
import Asesor from "../../../interfaces/Asesor.interface";
import Usuario from "../../../interfaces/Usuario.interface";
import Response from "../../../interfaces/Response.interface";
import Table, { TableColumn } from 'react-data-table-component';
import AsesorModel from "../../../models/Asesor.model";
import Encabezado from "../../../interfaces/Encabezado.interface";
import Moneda from "../../../tools/Moneda.tool";
import Movimiento from "../../../interfaces/Movimiento.interface";
import MovimientoModel from "../../../models/Movimiento.model";
import EmpresaService from "../../../services/Empresa.service";
import Empresa from "../../../interfaces/Empresa.interface";
import Porcentaje from "../../../interfaces/Porcentaje.interface";
import PorcentajeService from "../../../services/Porcentaje.service";
import PorcentajeModel from "../../../models/Porcentaje.model";
import Banco from "../../../interfaces/Banco.interface";
import BancoService from "../../../services/Banco.service";
import BancoModel from "../../../models/Banco.model";
import CuentaBancaria from "../../../interfaces/CuentaBancaria.interface";
import CuentaBancariaService from "../../../services/CuentaBancaria.service";
import CuentaBancariaModel from "../../../models/CuentaBancaria.model";
import Number from "../../../tools/Number.tool";
import Components from "../../../tools/Components.tool";
import MovimientoService from "../../../services/Movimiento.service";
import Fecha from "../../../tools/Fecha.tool";
import Tiempo from "../../../tools/Tiempo.tool";
import Swal from "sweetalert2";
import Device from "../../../interfaces/Device.interface";
import DeviceConfig from "../../../config/Device.config";



const Ingresos: FC<{ vUsuario: Usuario }> = ({ vUsuario }) => {
    const [usuario, setUsuario] = useState<Usuario>(vUsuario);
    const [asesor, setAsesor] = useState<Asesor>(AsesorModel.getEstructura());
    const [RFCAsesor, setRFCAsesor] = useState<string>("0");
    const [movimiento, setMovimiento] = useState<Movimiento>(MovimientoModel.getEstructura());
    const [movimientosLista, setMovimientosLista] = useState<Movimiento[]>([]);
    const [bancosLista, setBancosLista] = useState<Banco[]>([]);
    const [banco, setBanco] = useState<Banco>(BancoModel.getEstructura());
    const [cuentasBancariasLista, setCuentasBancariasLista] = useState<CuentaBancaria[]>([])
    const [porcentajesLista, setPorcentajesLista] = useState<Porcentaje[]>([]);
    const [porcentaje, setPorcentaje] = useState<Porcentaje>(PorcentajeModel.getEstructura());
    const [isOpenModalAsesor, setIsOpenModalAsesor] = useState<boolean>(false);
    const [isOpenModalMovimiento, setIsOpenModalMovimiento] = useState<boolean>(false);
    const [encabezados, setEncabezados] = useState<Encabezado>({ importeTotal: 0, gastosOperacionTotal: 0, utilidadTotal: 0, utilidadNetoTotal: 0, saldoTotal: 0, retornadoTotal: 0, porRetornar: 0, retornoEnTransito: 0, gastosTotal: 0 });
    const [focusImporte, setFocusImporte] = useState<boolean>(false);
    const [labelGuardarMovimiento, setLabelGuardarMovimiento] = useState<string>("");
    const [colorMovimiento, setColorMovimiento] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());

    useEffect(() => {
        cargarUsuario();
        // listarMovimientos("0");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listarMovimientos = async (RFCAsesor: string): Promise<void> => {
        const response: Response = await MovimientoService.listarIngresos(RFCAsesor);
        const movimientosLista: Movimiento[] = response.data.value;
        const encabezados: Encabezado = response.data.other.encabezados;
        setMovimientosLista(movimientosLista);
        setEncabezados(encabezados)
    }

    const cargarUsuario = async () => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        setUsuario((prev: Usuario) => ({ ...prev, ...usuario }));
    }

    const listarEmpresaUsuarios = async (): Promise<void> => {
        const response: Response = await EmpresaService.empresasUsuario(usuario.IdUsuario);
        const empresasLista: Empresa[] = response.data.value;
        setUsuario((prev) => ({
            ...prev,
            empresas: empresasLista
        }));
    };

    const listarAsesoresUsuarios = async (): Promise<void> => {

        const response: Response = await AsesorService.asesoresUsuario(usuario.IdUsuario);
        const asesoresLista: Asesor[] = response.data.value;
        console.log(asesoresLista)

        setUsuario((prev: Usuario) => ({
            ...prev,
            asesores: [...asesoresLista, { IdAsesoresUsuario: 74, IdUsuario: 2, RFC: "0", materno: "", nombres: "TODOS", paterno: "" }]
            // asesores: asesoresLista
        }))
    }

    const listarPorcentajes = async (): Promise<void> => {
        const response: Response = await PorcentajeService.listar("");
        const porcentajeLista: Porcentaje[] = response.data.value;
        setPorcentajesLista(porcentajeLista);
    };

    const listarBancos = async (): Promise<void> => {
        const response: Response = await BancoService.listar("");
        const bancosLista: Banco[] = response.data.value;
        setBancosLista(bancosLista);
    };

    const listarCuentasBancarias = async (RFCEmpresa: string, IdBanco: number): Promise<void> => {
        const response: Response = await CuentaBancariaService.listar(RFCEmpresa, IdBanco);
        const cuentasBancariasLista: CuentaBancaria[] = response.data.value;
        setCuentasBancariasLista(cuentasBancariasLista);
    };

    const validarDatosMovimiento = (): boolean => {

        let value: boolean = true;
        let labelGuardarMovimiento: string = "";
        let colorMovimiento: string = "";
        let { empresa, asesor, porcentaje, banco, cuentaBancaria, IdFormaPago, importe } = movimiento;
        let RFCAsesor: string = asesor.RFC;
        let RFCEmpresa: string = empresa.RFC;
        let { IdPorcentaje } = porcentaje
        let { IdBanco } = banco;
        let { IdBancoCuenta } = cuentaBancaria;

        if (+importe === 0) {
            labelGuardarMovimiento = "No has seleccionado un importe";
            colorMovimiento = colors.warning;
            Components.focus("importe");
            value = false;
        }
        if (IdBancoCuenta === 0) {
            labelGuardarMovimiento = "No has seleccionado una cuenta bancaria";
            colorMovimiento = colors.warning;
            Components.focus("IdBancoCuenta");
            value = false;
        }
        if (IdBanco === 0) {
            labelGuardarMovimiento = "No has seleccionado un banco";
            colorMovimiento = colors.warning;
            Components.focus("IdBanco");
            value = false;
        }
        if (IdFormaPago === 0) {
            labelGuardarMovimiento = "No has seleccionado una forma de pago";
            colorMovimiento = colors.warning;
            Components.focus("IdFormaPago");
            value = false;
        }
        if (IdPorcentaje === 0) {
            labelGuardarMovimiento = "No has seleccionado un porcentaje";
            colorMovimiento = colors.warning;
            Components.focus("IdPorcentaje");
            value = false;
        }
        if (RFCAsesor.length === 0) {
            labelGuardarMovimiento = "No has seleccionado una persona";
            colorMovimiento = colors.warning;
            Components.focus("RFCPersona");
            value = false;
        }
        if (RFCEmpresa.length === 0) {
            labelGuardarMovimiento = "No has seleccionado una empresa";
            colorMovimiento = colors.warning;
            Components.focus("RFCEmpresa");
            value = false;
        }

        setLabelGuardarMovimiento(labelGuardarMovimiento);
        setColorMovimiento(colorMovimiento);

        return value;
    }

    const guardarMovimiento = async (): Promise<void> => {
        const value: boolean = validarDatosMovimiento();
        if (value) {
            const response: Response = await MovimientoService.guardar(movimiento);
            if (response.error === false) {
                listarMovimientos(RFCAsesor);
                setLabelGuardarMovimiento("Movimiento Guardado");
                setColorMovimiento(colors.greenSuccessful);
                Tiempo.sleep(5).then(() => setLabelGuardarMovimiento(""));
                setMovimiento({ ...MovimientoModel.getEstructura(), IdTipo: 1 });
                Components.focus("fechaMovimiento");
            }
        }
    }

    const calcularDatosMovimientos = (importe: number, porcentaje: Porcentaje) => {
        let utilidad: number = 0;
        let gastosOperacion: number = 0;
        let retornar: number = 0;
        if (porcentaje.tipoCalculo.IdCalculoTipo === 1) {
            utilidad = (porcentaje.porcentajeUtilidad * importe) / 100;
            gastosOperacion = (porcentaje.porcentajeGastosOperacion * importe) / 100;
            retornar = importe - (utilidad + gastosOperacion)
        } else { /* Calculo sobre el sub total*/
            let valueTemp: number = importe / 1.16;
            utilidad = (porcentaje.porcentajeUtilidad * valueTemp) / 100;
            utilidad = parseFloat(utilidad.toFixed(2));
            gastosOperacion = (porcentaje.porcentajeGastosOperacion * valueTemp) / 100;
            gastosOperacion = parseFloat(gastosOperacion.toFixed(2));
            retornar = importe - (utilidad + gastosOperacion)
        }
        setMovimiento((prev: Movimiento) => ({ ...prev, utilidad, gastosOperacion, retornar }));
    }

    const columnsMovimientos: TableColumn<Movimiento>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FECHA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Fecha.formatDate(movimiento.fechaMovimiento)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FORMA DE PAGO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{MovimientoModel.selectFormaPago(movimiento.IdFormaPago)}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > TIPO DE MOVIMIENTO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }}>{MovimientoModel.selectTipoMovimiento(movimiento.IdTipo)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > ASESOR </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{`${movimiento.asesor.nombres} ${movimiento.asesor.paterno} ${movimiento.asesor.materno} `}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FOLIO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.referencia}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > IMPORTE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Moneda.formatCurrency(movimiento.importe)}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > PORCENTAJE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} title={`Nombre: ${movimiento.porcentaje.nombrePorcentaje}, Utilidad: ${movimiento.porcentaje.porcentajeUtilidad}%, Gastos de Operacion: ${movimiento.porcentaje.porcentajeGastosOperacion}%`} >{movimiento.porcentaje.porcentajeTotal}%</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > UTILIDAD </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.greenSuccessful, fontSize: 15, whiteSpace: 'normal' }} >{Moneda.formatCurrency(movimiento.utilidad)}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > GASTOS DE OPERACIÓN </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.red01, fontSize: 15, whiteSpace: 'normal' }} >{Moneda.formatCurrency(movimiento.gastosOperacion)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > POR RETORNAR </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.blue, fontSize: 15, whiteSpace: 'normal' }} > {Moneda.formatCurrency(movimiento.retornar)} </Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} ></Label>,
            cell: (movimiento: Movimiento) =>
                <>
                    <IconButton
                        style={{ color: colors.red01 }}
                        onClick={() => {
                            Swal.fire({
                                icon: "question",
                                title: "¿Eliminar movimiento?",
                                text: `¿Estas seguro de eliminar este movimiento de ingreso de ${Moneda.formatCurrency(movimiento.importe)}?`,
                                showCancelButton: true,
                                cancelButtonText: "No, Eliminar",
                                confirmButtonText: "Si, Eliminar",
                                confirmButtonColor: colors.blue,
                                reverseButtons: true
                            }).then(async (response) => {
                                if (response.isConfirmed) {
                                    await MovimientoService.eliminar(movimiento.IdMovimiento);
                                    listarMovimientos(RFCAsesor);
                                }
                            });
                        }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton
                        style={{ color: colors.blue }}
                        onClick={() => {
                            setMovimiento({ ...movimiento, fechaMovimiento: movimiento.fechaMovimiento.substring(0, 10) });
                            setIsOpenModalMovimiento(true);
                            listarCuentasBancarias(movimiento.empresa.RFC, movimiento.banco.IdBanco)
                            setBanco(movimiento.banco);
                            setPorcentaje(movimiento.porcentaje);
                        }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ]

    const columnsAsesoresAsignados: TableColumn<Asesor>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRES </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.nombres}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > APELLIDO PATERNO </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.paterno}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > APELLIDO PATERNO </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.materno}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > APELLIDO MATERNO </Label>,
            cell: (asesor: Asesor) =>
                <IconButton
                    style={{ color: colors.blue }}
                    onClick={async () => {
                        setAsesor(asesor);
                        setIsOpenModalAsesor(false);
                        listarMovimientos(asesor.RFC);
                        setRFCAsesor(asesor.RFC);
                    }}
                >
                    <Check />
                </IconButton>,
        },
    ];

    return (
        <>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                <Col style={{ minWidth: "30%", width: "30%", maxWidth: "30%" }} >
                    <FormGroup>
                        <Label style={{ color: colors.black, fontSize: 18 }} >
                            Asesor 
                        </Label>
                        <InputGroup>
                            <Input
                                style={{ ...Styles.input, /*width: "100%",*/ marginTop: -10 }}
                                type="text"
                                value={`${asesor.nombres} ${asesor.paterno} ${asesor.materno}`}
                                onClick={() => { setIsOpenModalAsesor(true) }}
                            />
                            {
                                RFCAsesor !== "0" &&
                                <InputGroupText style={{ ...Styles.input }} onClick={() => {
                                    setAsesor(AsesorModel.getEstructura());
                                    setRFCAsesor("0");
                                    listarMovimientos("0");
                                }} >
                                    <Close style={{ color: colors.blue, cursor: "pointer" }} />
                                </InputGroupText>
                            }
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col style={{ minWidth: "70%", width: "70%", maxWidth: "70%", textAlign: "right" }} >
                    <IconButton
                        style={{ backgroundColor: colors.blue01, color: colors.white, marginRight: 15 }}
                        onClick={() => listarMovimientos(RFCAsesor)}
                    >
                        <Cached />
                    </IconButton>
                    <IconButton
                        style={{ backgroundColor: colors.blue01, color: colors.white }}
                        onClick={() => setIsOpenModalMovimiento(true)}
                    >
                        <Add />
                    </IconButton>
                </Col>
            </Row >
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                    <Label style={{ fontSize: 14 }} >TOTAL DE IMPORTE</Label>
                    <br />
                    <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.importeTotal)}</Label>
                </Col>
                <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                    <Label style={{ fontSize: 14 }} >TOTAL DE UTILIDAD</Label>
                    <br />
                    <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.utilidadTotal)}</Label>
                </Col>
                <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                    <Label style={{ fontSize: 14 }} >TOTAL DE GASTOS DE OPERACIÓN</Label>
                    <br />
                    <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.gastosOperacionTotal)}</Label>
                </Col>
                {/* <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                    <Label style={{ fontSize: 14 }} >TOTAL DE UTILIDAD NETO</Label>
                    <br />
                    <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.utilidadNetoTotal)}</Label>
                </Col> */}
                {/* <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                    <Label style={{ fontSize: 14 }} >TOTAL DE SALDO</Label>
                    <br />
                    <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.saldoTotal)}</Label>
                </Col> */}
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                    <Label style={{ fontSize: 14 }} >RETORNADO</Label>
                    <br />
                    <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.retornadoTotal)}</Label>
                </Col>
                <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                    <Label style={{ fontSize: 14 }} >POR RETORNAR</Label>
                    <br />
                    <Label style={{ fontSize: 14 }} >{Moneda.formatCurrency(encabezados.porRetornar)}</Label>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col>
                    <i>Pulse la tecla SHIFT y haga scroll con la rueda del ratón sobre la tabla para visualizar todas sus columnas.</i>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Table
                    columns={columnsMovimientos}
                    data={movimientosLista}
                    noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 30 }} >No hay movimientos registrados</Label>}
                />
            </Row>

            <Modal
                isOpen={isOpenModalMovimiento}
                style={{ fontFamily: "Montserrat", maxWidth: data.isMovil ? "" : "80%" }}
                size="lg"
                onOpened={() => { listarEmpresaUsuarios(); listarAsesoresUsuarios(); listarPorcentajes(); listarBancos(); setMovimiento((prev: Movimiento) => ({ ...prev, IdTipo: 1 })); }}
                onClosed={() => { setMovimiento(MovimientoModel.getEstructura()); setLabelGuardarMovimiento(""); }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalMovimiento(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Agregar Movimiento de Ingreso
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: data.isDesktop ? "70vh" : "", height: data.isDesktop ? "70vh" : "", maxHeight: data.isDesktop ? "70vh" : "", overflowY: "auto" }} >
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Fecha
                                </Label>
                                <Input
                                    id="fechaMovimiento"
                                    name="fechaMovimiento"
                                    type="date"
                                    value={movimiento.fechaMovimiento}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, [name]: value }));
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Empresa
                                </Label>
                                <Input
                                    id="RFCEmpresa"
                                    type="select"
                                    value={movimiento.empresa.RFC}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, empresa: { ...prev.empresa, RFC: value } }));
                                        listarCuentasBancarias(value, movimiento.banco.IdBanco);
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Seleccione una empresa</option>
                                    {
                                        usuario.empresas.map((empresa: Empresa) => {
                                            return (
                                                <option value={empresa.RFC} title={empresa.giroEmpresarial} >{empresa.nombreEmpresa}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Persona
                                </Label>
                                <Input
                                    id="RFCPersona"
                                    type="select"
                                    value={movimiento.asesor.RFC}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, asesor: { ...prev.asesor, RFC: value } }));
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Seleccione una persona</option>
                                    {
                                        usuario.asesores.map((asesor: Asesor) => {
                                            return (
                                                <option value={asesor.RFC}  >{asesor.nombres} {asesor.paterno} {asesor.materno} </option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Porcentaje
                                </Label>
                                <Input
                                    id="IdPorcentaje"
                                    type="select"
                                    value={movimiento.porcentaje.IdPorcentaje}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, porcentaje: { ...prev.porcentaje, IdPorcentaje: +value } }));
                                        const porcentaje: Porcentaje | undefined = porcentajesLista.find(porcentaje => porcentaje.IdPorcentaje === +value);
                                        if (porcentaje !== undefined) {
                                            setPorcentaje(porcentaje);
                                            calcularDatosMovimientos(+movimiento.importe, porcentaje);
                                        } else {
                                            setPorcentaje(PorcentajeModel.getEstructura());
                                        }
                                    }}
                                    style={Styles.input}
                                >
                                    <option value={0}>Seleccione un porcentaje</option>
                                    {
                                        porcentajesLista.map((porcentaje: Porcentaje) => {
                                            return (
                                                <option value={porcentaje.IdPorcentaje} title={`Utilidad ${porcentaje.porcentajeUtilidad}%, Gastos Operacion: ${porcentaje.porcentajeGastosOperacion}%`}  >{porcentaje.nombrePorcentaje} </option>
                                            )
                                        })
                                    }
                                </Input>
                                {
                                    porcentaje.IdPorcentaje > 0 &&
                                    <Label style={{ color: colors.blue }} >
                                        Tipo de calculo: {porcentaje.tipoCalculo.calculoTipo}
                                    </Label>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Forma de Pago
                                </Label>
                                <Input
                                    id="IdFormaPago"
                                    type="select"
                                    value={movimiento.IdFormaPago}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMovimiento((prev: Movimiento) => ({ ...prev, IdFormaPago: +value }));
                                    }}
                                    style={Styles.input}
                                >
                                    <option value={0}>Seleccione una forma de pago</option>
                                    <option value={1}>Efectivo</option>
                                    <option value={2}>Trasferencia</option>
                                    <option value={3}>Cheque</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Banco
                                </Label>
                                <Row>
                                    <Col style={{ minWidth: "85%", width: "85%", maxWidth: "85%" }} >
                                        <Input
                                            id="IdBanco"
                                            type="select"
                                            value={movimiento.banco.IdBanco}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setMovimiento((prev: Movimiento) => ({ ...prev, banco: { ...prev.banco, IdBanco: +value }, cuentaBancaria: { ...prev.cuentaBancaria, IdBancoCuenta: 0 } }));
                                                const banco: Banco | undefined = bancosLista.find(banco => banco.IdBanco === +value);
                                                if (banco !== undefined) setBanco(banco); else setBanco(BancoModel.getEstructura());
                                                listarCuentasBancarias(movimiento.empresa.RFC, +value)
                                            }}
                                            style={{ ...Styles.input, width: "100%" }}
                                        >
                                            <option value={0}>Seleccione un banco</option>
                                            {
                                                bancosLista.map((banco: Banco) => {
                                                    return (
                                                        <option value={banco.IdBanco}  >{banco.nombreBanco} </option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </Col>
                                    <Col style={{ minWidth: "15%", width: "15%", maxWidth: "15%", textAlign: "center" }} >
                                        {
                                            banco.IdBanco > 0 ?
                                                <img style={{ height: 35, borderRadius: 12 }} src={banco.imgBanco} alt="No se pudo cargar la imagen" />
                                                :
                                                <i>Logo</i>
                                        }

                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ /*minWidth: "85%", width: "85%", maxWidth: "85%"*/ }} >
                            <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                Cuentas
                            </Label>
                            <Input
                                id="IdBancoCuenta"
                                type="select"
                                value={movimiento.cuentaBancaria.IdBancoCuenta}
                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                    setMovimiento((prev: Movimiento) => ({ ...prev, cuentaBancaria: { ...prev.cuentaBancaria, IdBancoCuenta: +value } }))
                                }}
                                style={{ ...Styles.input, width: "100%" }}
                            >
                                <option value={0}>Seleccione una cuenta</option>
                                {
                                    cuentasBancariasLista.map((cuenta: CuentaBancaria) => {
                                        return (
                                            <option value={cuenta.IdBancoCuenta} title={cuenta.descripcion} >{CuentaBancariaModel.cast(cuenta.numeroCuenta)} </option>
                                        )
                                    })
                                }
                            </Input>
                            {
                                movimiento.empresa.RFC.length === 0 && movimiento.banco.IdBanco === 0 ?
                                    <Label style={{ color: colors.blue }} >Seleccione un empresa y un banco para ver sus cuentas </Label>
                                    :
                                    movimiento.empresa.RFC.length === 0 ?
                                        <Label style={{ color: colors.blue }} >Seleccione un empresa para ver sus cuentas </Label>
                                        :
                                        movimiento.banco.IdBanco === 0 ?
                                            <Label style={{ color: colors.blue }} >Seleccione un banco para ver sus cuentas </Label>
                                            :
                                            cuentasBancariasLista.length === 0 ?
                                                <Label style={{ color: colors.blue }} >No hay cuentas asignadas </Label>
                                                :
                                                <Label style={{ color: colors.blue }} >Se encontraron {cuentasBancariasLista.length} cuentas registradas</Label>

                            }

                        </Col>

                        <Col>
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                        Folio
                                    </Label>
                                    <Input
                                        id="referencia"
                                        type="text"
                                        value={movimiento.referencia}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setMovimiento((prev: Movimiento) => ({ ...prev, referencia: value }));
                                        }}
                                        style={Styles.input}
                                    />
                                </FormGroup>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Importe
                                </Label>
                                <Input
                                    id="importe"
                                    type="text"
                                    value={focusImporte ? movimiento.importe : Moneda.formatCurrency(movimiento.importe)}
                                    onFocus={() => {
                                        setFocusImporte(true);
                                    }}
                                    onBlur={() => {
                                        setFocusImporte(false);
                                    }}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        const importe: string = Number.singleNumbers(value);
                                        setMovimiento((prev: Movimiento) => ({ ...prev, importe: importe }));
                                        calcularDatosMovimientos(+importe, porcentaje);
                                    }}
                                    style={Styles.input}
                                />
                                <Row>
                                    <Col>
                                        {
                                            porcentaje.IdPorcentaje > 0 &&
                                            <Label style={{ color: colors.blue }} >
                                                Utilidad: {porcentaje.porcentajeUtilidad}%, Gastos de Operacion: {porcentaje.porcentajeGastosOperacion}%, Total: {porcentaje.porcentajeTotal}%
                                            </Label>
                                        }
                                    </Col>
                                    <Col style={{ textAlign: "right" }} >
                                        {
                                            porcentaje.IdPorcentaje > 0 &&
                                            <Label style={{ color: colors.blue }} >
                                                Utilidad: {Moneda.formatCurrency(movimiento.utilidad)}, Gastos de Operacion: {Moneda.formatCurrency(movimiento.gastosOperacion)}, Por Retornar: {Moneda.formatCurrency(movimiento.retornar)}
                                            </Label>
                                        }
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Observaciones
                                </Label>
                                <Input
                                    id="observaciones"
                                    type="textarea"
                                    value={movimiento.observaciones}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 100) return
                                        setMovimiento((prev: Movimiento) => ({ ...prev, observaciones: value }));
                                    }}
                                    style={{
                                        height: 100,
                                        minHeight: 100,
                                        maxHeight: 100,
                                        borderColor: colors.blue,
                                        boxShadow: "none"
                                    }}
                                />
                                <Label>
                                    {movimiento.observaciones.length} / 100
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Row style={{ width: "100%" }} >
                        <Col style={{ textAlign: "left" }} >
                            {
                                labelGuardarMovimiento.length > 0 &&
                                <Label style={{ color: colorMovimiento, fontSize: 20, fontWeight: "bold", fontFamily: "Montserrat" }} >
                                    {labelGuardarMovimiento}
                                </Label>
                            }
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Button
                                style={Styles.button}
                                onClick={() => guardarMovimiento()}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal >


            <Modal
                isOpen={isOpenModalAsesor}
                style={{ fontFamily: "Montserrat", maxWidth: "60%" }}
                size="lg"
                onOpened={() => { listarAsesoresUsuarios(); }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalAsesor(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Seleccione al asesor
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Table
                            columns={columnsAsesoresAsignados}
                            data={usuario.asesores}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >No hay asesores asignados</Label>}
                        />
                    </Row>
                </ModalBody>
                {/* <ModalFooter>

                </ModalFooter> */}
            </Modal>
        </>
    )
}

export default Ingresos;