import { FC, useState, SyntheticEvent } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Col, Label, Row } from "reactstrap";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import { Tabs } from "@mui/material";
import Ingresos from "./movimientos/Ingresos.movimiento";
import Retornos from "./movimientos/Retornos.movimiento";
import Gastos from "./movimientos/Gastos.movimiento";
import Usuario from "../../interfaces/Usuario.interface";
import UsuarioModel from "../../models/Usuario.model";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const IngresosEgresos: FC = () => {

    const [value, setValue] = useState<string>('1');
    const usuario: Usuario = UsuarioModel.getEstructura();
    const [data] = useState<Device>(DeviceConfig.data());


    const MyTabs = styled(Tabs)(() => ({
        '.MuiTabs-indicator': {
            backgroundColor: colors.blue,
            color: colors.blue
        },
    }));

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            INGRESOS Y EGRESOS
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Box sx={{ typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <MyTabs value={value} onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="INGRESOS" value="1" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="RETORNOS" value="2" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="GASTOS" value="3" />
                                </MyTabs>
                            </Box>
                            <TabPanel value="1" style={{ padding: 0 }} >
                                <Ingresos vUsuario={usuario} />
                            </TabPanel>
                            <TabPanel value="2" style={{ padding: 0 }}>
                                <Retornos vUsuario={usuario} />
                            </TabPanel>
                            <TabPanel value="3" style={{ padding: 0 }}>
                                <Gastos vUsuario={usuario} />
                            </TabPanel>
                        </TabContext>

                    </Box>
                </Row>
            </section>
        </main>
    )
}

export default IngresosEgresos;