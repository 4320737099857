const formatCurrency = (amount: number | string| undefined): string => {
    if (amount === undefined) return "$ Nan #*";
    const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
    return numAmount.toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });
};

const Moneda = {
    formatCurrency
}

export default Moneda;