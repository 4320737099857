const convertirImagenABase64 = (imagen: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject(new Error('No se pudo leer la imagen'));
            }
        };

        reader.onerror = () => {
            reject(new Error('Error al leer la imagen'));
        };

        reader.readAsDataURL(imagen);
    });
};

const obtenerTamañoImagenMB = (imagen: File): number => {
    if(imagen === undefined) return 0;
    return imagen.size / (1024 * 1024);
};

const Image = {
    convertirImagenABase64,
    obtenerTamañoImagenMB
}

export default Image;