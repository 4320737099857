import { FC, useEffect, useState, ChangeEvent } from "react";
import Usuario from "../../interfaces/Usuario.interface";
import Fecha from "../../tools/Fecha.tool";
import UsuarioModel from "../../models/Usuario.model";
import Movimiento from "../../interfaces/Movimiento.interface";
import MovimientoService from "../../services/Movimiento.service";
import Response from "../../interfaces/Response.interface";
import AsesorService from "../../services/Asesor.service";
import Asesor from "../../interfaces/Asesor.interface";
import EmpresaService from "../../services/Empresa.service";
import Empresa from "../../interfaces/Empresa.interface";
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import colors from "../../config/Colors.config";
import Styles from "../../assets/css/Styles.css";
import Block from "../../components/Block.component";
import { IconButton } from "@mui/material";
import Moneda from "../../tools/Moneda.tool";
import Table, { TableColumn } from 'react-data-table-component';
import { Cached, Close, Download } from "@mui/icons-material";
import { PDFViewer } from "@react-pdf/renderer";
import PDFResumenAsesores from "../../components/pdf/PDFResumenAsesores.pdf";
import AsesorModel from "../../models/Asesor.model";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

interface ReferenciaMovimiento { RFCEmpresa: string, RFCAsesor: string };

const ResumenAsesor: FC<{ vUsuario: Usuario }> = ({ vUsuario }) => {

    const [usuario, setUsuario] = useState<Usuario>(vUsuario);
    const [asesor, setAsesor] = useState<Asesor | Asesor[]>([]);
    const [referenciaMovimientos, setReferenciaMovimientos] = useState<ReferenciaMovimiento>({ RFCAsesor: "", RFCEmpresa: "" });
    const [movimientosLista, setMovimientosLista] = useState<Movimiento[]>([]);
    const [isOpenModalPDF, setIsOpenModalPDF] = useState<boolean>(false);
    const [data] = useState<Device>(DeviceConfig.data());

    useEffect(() => {
        cargarUsuario();
        listarMovimientos(referenciaMovimientos.RFCAsesor, referenciaMovimientos.RFCEmpresa);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const listarMovimientos = async (fecha: string, RFCEmpresa: string): Promise<void> => {
        const response: Response = await MovimientoService.resumenAsesores(fecha, RFCEmpresa);
        const movimientosLista: Movimiento[] = response.data.value;
        for (let i = 0; i < movimientosLista.length; i++) {
            if (movimientosLista[i].IdTipo === 1) {
                const response: Response = await MovimientoService.saldoPendiente(movimientosLista[i].IdMovimiento);
                if (response.error === false) {
                    movimientosLista[i].retornado = +response.data.value
                    movimientosLista[i].saldoPendiente = movimientosLista[i].retornar - +response.data.value
                } else {
                    movimientosLista[i].saldoPendiente = -1
                }
            }
        }
        setMovimientosLista(movimientosLista);
    }
    const listarEmpresaUsuarios = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        const response: Response = await EmpresaService.empresasUsuario(usuario.IdUsuario);
        const empresasLista: Empresa[] = response.data.value;
        setUsuario((prev) => ({
            ...prev,
            empresas: empresasLista
        }));
    };
    const listarAsesoresUsuarios = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        const response: Response = await AsesorService.asesoresUsuario(usuario.IdUsuario);
        const asesoresLista: Asesor[] = response.data.value;
        setUsuario((prev: Usuario) => ({
            ...prev,
            asesores: asesoresLista
        }))
    }
    const cargarUsuario = async () => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        setUsuario((prev: Usuario) => ({ ...prev, ...usuario }));
        listarEmpresaUsuarios();
        listarAsesoresUsuarios();
    }
    const columnsMovimientos: TableColumn<Movimiento>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FECHA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Fecha.formatDate(movimiento.fechaMovimiento)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > NOMBRE ASESOR </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{`${movimiento.asesor.nombres} ${movimiento.asesor.paterno} ${movimiento.asesor.materno} `}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > INGRESO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }}  >{Moneda.formatCurrency(movimiento.importe)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > RETORNO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }}  >{Moneda.formatCurrency(movimiento.retornar)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > PORCENTAJE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }}  >{movimiento.porcentaje.porcentajeTotal} %</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > UTILIDAD </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.greenSuccessful, fontSize: 15, whiteSpace: 'normal' }}  >{Moneda.formatCurrency(movimiento.utilidad)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > GASTOS DE OPERACIÓN </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.red01, fontSize: 15, whiteSpace: 'normal' }}  >{Moneda.formatCurrency(movimiento.gastosOperacion)}</Label>,
        },

        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > RETORNADO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.blue, fontSize: 15, whiteSpace: 'normal' }}  >{Moneda.formatCurrency(movimiento.retornado)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > SALDO PENDIENTE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.blue, fontSize: 15, whiteSpace: 'normal' }}  >{Moneda.formatCurrency(movimiento.saldoPendiente)}</Label>,
        },
    ]

    return (
        <>
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                    <Block height={"5vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                RESUMEN DE MOVIMIENTOS POR ASESOR
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.blue01, color: colors.white, marginRight: 15, height: 38, width: 38, borderRadius: 50 }}
                                onClick={() => {
                                    if (referenciaMovimientos.RFCAsesor.length === 0) {
                                        setAsesor(usuario.asesores);
                                    } else {
                                        const asesor: Asesor | undefined = usuario.asesores.find(asesor => asesor.RFC === referenciaMovimientos.RFCAsesor);
                                        if (asesor !== undefined) { setAsesor(asesor); } else { setAsesor(AsesorModel.getEstructura()); }
                                    }
                                    setIsOpenModalPDF(true);
                                }}
                            >
                                <Download />
                            </IconButton>
                            <IconButton
                                style={{ backgroundColor: colors.blue01, color: colors.white, marginRight: 15, height: 38, width: 38, borderRadius: 50 }}
                                onClick={() => listarMovimientos(referenciaMovimientos.RFCAsesor, referenciaMovimientos.RFCEmpresa)}
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Asesor
                                </Label>
                                <Input
                                    id="RFCPersona"
                                    type="select"
                                    value={referenciaMovimientos.RFCAsesor}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaMovimientos((prev: ReferenciaMovimiento) => ({ ...prev, RFCAsesor: value }));
                                        listarMovimientos(value, referenciaMovimientos.RFCEmpresa);
                                        const asesor: Asesor | undefined = usuario.asesores.find(asesor => asesor.RFC === value);
                                        if (asesor !== undefined) { setAsesor(asesor); } else { setAsesor(AsesorModel.getEstructura()); }

                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Todos los asesores</option>
                                    <option value="000">NO APLICA</option>
                                    {
                                        usuario.asesores.map((asesor: Asesor) => {
                                            return (
                                                <option value={asesor.RFC}  >{asesor.nombres} {asesor.paterno} {asesor.materno} </option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Empresa
                                </Label>
                                <Input
                                    id="RFCEmpresa"
                                    type="select"
                                    value={referenciaMovimientos.RFCEmpresa}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaMovimientos((prev: ReferenciaMovimiento) => ({ ...prev, RFCEmpresa: value }));
                                        listarMovimientos(referenciaMovimientos.RFCAsesor, value)
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Todas las empresas</option>
                                    <option value="000">NO APLICA</option>
                                    {
                                        usuario?.empresas.map((empresa: Empresa) => {
                                            return (
                                                <option value={empresa.RFC} title={empresa.giroEmpresarial} >{empresa.nombreEmpresa}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col>
                            {
                                data.isDesktop ?
                                    <i>Pulse la tecla SHIFT y haga scroll con la rueda del ratón sobre la tabla para visualizar todas sus columnas.</i>
                                    :
                                    <i>Deslice el dedo a la derecha y a la izquierda de la tabla para ver mas información</i>
                            }

                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Table
                            columns={columnsMovimientos}
                            data={movimientosLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 30 }} >No hay movimientos registrados</Label>}
                        />
                    </Row>
                    <Block height={"5vh"} />
                </section>
            </main>
            <Modal
                isOpen={isOpenModalPDF}
                fullscreen
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.pdf.grey02, color: colors.pdf.white01, height: 38, width: 38, borderRadius: 50 }}
                            onClick={() => { setIsOpenModalPDF(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.pdf.grey01 }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >

                    </Label>
                </ModalHeader>
                <ModalBody style={{ padding: 0, height: "100vh" }} >
                    <PDFViewer
                        style={{
                            width: "100%",
                            height: "90vh"
                        }}

                    >
                        <PDFResumenAsesores asesor={asesor} movimientosLista={movimientosLista} usuario={usuario} />
                    </PDFViewer>
                </ModalBody>
            </Modal>
        </>
    );
}

export default ResumenAsesor;