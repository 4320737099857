import ApiConfig from "../config/Api.config";
import CuentaBancaria from "../interfaces/CuentaBancaria.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/bancos/cuentas`

const guardar = async (cuentaBancaria: CuentaBancaria): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { cuentaBancaria }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (RFCEmpresa: string, IdBanco: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { RFCEmpresa, IdBanco }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdBancoCuenta: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        metodo: "DELETE",
        data: { IdBancoCuenta }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
}

const CuentaBancariaService = {
    guardar,
    listar,
    eliminar,
}

export default CuentaBancariaService;