import { FC, useState, ChangeEvent, useEffect } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Button, Col, DropdownItem, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Styles from "../../assets/css/Styles.css";
import Fecha from "../../tools/Fecha.tool";
import Response from "../../interfaces/Response.interface";
import EmpresaService from "../../services/Empresa.service";
import AsesorService from "../../services/Asesor.service";
import Usuario from "../../interfaces/Usuario.interface";
import UsuarioModel from "../../models/Usuario.model";
import Asesor from "../../interfaces/Asesor.interface";
import Empresa from "../../interfaces/Empresa.interface";
import Banco from "../../interfaces/Banco.interface";
import BancoService from "../../services/Banco.service";
import Moneda from "../../tools/Moneda.tool";
import Movimiento from "../../interfaces/Movimiento.interface";
import MovimientoService from "../../services/Movimiento.service";
import Table, { TableColumn } from 'react-data-table-component';
import { IconButton } from "@mui/material";
import { Cached, Close, Edit } from "@mui/icons-material";
import MovimientoModel from "../../models/Movimiento.model";
import CuentaBancariaModel from "../../models/CuentaBancaria.model";
import Tiempo from "../../tools/Tiempo.tool";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

interface ReferenciaCheque { fecha: string, RFCEmpresa: string, RFCAsesor: string, IdBanco: number, IdChequeStatus: number }
interface Encabezado { importeTransitoTotal: number, importeCobradoTotal: number, importeCanceladoTotal: number, importeTotal: number, totalTransito: number, totalCobrado: number, totalCancelado: number, totalCheques: number }

const Cheques: FC<{ vUsuario: Usuario }> = ({ vUsuario }) => {

    const [usuario, setUsuario] = useState<Usuario>(vUsuario);
    const [bancosLista, setBancosLista] = useState<Banco[]>([]);
    const [movimiento, setMovimiento] = useState<Movimiento>(MovimientoModel.getEstructura());
    const [movimientosLista, setMovimientosLista] = useState<Movimiento[]>([]);
    const [referenciaCheque, setReferenciaCheque] = useState<ReferenciaCheque>({ fecha: Fecha.getDateToDay(), RFCEmpresa: "", RFCAsesor: "", IdBanco: 0, IdChequeStatus: -1 });
    const [encabezados, setEncabezado] = useState<Encabezado>({ importeTransitoTotal: 0, importeCobradoTotal: 0, importeCanceladoTotal: 0, importeTotal: 0, totalTransito: 0, totalCobrado: 0, totalCancelado: 0, totalCheques: 0 });
    const [isOpenModalCheque, setIsOpenModalCheque] = useState<boolean>(false);
    const [labelGuardarCheque, setLabelGuardarCheque] = useState<string>("");
    const [colorGuardarCheque, setColorGuardarCheque] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());

    useEffect(() => {
        cargarUsuario();
        listarBancos();
        listarMovimientos(referenciaCheque.fecha, referenciaCheque.RFCEmpresa, referenciaCheque.RFCAsesor, referenciaCheque.IdBanco, referenciaCheque.IdChequeStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listarMovimientos = async (fecha: string, RFCEmpresa: string, RFCAsesor: string, IdBanco: number, IdChequeStatus: number): Promise<void> => {
        const response: Response = await MovimientoService.listarCheques(fecha, RFCEmpresa, RFCAsesor, IdBanco, IdChequeStatus);
        const movimientosLista: Movimiento[] = response.data.value;
        const encabezados: Encabezado = response.data.other.encabezados;
        setMovimientosLista(movimientosLista);
        setEncabezado(encabezados)
    }

    const listarEmpresaUsuarios = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        const response: Response = await EmpresaService.empresasUsuario(usuario.IdUsuario);
        const empresasLista: Empresa[] = response.data.value;
        setUsuario((prev) => ({
            ...prev,
            empresas: empresasLista
        }));
    };


    const listarAsesoresUsuarios = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        const response: Response = await AsesorService.asesoresUsuario(usuario.IdUsuario);
        const asesoresLista: Asesor[] = response.data.value;
        setUsuario((prev: Usuario) => ({
            ...prev,
            asesores: asesoresLista
        }))
    }

    const listarBancos = async (): Promise<void> => {
        const response: Response = await BancoService.listar("");
        const bancosLista: Banco[] = response.data.value;
        setBancosLista(bancosLista);
    };

    const cargarUsuario = async (): Promise<void> => {
        const usuario: Usuario = await UsuarioModel.getUsuario();
        setUsuario((prev: Usuario) => ({ ...prev, ...usuario }));
        listarEmpresaUsuarios();
        listarAsesoresUsuarios();
    }

    const cambiarStatusCheque = async (): Promise<void> => {
        const response: Response = await MovimientoService.cambiarStatusCheque(movimiento.IdMovimiento, movimiento.IdChequeStatus);
        let labelGuardarCheque: string = "";
        let colorGuardarCheque: string = "";
        if (response.error === false) {
            listarMovimientos(referenciaCheque.fecha, referenciaCheque.RFCEmpresa, referenciaCheque.RFCAsesor, referenciaCheque.IdBanco, referenciaCheque.IdChequeStatus);
            labelGuardarCheque = `Estatus del cheque cambiado a ${MovimientoModel.selectStatusCheque(movimiento.IdChequeStatus)}`
            colorGuardarCheque = colors.greenSuccessful;
        } else {
            labelGuardarCheque = `NO se pudo cambiar el estatus del cheque`
            colorGuardarCheque = colors.red01;
        }
        setLabelGuardarCheque(labelGuardarCheque);
        setColorGuardarCheque(colorGuardarCheque);
        Tiempo.sleep(5).then(() => {
            setLabelGuardarCheque("");
            setColorGuardarCheque("");
        });
    }

    const columnsMovimientos: TableColumn<Movimiento>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > FECHA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Fecha.formatDate(movimiento.fechaMovimiento)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > EMPRESA </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.empresa.nombreEmpresa}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > ASESOR </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.asesor.nombres} {movimiento.asesor.paterno} {movimiento.asesor.materno}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > BANCO </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{movimiento.banco.nombreBanco}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > ESTATUS DEL CHEQUE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: MovimientoModel.selectColorStatus(movimiento.IdChequeStatus), fontSize: 15, whiteSpace: 'normal', fontWeight: "bold" }} >{MovimientoModel.selectStatusCheque(movimiento.IdChequeStatus)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} > IMPORTE </Label>,
            cell: (movimiento: Movimiento) => <Label style={{ color: colors.black, fontSize: 15, whiteSpace: 'normal' }} >{Moneda.formatCurrency(movimiento.importe)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 16 }} ></Label>,
            cell: (movimiento: Movimiento) =>

                <IconButton
                    style={{ color: colors.blue }}
                    onClick={() => {
                        setIsOpenModalCheque(true);
                        setMovimiento(movimiento);
                    }}
                >
                    <Edit />
                </IconButton>,
        }
    ]

    return (
        <>
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                    <Block height={"5vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                LISTA DE CHEQUES
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.blue01, color: colors.white }}
                                onClick={() => { listarMovimientos(referenciaCheque.fecha, referenciaCheque.RFCEmpresa, referenciaCheque.RFCAsesor, referenciaCheque.IdBanco, referenciaCheque.IdChequeStatus); }
                                }
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Fecha
                                </Label>
                                <Input
                                    id="fecha"
                                    type="date"
                                    value={referenciaCheque.fecha}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaCheque((prev: ReferenciaCheque) => ({ ...prev, fecha: value }));
                                        listarMovimientos(value, referenciaCheque.RFCEmpresa, referenciaCheque.RFCAsesor, referenciaCheque.IdBanco, referenciaCheque.IdChequeStatus);
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Empresa
                                </Label>
                                <Input
                                    id="RFCEmpresa"
                                    type="select"
                                    value={referenciaCheque.RFCEmpresa}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaCheque((prev: ReferenciaCheque) => ({ ...prev, RFCEmpresa: value }));
                                        listarMovimientos(referenciaCheque.fecha, value, referenciaCheque.RFCAsesor, referenciaCheque.IdBanco, referenciaCheque.IdChequeStatus);
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Todas las empresas</option>
                                    <option value="000">NO APLICA</option>
                                    {
                                        usuario.empresas.map((empresa: Empresa) => {
                                            return (
                                                <option value={empresa.RFC} title={empresa.giroEmpresarial} >{empresa.nombreEmpresa}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Asesor
                                </Label>
                                <Input
                                    id="RFCAsesor"
                                    type="select"
                                    value={referenciaCheque.RFCAsesor}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaCheque((prev: ReferenciaCheque) => ({ ...prev, RFCAsesor: value }));
                                        listarMovimientos(referenciaCheque.fecha, referenciaCheque.RFCEmpresa, value, referenciaCheque.IdBanco, referenciaCheque.IdChequeStatus);
                                    }}
                                    style={Styles.input}
                                >
                                    <option value="">Todas los asesores</option>
                                    <option value="000">NO APLICA</option>
                                    {
                                        usuario.asesores.map((asesor: Asesor) => {
                                            return (
                                                <option value={asesor.RFC}  >{asesor.nombres} {asesor.paterno} {asesor.materno} </option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    Banco
                                </Label>
                                <Input
                                    id="IdBanco"
                                    type="select"
                                    value={referenciaCheque.IdBanco}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaCheque((prev: ReferenciaCheque) => ({ ...prev, IdBanco: +value }));
                                        listarMovimientos(referenciaCheque.fecha, referenciaCheque.RFCEmpresa, referenciaCheque.RFCAsesor, +value, referenciaCheque.IdChequeStatus);
                                    }}
                                    style={Styles.input}
                                >
                                    <option value={0}>Todas los bancos</option>
                                    {
                                        bancosLista.map((banco: Banco) => {
                                            return (
                                                <option value={banco.IdBanco}  >{banco.nombreBanco} </option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 14, fontWeight: "bold" }} >
                                    {data.isDesktop ? "Estatus del Cheque" : "Estatus"}
                                </Label>
                                <Input
                                    id="IdChequeStatus"
                                    type="select"
                                    value={referenciaCheque.IdChequeStatus}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setReferenciaCheque((prev: ReferenciaCheque) => ({ ...prev, IdChequeStatus: +value }));
                                        listarMovimientos(referenciaCheque.fecha, referenciaCheque.RFCEmpresa, referenciaCheque.RFCAsesor, referenciaCheque.IdBanco, +value);
                                    }}
                                    style={Styles.input}
                                >
                                    <option value={-1}>Todas los estatus</option>
                                    <option value={0}>Inicial</option>
                                    <option value={1}>Cobrado</option>
                                    <option value={2}>Cancelado</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col>
                            <Label>
                                Total Importe En Transito: {Moneda.formatCurrency(encabezados.importeTransitoTotal)}
                            </Label>
                            <br />
                            <Label>
                                Total Importe En Cobrado: {Moneda.formatCurrency(encabezados.importeCobradoTotal)}
                            </Label>
                            <br />
                            <Label>
                                Total Importe En Cancelado: {Moneda.formatCurrency(encabezados.importeCanceladoTotal)}
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Label>
                                Total En Transito: {encabezados.totalTransito}
                            </Label>
                            <br />
                            <Label>
                                Total En Cobrado: {encabezados.totalCobrado}
                            </Label>
                            <br />
                            <Label>
                                Total En Cancelado: {encabezados.totalCancelado}
                            </Label>
                        </Col>
                    </Row>
                    <DropdownItem divider />
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 5 }} >
                        <Col>
                            <Label>
                                Total Importe: {Moneda.formatCurrency(encabezados.importeTotal)}
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Label>
                                Total de Cheques: {encabezados.totalCheques}
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 20 }} >
                        <Col>
                            <i>Pulse la tecla SHIFT y haga scroll con la rueda del ratón sobre la tabla para visualizar todas sus columnas.
                            </i>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Table
                            columns={columnsMovimientos}
                            data={movimientosLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey, marginTop: 30 }} >No hay movimientos registrados</Label>}
                        />
                    </Row>
                </section>
            </main>
            <Modal
                isOpen={isOpenModalCheque}
                size="lg"
                style={{ fontFamily: "Montserrat" }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalCheque(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24 }} >
                        Cheque
                    </Label>
                </ModalHeader>
                <ModalBody  >
                    <div style={{ height: data.isDesktop ? "50vh" : "95vh", borderRadius: 12, backgroundColor: "#F0F0F0" }} >
                        <Row style={{ marginRight: 5, marginLeft: 5, }}>
                            <Block height={10} />
                            <Col>
                                <Row>
                                    <Col>
                                        <img src={movimiento.banco.imgBanco} style={{ height: 60, width: 90, borderRadius: 12 }} alt="No se pudo cargar la imagen" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "1vh" }} >
                                    <Col>
                                        <Label style={{ fontWeight: "bold" }} >
                                            {movimiento.banco.nombreBanco}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ textAlign: "right" }} >
                                <Row>
                                    <Col>
                                        <Label style={{ fontSize: 16 }} >
                                            <strong>Fecha: </strong>{Fecha.formatDate(movimiento.fechaMovimiento)}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label style={{ fontSize: 16 }} >
                                            <strong>Folio: </strong>{movimiento.referencia}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, marginTop: "5vh" }}>
                            <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                                <Row>
                                    <Label style={{ fontWeight: "bold" }} >
                                        Páguese a la orden de:
                                    </Label>
                                </Row>
                                <Row>
                                    <Label style={{}} >
                                        {movimiento.empresa.nombreEmpresa}
                                    </Label>
                                </Row>
                                <Row>
                                    <div style={{ height: 1, backgroundColor: colors.black, marginRight: 5, marginLeft: 5 }} ></div>
                                </Row>
                            </Col>
                            <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%", textAlign: "right" }} >
                                <Row style={{ marginTop: 20 }} >
                                    <Label style={{ fontSize: 32 }} >
                                        <span style={{ fontSize: 32 }} >$</span> {Moneda.formatCurrency(movimiento.importe).replace("$", "")} <span style={{ fontSize: 16 }} >MXN</span>
                                    </Label>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, marginTop: "5vh" }}>
                            <Col>
                                <Row>
                                    <Label>
                                        <strong style={{ marginRight: 10 }} >Numero de Cuenta: </strong> {CuentaBancariaModel.cast(movimiento.cuentaBancaria.numeroCuenta)}
                                    </Label>
                                </Row>
                                <Row>
                                    <Label>
                                        <strong style={{ marginRight: 10 }} >Observaciones:  <br /> </strong> {movimiento.cuentaBancaria.descripcion}
                                    </Label>
                                </Row>
                            </Col>
                            <Col style={{ textAlign: "right" }} >
                                <FormGroup>
                                    <Input

                                        type="select"
                                        value={movimiento.IdChequeStatus}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setMovimiento((prev: Movimiento) => ({ ...prev, IdChequeStatus: +value }));
                                        }}
                                        style={{ ...Styles.input, width: "80%", marginLeft: "20%" }}
                                    >
                                        <option value={0} style={{ color: colors.black }} >INICIAL</option>
                                        <option value={1} style={{ color: colors.black }} >COBRADO</option>
                                        <option value={2} style={{ color: colors.black }} >CANCELADO</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <Row style={{ marginRight: 5, marginLeft: 5, marginTop: 20 }}>
                        <Label style={{ color: colorGuardarCheque, fontWeight: "bold", fontSize: 16 }} >
                            {labelGuardarCheque}
                        </Label>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => { cambiarStatusCheque() }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Cheques