import { FC, useState, KeyboardEvent, FocusEvent, ChangeEvent, useEffect } from "react";
import Styles from "../../../assets/css/Styles.css";
import Components from "../../../tools/Components.tool";
import { Add, Cached, Close, Article, Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Table, { TableColumn } from 'react-data-table-component';
import { Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import colors from "../../../config/Colors.config";
import Response from "../../../interfaces/Response.interface";
import Documentacion from "../../../interfaces/Documentacion.interface";
import DocumentacionModel from "../../../models/Documentacion.model";

import DocumentacionService from "../../../services/Documentacion.service";




const DocumentacionLista: FC<{ onChangeIndexPage: any, onChangeNombreEmpresa: any, onChangeDocumentacion: any }> = ({ onChangeIndexPage, onChangeNombreEmpresa, onChangeDocumentacion }) => {


    const [documentacionLista, setDocumentacionLista] = useState<Documentacion[]>([]);
    const [empresaBuscar, setEmpresaBuscar] = useState<string>("");
    
    useEffect(() => {
        // listarDocumentos(empresaBuscar);
    }, []);

    const listarDocumentos = async (empresaBuscar: string): Promise<void> => {
        const response: Response = await DocumentacionService.listar(empresaBuscar);
        if (response.error === false) {
            const documentacionLista: Documentacion[] = response.data.value;
            setDocumentacionLista(documentacionLista);
        }
    };

    const columnsDocumentacion: TableColumn<Documentacion>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > EMPRESA </Label>,
            cell: (documentacion: Documentacion) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{documentacion.empresa.nombreEmpresa}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CANTIDAD DOCUMENTOS CARGADOS </Label>,
            cell: (documentacion: Documentacion) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{documentacion.cantidadArchivosCargados} {documentacion.cantidadArchivosCargados === 1 ? "archivo" : "archivos"}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (documentacion: Documentacion) =>

                <IconButton style={{ color: colors.blue }} onClick={() => {
                    onChangeIndexPage("ver-un-documento");
                    onChangeNombreEmpresa(`DE ${documentacion.empresa.nombreEmpresa}`);
                    onChangeDocumentacion(documentacion);
                }}
                >
                    <Article />
                </IconButton>,
        },
    ];

    return (
        <>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                    <FormGroup>
                        <Label style={{ color: colors.black, fontSize: 16, fontWeight: "bold" }} >
                            Buscar por Empresa
                        </Label>
                        <InputGroup>
                            <Input
                                id="textBuscar"
                                type="text"
                                placeholder="Nombre de la empresa o RFC donde se tienen guardados los documentos"
                                value={empresaBuscar}
                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                    setEmpresaBuscar(value)
                                }}
                                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                    if (key === "Enter") listarDocumentos(empresaBuscar);
                                }}
                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                    event.target.select();
                                }}
                                style={Styles.input}
                            />
                            <InputGroupText
                                style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                onClick={() => {
                                    setEmpresaBuscar("");
                                    Components.focus("textBuscar");
                                }}
                            >
                                <Close />
                            </InputGroupText>
                            <InputGroupText
                                style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                onClick={() => {
                                    listarDocumentos(empresaBuscar);
                                    Components.focus("textBuscar");
                                }}
                            >
                                <Search />
                            </InputGroupText>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col style={{ textAlign: "right" }} >
                    <IconButton
                        style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                        onClick={() => listarDocumentos(empresaBuscar)}
                    >
                        <Cached />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            onChangeIndexPage("ver-un-documento");
                            onChangeDocumentacion(DocumentacionModel.getEstructura());
                            onChangeNombreEmpresa("NUEVA")
                        }}
                        style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                    >
                        <Add />
                    </IconButton>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                <Col>
                    <Table
                        columns={columnsDocumentacion}
                        data={documentacionLista}
                        noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >No hay documentacion para mostrar</Label>}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DocumentacionLista;