import { FC, useState, ChangeEvent, KeyboardEvent, FocusEvent, useEffect } from "react";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Styles from "../../assets/css/Styles.css";
import Tiempo from "../../tools/Tiempo.tool";
import Components from "../../tools/Components.tool";
import { Add, Cached, Close, Delete, Edit, Search } from "@mui/icons-material";
import AsesorService from "../../services/Asesor.service";
import Asesor from "../../interfaces/Asesor.interface";
import Response from "../../interfaces/Response.interface";
import { IconButton } from "@mui/material";
import Table, { TableColumn } from 'react-data-table-component';
import AsesorModel from "../../models/Asesor.model";
import Swal from "sweetalert2";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";

const Asesores: FC = () => {

    const [asesor, setAsesor] = useState<Asesor>(AsesorModel.getEstructura());
    const [asesoresLista, setAsesoresLista] = useState<Asesor[]>([]);
    const [textBuscar, setTextBuscar] = useState<string>("");
    const [hora, setHora] = useState<string>("");
    const [isOpenModalAsesor, setIsOpenModalAsesor] = useState<boolean>(false);
    const [labelGuarAsesor, setLabelGuarAsesor] = useState<string>("");
    const [colorCambioLabel, setColorCambioLabel] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());
    
    useEffect(() => {
        listarAsesores("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeAsesor = (name: string, value: string | number) => {
        setAsesor((prev) => ({ ...prev, [name]: value }));
    };

    const listarAsesores = async (text: string): Promise<void> => {
        const response: Response = await AsesorService.listar(text);
        const usuariosLista: Asesor[] = response.data.value;
        setAsesoresLista(usuariosLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour())
    };

    const validarDatosAsesor = (): boolean => {
        let value: boolean = true;
        let label: string = "";
        let color: string = "";

        if (asesor.nombres.length === 0) {
            label = "Tines que especificar el nombre del asesor";
            color = colors.warning;
            Components.focus("nombres");
            value = false;
        }
        if (asesor.RFC.length === 0) {
            label = "Tines que especificar el RFC del asesor";
            color = colors.warning;
            Components.focus("RFC");
            value = false;
        }
        setLabelGuarAsesor(label);
        setColorCambioLabel(color);

        return value;
    }

    const guardarAsesor = async () => {
        if (validarDatosAsesor()) {
            const response: Response = await AsesorService.guardar(asesor);
            if (response.error === false) {
                listarAsesores(textBuscar);
                setAsesor(AsesorModel.getEstructura());
                Components.focus("RFC");
            } else {
                let label: string = "No se pudo guardar al asesor";
                let color: string = colors.red01;
                setLabelGuarAsesor(label);
                setColorCambioLabel(color);
            }
        }
    }

    const eliminarUsuario = (asesor: Asesor) => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Asesor",
            text: `¿Estas seguro de eliminar al usuario ${asesor.nombres}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.red01,
            timer: 10000,
            timerProgressBar: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            reverseButtons: true,
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await AsesorService.eliminar(asesor.RFC);
                if (response.error === false) {
                    listarAsesores(textBuscar);
                }
            }
        });
    }

    const columnsAsesores: TableColumn<Asesor>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > RFC </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.RFC}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRE(S) </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.nombres}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > APELLIDO PATERNO </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.paterno}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > APELLIDO MATERNO </Label>,
            cell: (asesor: Asesor) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{asesor.materno}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (asesor: Asesor) =>
                <>
                    <IconButton style={{ color: colors.red01 }} onClick={() => {
                        eliminarUsuario(asesor)
                    }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton style={{ color: colors.blue }} onClick={() => {

                        setIsOpenModalAsesor(true);
                        setAsesor(asesor);
                    }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ];

    return (
        <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white }} >
            <section style={{ marginLeft: data.isDesktop ? 80 : 0, }} >
                <Block height={"5vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 30, }} >
                            ASESORES DADOS DE ALTA EN EL SISTEMA
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                        <FormGroup>
                            <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                Buscar Asesor
                            </Label>
                            <InputGroup>
                                <Input
                                    id="textBuscar"
                                    type="text"
                                    placeholder="Nombres, Apellido Paterno, Apellido Materno"
                                    value={textBuscar}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setTextBuscar(value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") listarAsesores(textBuscar);
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        setTextBuscar("");
                                        Tiempo.sleep(1).then(() => { listarAsesores("") });
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Close />
                                </InputGroupText>
                                <InputGroupText
                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white, cursor: "pointer" }}
                                    onClick={() => {
                                        listarAsesores(textBuscar);
                                        Components.focus("textBuscar");
                                    }}
                                >
                                    <Search />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <IconButton
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => listarAsesores(textBuscar)}
                        >
                            <Cached />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIsOpenModalAsesor(true);
                                setAsesor(AsesorModel.getEstructura());
                            }}
                            style={{ marginRight: 20, backgroundColor: colors.blue01, color: colors.white }}
                        >
                            <Add />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Label style={{ fontWeight: "bold" }} >
                            Ultima Actualización: {hora}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                    <Col>
                        <Table
                            columns={columnsAsesores}
                            data={asesoresLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >Tabla Vacía</Label>}
                        />
                    </Col>
                </Row>
            </section>
            <Modal
                isOpen={isOpenModalAsesor}
                style={{ fontFamily: "Montserrat" }}
                onOpened={() => {
                    Components.focus("RFC");
                }}
                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                    if (key === "Escape") setIsOpenModalAsesor(false);
                }}
                fullscreen={data.isMovil}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blue01, color: colors.white }}
                            onClick={() => { setIsOpenModalAsesor(false); }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{ backgroundColor: colors.blue }}
                >
                    <Label style={{ color: colors.white, fontSize: 24}} >
                        Datos del Asesor
                    </Label>
                </ModalHeader>
                <ModalBody  >
                    <Row style={{ marginTop: 20 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    RFC del Asesor
                                </Label>
                                <Input
                                    id="RFC"
                                    type="text"
                                    name="RFC"
                                    value={asesor.RFC}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeAsesor(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("nombres");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Nombre(s) del Asesor
                                </Label>
                                <Input
                                    id="nombres"
                                    type="text"
                                    name="nombres"
                                    value={asesor.nombres}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeAsesor(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("paterno");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Apellido Paterno
                                </Label>
                                <Input
                                    id="paterno"
                                    type="text"
                                    name="paterno"
                                    value={asesor.paterno}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeAsesor(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") Components.focus("materno");
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Apellido Materno
                                </Label>
                                <Input
                                    id="materno"
                                    name="materno"
                                    type="text"
                                    value={asesor.materno}
                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                        onChangeAsesor(name, value)
                                    }}
                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                        if (key === "Enter") guardarAsesor();
                                    }}
                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                        event.target.select();
                                    }}
                                    style={Styles.input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label style={{ marginTop: 10, color: colorCambioLabel, fontWeight: "bold" }} >
                                {labelGuarAsesor}
                            </Label>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={Styles.button}
                        onClick={() => {
                            setAsesor(AsesorModel.getEstructura());
                            Components.focus("RFC");
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={Styles.button}
                        onClick={() => guardarAsesor()}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </main>
    );
}

export default Asesores;