import Porcentaje from "../interfaces/Porcentaje.interface";

const getEstructura = (): Porcentaje => {
    const usuario: Porcentaje = {
        IdPorcentaje: 0,
        nombrePorcentaje: "",
        observacionesPorcentaje: "",
        porcentajeUtilidad: 0,
        porcentajeGastosOperacion: 0,
        porcentajeTotal: 0,
        tipoCalculo: {
            IdCalculoTipo: 0,
            calculoTipo: "",
            descripcionCalculoTipo: ""
        }
    };
    return usuario;
}

const PorcentajeModel = {
    getEstructura,
}

export default PorcentajeModel; 